import { DeliveryStatusCode } from "@/services/types/deliveryStatusCode";
import { ActionContext } from "vuex";
import { deliveryStatusCodesService } from "@/services/delivery-status-codes.service";

export interface deliveryStatusCodeState {
    deliveryStatusCode: DeliveryStatusCode[];
}

const deliveryStatusCode = {
    namespaced: true,

    state: {
        deliveryStatusCode: [] as DeliveryStatusCode[],
    },
    mutations: {
        setDeliveryStatusCode(state: deliveryStatusCodeState, payload: DeliveryStatusCode[]): void {
            state.deliveryStatusCode = payload;
        },
    },
    actions: {
        deliveryStatusCodeList: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const deliveryStatusCodes: DeliveryStatusCode[] = (await deliveryStatusCodesService.deliveryStatusCodesList()).data;
                commit("setDeliveryStatusCode", deliveryStatusCodes);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export default deliveryStatusCode;
