import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { frontendToBackendLocales, messages } from "@/i18n";

export const useBackendLocaleName = () => {
    const { locale } = useI18n();

    const backendLocale = computed(() => frontendToBackendLocales[locale.value as keyof typeof messages]);

    return backendLocale;
};
