import { createI18n } from "vue-i18n";

import localeUkUa from "./uk-ua";
import localeEnUs from "./en-us";
import { ukUaLocaleName, enUsLocaleName, backendEnUsLocaleName, backendUkUaLocaleName, fallbackLocale } from "@/utils/constants";

export const messages = {
    [ukUaLocaleName]: localeUkUa,
    [enUsLocaleName]: localeEnUs,
};

export const frontendToBackendLocales = {
    [enUsLocaleName]: backendEnUsLocaleName,
    [ukUaLocaleName]: backendUkUaLocaleName,
} as const;

export default createI18n({
    locale: ukUaLocaleName,
    fallbackLocale: enUsLocaleName,
    legacy: false,
    globalInjection: true,
    messages,
});
