import { ActionContext } from "vuex";

import { statisticsService } from "@/services";
import { Statistic, DashboardStatistic, StatisticFilters, DashboardStatisticFilters } from "@/services/types";
import { AxiosResponse } from "axios";

type Context = ActionContext<unknown, unknown>;
type DashboardStatisticResponse = AxiosResponse<DashboardStatistic> | undefined;
type StatisticResponse = AxiosResponse<Statistic> | undefined;

export interface StatisticState {
    statistic: StatisticResponse;
    dashboardStatistic: DashboardStatisticResponse;
}

const StatisticsModule = {
    namespaced: true,
    state: {
        statistic: undefined as StatisticResponse,
        dashboardStatistic: undefined as DashboardStatisticResponse,
    },
    mutations: {
        setStatistic: (state: StatisticState, payload: StatisticResponse): void => {
            state.statistic = payload;
        },
        setDashboardStatistic: (state: StatisticState, payload: DashboardStatisticResponse): void => {
            state.dashboardStatistic = payload;
        },
    },
    actions: {
        getStatistic: async ({ commit }: Context, filters: StatisticFilters): Promise<void> => {
            try {
                const res = await statisticsService.statistics(filters);
                commit("setStatistic", res);
            } catch (e: any) {
                console.error(e);
                commit("setStatistic", e.response || { statusText: e.code });
            }
        },
        getDashboardStatistic: async ({ commit }: Context, filters: DashboardStatisticFilters): Promise<void> => {
            try {
                const res = await statisticsService.dashboardStatistics(filters);
                commit("setDashboardStatistic", res);
            } catch (e: any) {
                console.error(e);
                commit("setDashboardStatistic", e.response || { statusText: e.code });
            }
        },
    },
};

export default StatisticsModule;
