import Joi from "joi";
import { RegexPresets } from "@/uikit/utils/validators/regex";
export const PHONE_NUMBER_REGEX = /^\+\d{12}$/;
export const emailValidationSchema = Joi.string().email({ tlds: { allow: false } });
export const passwordValidations = {
    password: Joi.string().min(3).max(15).required(),
    confirmPassword: Joi.any().valid(Joi.ref("password")).required(),
};

export const createTaskSchema = Joi.object({
    name: Joi.string().min(3).max(100).required(),
    responsibleId: Joi.number().integer().positive().required(),
    responsibleContactId: Joi.number().integer().allow(-1),
    createdById: Joi.number().integer().allow(-1),
    statusId: Joi.number().integer().allow(-1),
    comment: Joi.string().allow(""),
    endDate: Joi.date().optional(),
    createdAt: Joi.date().optional(),
    clientId: Joi.number().integer().positive().allow(0),
    leadId: Joi.number().integer().positive().allow(0),
    dealId: Joi.number().integer().allow(-1),
});

const today = new Date();
today.setDate(today.getDate());
today.setHours(0, 0, 0, 0);

export const createNewDeliverySchema = Joi.object({
    senderFirstName: Joi.string().min(3).max(100).required(),
    senderCity: Joi.string().min(3).max(100).required(),
    senderPhone: Joi.string()
        .regex(/^(?:\+?380|0)\d{9}$/)
        .required(),
    recipientPhone: Joi.string()
        .regex(/^(?:\+?380|0)\d{9}$/)
        .required(),
    senderLastName: Joi.string().min(3).max(100).required(),
    recipientCity: Joi.string().min(3).max(100).required(),
    recipientFirstName: Joi.string().min(3).max(100).required(),
    recipientLastName: Joi.string().min(3).max(100).required(),
    senderCounterPartyType: Joi.any().not("").required(),
    dateTime: Joi.date().min(today).optional(),
    cost: Joi.number().integer().min(1).required(),
    AfterpaymentOnGoodsCost: Joi.number().integer().min(1).optional(),
    recipientCounterPartyType: Joi.string().not("").required(),
    descriptionOfTheCargo: Joi.string().min(1).max(100).required(),
    paymentMethod: Joi.string().not("").required(),
    payerOfDelivery: Joi.string().not("").required(),
    cargoType: Joi.string().not("").required(),
    serviceType: Joi.string().not("").required(),
    recipientAddress: Joi.string().not("").required(),
    senderAddress: Joi.string().not("").required(),

    totalWeight: Joi.number().min(0.1).optional(),
    numberOfSeats: Joi.number().optional(),
    totalVolumeOfShipments: Joi.number().optional(),
    useGeneralParams: Joi.boolean().optional(),
    typeOfReceivingMoney: Joi.string().optional(),
    payerOfCashOnDelivery: Joi.string().optional(),
    paymentControlEnabledValue: Joi.string().optional(),
    senderTypeDepartment: Joi.string().optional(),
    senderSettlement: Joi.string().optional(),
    senderDepartments: Joi.string().optional(),
    postPaid: Joi.string().optional(),
    additionalInformation: Joi.string().optional(),
    packageNumber: Joi.string().optional(),
    recipientTypeDepartment: Joi.string().optional(),
    recipientDepartments: Joi.string().optional(),
    recipientSettlement: Joi.string().optional(),
}).options({ presence: "optional" });

export const createPriceListSchema = Joi.object({
    name: Joi.string().min(3).max(100).required(),
    categoryId: Joi.number().min(0).optional().allow(0),
    file: Joi.any().optional(),
    article: Joi.string().optional().allow(""),
    unit: Joi.string().min(1).max(20).required(),
    cost: Joi.number().min(0.1).precision(15).required(),
    price: Joi.number().min(0.1).precision(15).required(),
    url: Joi.string()
        .pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
        .allow("")
        .optional()
        .messages({
            "string.pattern.base": "Please enter a correct URL format, such as starting with https://, http://, or www.",
        }),
    currencyId: Joi.number().integer().positive().required(), // USD $
    createdAt: Joi.date().optional(),
});

export const createDealSchema = Joi.object({
    name: Joi.string().min(3).max(100).required(),
    amount: Joi.number().min(0).allow(0).optional(),
    currencyId: Joi.number().integer().allow(-1).optional(),
    responsibleId: Joi.number().integer().positive().required(),
    responsibleContactId: Joi.number().integer().allow(-1).optional(),
    clientId: Joi.number().integer().positive().required(),
    comment: Joi.string().allow("").optional(),
    stageId: Joi.number().integer().positive().required(),
    funnelId: Joi.number().integer().positive().required(),
    sourceId: Joi.number().integer().allow(-1).optional(),
    endDate: Joi.date().optional(),
    createdAt: Joi.date().optional(),
});

export const createLeadSchema = Joi.object({
    name: Joi.string().min(3).max(100).required(),
    responsibleId: Joi.number().integer().positive().required(),
    typeId: Joi.number().integer().positive().required(),
    sourceId: Joi.number().integer().allow(-1),
    statusId: Joi.number().integer().allow(-1),
    email: Joi.string().regex(RegexPresets.email).required(),
    contactPerson: Joi.string().min(3).max(10).allow(""),
    phoneNumber: Joi.string().regex(PHONE_NUMBER_REGEX).required(),
    comment: Joi.string().max(500).allow(""),
    createdAt: Joi.date().optional(),
    novaPostDeliveryAddress: Joi.object().allow(null),
    ukrPostDeliveryAddress: Joi.object().allow(null),
}).or("email", "phoneNumber");

export const createUserSchema = Joi.object({
    login: emailValidationSchema,
    fullName: Joi.string()
        .regex(/^(?!\s*$).{3,}$/)
        .min(3)
        .max(100)
        .required(),
    roleId: Joi.number().integer().positive().required(),
    positionId: Joi.number().integer().positive().required(),
}).with("password", "confirmPassword"); //Todo check if we need .with(...)

export const createAdditionalContactSchema = Joi.object({
    firstName: Joi.string().min(3).max(100).required(),
    lastName: Joi.string().min(3).max(100).required(),
    middleName: Joi.string().min(3).max(100).required(),
    position: Joi.string().min(1).max(100).allow(""),
    phoneNumber: Joi.string()
        .regex(/^(?:\+?\d{12}|\d{10})$/)
        .allow(""),
    email: Joi.string()
        .regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}|^$/)
        .allow(""),
    novaPostDeliveryAddress: Joi.object().allow(null),
    ukrPostDeliveryAddress: Joi.object().allow(null),
});

export const priceListFilterSchema = Joi.object({
    priceList_priceFrom: Joi.number().min(0).precision(3).optional(),
    priceList_priceTo: Joi.number().min(0).precision(3).optional(),
    priceList_costFrom: Joi.number().min(0).precision(3).optional(),
    priceList_costTo: Joi.number().min(0).precision(3).optional(),
});

export const loginSchema = Joi.object({
    email: Joi.string().regex(RegexPresets.email).required(),
    password: Joi.string().regex(RegexPresets.newPassword).required(),
});

export * from "./barcodes";
export * from "./position";
export * from "./role";
