import api from "@/services/api";
import { Note, CreateNote, UpdateNote, NoteFilters } from "@/services/types";
import { AxiosProgressEvent } from "axios";
import { SourceType } from "./enums";

export const notesService = {
    async noteList(params: NoteFilters): Promise<Note[]> {
        const response = await api.get("/notes", { params });
        return response.data;
    },
    async createNote(sourceType: SourceType, payload: CreateNote, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
        const response = await api.post("/notes", payload, {
            params: { sourceType },
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60 * 20,
            onUploadProgress,
        });
        return response.data;
    },

    async updateNote(id: number, sourceType: SourceType, payload: UpdateNote, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<void> {
        const response = await api.patch(`/notes/${id}`, payload, {
            params: { sourceType },
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
            timeout: 1000 * 60 * 20,
        });
        return response.data;
    },
    async removeNote({ id, sourceType }: { id: number; sourceType: SourceType }): Promise<void> {
        const response = await api.delete(`/notes/${id}`, { params: { sourceType } });
        return response.data;
    },
};
