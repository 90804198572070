import { positionsService, referencesService } from "@/services";
import { DraftPosition, Position, PositionsFilters } from "@/services/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";

export const POSITIONS_LIST_KEY = "positions-list";
const COUNT_USERS_WITH_POSITION = "count-users-with-position";

// TODO move it to references
export const usePositionsRefs = ({ enabled }: { enabled: ComputedRef<boolean> } = { enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["getPositions"],
        queryFn: () => referencesService.getPositionsList(),
        initialData: [],
        enabled,
    });

export const usePositionsList = () =>
    useQuery({
        queryKey: [POSITIONS_LIST_KEY],
        queryFn: () => positionsService.positionsList({}),
        initialData: { positions: [], totalCount: 0 },
    });

export const useDeletePosition = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => positionsService.deletePosition(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [POSITIONS_LIST_KEY] });
        },
    });
};

export const useUpdatePosition = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (position: Position) => positionsService.updatePosition(position),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [POSITIONS_LIST_KEY] }),
    });
};

export const useCreatePosition = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (position: DraftPosition) => positionsService.createPosition(position),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [POSITIONS_LIST_KEY] }),
    });
};

export const useCountUsersWithRole = (positionId: number) =>
    useQuery({
        queryKey: [COUNT_USERS_WITH_POSITION, positionId],
        queryFn: () => positionsService.getUsersCountWithPosition(positionId),
        initialData: [],
    });
