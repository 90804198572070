import moment from "moment";
import { computed } from "vue";

export const formatDate = (date: Date) => {
    const receivedDate = moment(date);
    const today = moment().startOf("day");

    if (receivedDate.isSame(today, "d")) {
        return receivedDate.format("HH:mm");
    } else {
        return receivedDate.format("DD.MM.YY");
    }
};

export const limitDateToday = computed(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
});
