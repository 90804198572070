import { computed, onBeforeMount } from "vue";
import { Store, useStore } from "vuex";
import { Centrifuge } from "centrifuge";
import { useI18n } from "vue-i18n";

import { VUE_APP_CENTRIFUGO_URL } from "@/utils/config";
import { authService } from "@/services";
import store, { RootState } from "@/store";
import { LOGS_CHANNEL, NOTIFICATIONS_CHANNEL } from "../constants/centrifugo";
import { SourceType } from "@/services/enums";
import { backendEnUsLocaleName, backendUkUaLocaleName, ukUaLocaleName } from "@/utils/constants";

export function useCentrifugoSubscription() {
    const store: Store<RootState> = useStore<RootState>();
    const logs = computed(() => store.state.logs);
    const { locale } = useI18n();
    const currentLocale = computed(() => (locale.value === ukUaLocaleName ? backendUkUaLocaleName : backendEnUsLocaleName));

    onBeforeMount(async () => {
        try {
            const { token, channelId } = await authService.getCentrifugoCredentials();
            if (token) {
                const centrifuge = new Centrifuge(VUE_APP_CENTRIFUGO_URL, {
                    token,
                });

                centrifuge
                    .on("connected", function (ctx) {
                        console.info(`connected over ${ctx.transport}`);
                    })
                    .connect();

                centrifuge.on("error", (e) => console.error(e));

                const notificationsSub = centrifuge.newSubscription(`${NOTIFICATIONS_CHANNEL}#${channelId}`);
                const logsSub = centrifuge.newSubscription(LOGS_CHANNEL);

                notificationsSub
                    .on("publication", (ctx) => {
                        store.commit("notifications/add", ctx.data.items);
                    })
                    .subscribe();

                logsSub
                    .on("publication", (ctx) => {
                        if (
                            ctx.data &&
                            logs.value.logs.length &&
                            ctx.data.total > 0 &&
                            logs.value.logs[0].essence === ctx.data.items[0].essence &&
                            logs.value.logs[0].essenceId === ctx.data.items[0].essenceId
                        ) {
                            store.commit("logs/setLogs", ctx.data.items);
                            store.commit("logs/setTotal", ctx.data.total);
                            const updateEssence = updateEssenceMap[`${logs.value.logs[0].essence}`];
                            updateEssence(ctx.data.items[0].essenceId, currentLocale.value);
                        }
                    })
                    .subscribe();
            }
        } catch (error) {
            console.error(error);
        }
    });
}

const updateEssenceMap = {
    [`${SourceType.LEAD}`]: (id: number, locale: string) => store.dispatch("leads/getLead", { id, locale }),
    [`${SourceType.CLIENT}`]: (id: number, locale: string) => store.dispatch("clients/getClient", { id, locale }),
    [`${SourceType.DEAL}`]: (id: number, locale: string) => store.dispatch("deals/getDeal", { id, locale }),
    [`${SourceType.TASK}`]: (id: number, locale: string) => store.dispatch("tasks/getTask", { id, locale }),
    [`${SourceType.PRICE_LIST}`]: (id: number) => store.dispatch("priceList/getPriceListItem", id),
};
