export interface PaginationState {
    page: number;
    pagesCount: number;
    limit: number;
}

export const paginationState: PaginationState = {
    page: 1,
    pagesCount: 1,
    limit: 10,
};

export const paginationMutations = {
    setCurrentPage: (state: PaginationState, payload: number): void => {
        state.page = payload;
    },
    setPagesCount: (state: PaginationState, payload: number): void => {
        state.pagesCount = payload;
    },
};
