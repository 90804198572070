const importExport = {
    export: "Експорт",
    downloadAll: "Завантажити все",
    downloadFiltered: "Завантажити відфільтроване",
    startExport: "Експорт",
    startImport: "Імпорт",
    chooseImportFile: "Оберіть файл для імпорту",
    import: "Імпорт",
    uniqueField: "Унікальне поле",
    all: "Всі",
    noChecks: "Без перевірок",
    chooseFile: "Оберіть файл для імпорту",
    file: "Файл",

    successfullyImported: "Успішно імпортовано:",
    didntManageToImport: "Імпорт невдалий:",
    importProcessEnded: "Процес імпорту закінчено",
    downloadExported: "Завантажити експорт",
    downloadTemplate: "Завантажити шаблон",
    seeWhichRowsBelow: "нижче вказані невдалі рядки",

    leads: {
        exportDescription: `Виберіть, які дані потрібно експортувати, в тому числі додаткові контакти. 
                            Якщо даних багато, то це може зайняти деякий час. Зверніть увагу, якщо
                            ви не включаєте обов’язкові поля в експорт, цей файл експорту не зможе бути імпортований`,
        importDescription: `Щоб імпортувати лідів потрібно сформувати файл імпорту, згідно нашого шаблону.
                            Зауважте, що якщо ви не включили у файл обов’язкові поля, він не зможе бути імпортований`,
        additionalContacts: "Додаткові контакти",
    },
    clients: {
        exportDescription: `Виберіть, які дані потрібно експортувати, в тому числі додаткові контакти. 
                            Якщо даних багато, то це може зайняти деякий час. Зверніть увагу, якщо
                            ви не включаєте обов’язкові поля в експорт, цей файл експорту не зможе бути імпортований`,
        importDescription: `Щоб імпортувати клієнтів потрібно сформувати файл імпорту, згідно нашого шаблону.
                            Зауважте, що якщо ви не включили у файл обов’язкові поля, він не зможе бути імпортований`,
        additionalContacts: "Додаткові контакти",
    },
    deals: {
        exportDescription: `Виберіть, які дані потрібно експортувати, в тому числі сервіси та продукти. 
                            Якщо даних багато, то це може зайняти деякий час. Зверніть увагу, якщо
                            ви не включаєте обов’язкові поля в експорт, цей файл експорту не зможе бути імпортований.
                            Зображення можна експортувати лише у XLSX файлі`,
        importDescription: `Щоб імпортувати угоди потрібно сформувати файл імпорту, згідно нашого шаблону.
                            Зауважте, що якщо ви не включили у файл обов’язкові поля, він не зможе бути імпортований.
                            Зображення можна додати лише у XLSX файл`,
        priceLists: "Сервіси/продукти",
    },
    priceLists: {
        exportDescription: `Виберіть, які дані потрібно експортувати.
                            Якщо даних багато, то це може зайняти деякий час. Зверніть увагу, якщо
                            ви не включаєте обов’язкові поля в експорт, цей файл експорту не зможе бути імпортований.
                            Зображення можна експортувати лише у XLSX файлі`,
        importDescription: `Щоб імпортувати сервіси/продукти потрібно сформувати файл імпорту, згідно нашого шаблону.
                            Зауважте, що якщо ви не включили у файл обов’язкові поля, він не зможе бути імпортований.
                            Зображення можна додати лише у XLSX файл`,

        barcodes: "Штрихкоди",
    },
    tasks: {
        exportDescription: `Виберіть, які дані потрібно експортувати,
                            Якщо даних багато, то це може зайняти деякий час. Зверніть увагу, якщо
                            ви не включаєте обов’язкові поля в експорт, цей файл експорту не зможе бути імпортований`,
        importDescription: `Щоб імпортувати задачі потрібно сформувати файл імпорту, згідно нашого шаблону.
                            Зауважте, що якщо ви не включили у файл обов’язкові поля, він не зможе бути імпортований`,
    },
};
export default importExport;
