import { computed, ref, Ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { detailedDiff } from "deep-object-diff";
import { useModal } from "@/utils/hooks/modals";
import { useRootStore } from "@/utils/hooks/store";

export function useUnsavedChanges(current: Ref<any>, customFieldChanges: Ref<any>) {
    const originalValue = ref();
    const store = useRootStore();
    const { openModal } = useModal("changesNotSaved");
    const preventCheck = computed(() => store.state.modals.params?.preventCheck);

    const setOriginalValue = (value: any) => (originalValue.value = JSON.parse(JSON.stringify(value)));
    const changesNotSaved = computed(() => {
        const diff = detailedDiff(originalValue.value, current.value);
        return !!Object.keys(diff.updated).length || !!Object.keys(diff.added).length || !!Object.keys(diff.deleted).length;
    });

    onBeforeRouteLeave(async (to, from, next) => {
        if (preventCheck.value) {
            store.commit("modals/setParams", { preventCheck: false });
            next();
        } else if (changesNotSaved.value || !!customFieldChanges.value.length) {
            next(false);
            await openModal("changesNotSaved", { to });
        } else {
            next();
        }
    });

    return setOriginalValue;
}
