const tooltips = {
    copyLink: "Copy link",
    archive: "Archive",
    unarchive: "Unarchive",
    edit: "Edit",
    cancelSelect: "Cancel selection",
    transformToClient: "Transform to client",
    requestForArchive: "Request for archiving",
    copy: "Copy",
    moveToCategory: "Move to category",
    remove: "Remove",
    printDeliveries: "Print selected invoices",
    export: "Export data",
    import: "Import data",
    addArticleToPdf: "DESCRIBE BEHAVIOUR",
    addProducNameToPdf: "DESCRIBE BEHAVIOUR",
    copyArticleToBarcode: "DESCRIBE BEHAVIOUR",
};

export default tooltips;
