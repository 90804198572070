import { CustomField } from "@/services/types";

export interface CustomFieldsState {
    customFields: CustomField[];
}

export const customFieldsState: CustomFieldsState = {
    customFields: [],
};

export const customFieldsMutations = {
    setCustomFields: (state: CustomFieldsState, payload: CustomField[]): void => {
        state.customFields = payload;
    },
};
