import { ActionContext } from "vuex";

import { categoriesService } from "@/services";
import { Category, CreateCategory, UpdateCategory } from "@/services/types";
import { useQuery } from "@tanstack/vue-query";

export interface CategoryState {
    categories: Category[];
}

const CategoriesModule = {
    namespaced: true,
    state: {
        categories: [] as Category[],
    },
    mutations: {
        setCategories: (state: CategoryState, payload: Category[]): void => {
            state.categories = payload;
        },
    },
    actions: {
        categoryList: async ({ commit }: ActionContext<unknown, unknown>, filters: any): Promise<void> => {
            try {
                const categories: Category[] = (await categoriesService.categoryList(filters)).data;
                commit("setCategories", categories);
            } catch (e) {
                console.error(e);
            }
        },
        createCategory: async (_commit: unknown, payload: CreateCategory): Promise<void> => {
            try {
                await categoriesService.createCategory(payload);
            } catch (e) {
                console.error(e);
            }
        },
        updateCategory: async (_commit: unknown, { id, payload }: { id: number; payload: UpdateCategory }): Promise<void> => {
            try {
                await categoriesService.updateCategory(id, payload);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useCategories = (filters: any) =>
    useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: () =>
            categoriesService.categoryList(filters).then((res) => {
                return res.data;
            }),
    });

export default CategoriesModule;
