import { RouteLocationNormalized } from "vue-router";
import { PublicPages, RoutesNames } from "./consts";
import Cookies from "js-cookie";
import store from "@/store";
import { routeNameToRequiredPermissionMap } from "@/utils/mappings";

export const isAccessToRoute = (to: RouteLocationNormalized): boolean => {
    if (PublicPages.includes(to.path)) return true;

    const userPermissions = store.getters["auth/userPermissions"] || [];

    // @ts-ignore
    const isRouteWithoutPermissions = !(to.name in routeNameToRequiredPermissionMap);
    if (isRouteWithoutPermissions) {
        return true;
    }

    // @ts-ignore
    const enterPermission = routeNameToRequiredPermissionMap[to.name];
    const isAllow = userPermissions.includes(enterPermission);
    const isRedirectedFromMain = to.redirectedFrom?.name === RoutesNames.MAIN;

    if (!isAllow && !isRedirectedFromMain) {
        alert("You do not have the access to this page!");
        return false;
    }

    return true;
};

export const isAuthorized = (to: RouteLocationNormalized): boolean => {
    const authRequired = !PublicPages.includes(to.path);
    const loggedIn = !!Cookies.get("check");

    if (authRequired && !loggedIn) {
        return false;
    }

    return true;
};
