import api from "@/services/api";
import { Client, ClientFilters, ClientList, ClientSorting, CreateClient, CustomField, Locale, Pagination, SomeFieldsRequest, UpdateClient } from "@/services/types";
import { DownloadFileTypeOptions } from "@/utils/import-export";

export const clientsService = {
    async clientList(params: ClientFilters | ClientSorting | Locale): Promise<ClientList> {
        const response = await api.get("/clients", {
            params,
        });
        return response.data;
    },

    async someFields(params: SomeFieldsRequest): Promise<Partial<Client>[]> {
        const response = await api.get("/clients/some-fields", {
            params: { fields: params.fields?.join(",") },
        });

        return response.data;
    },

    async getClient(id: string, locale: Locale) {
        const response = await api.get(`/clients/${id}`, { params: { locale } });
        return response.data;
    },

    createClient(leadId: number, statusId: number | undefined, locale: "en" | "ua") {
        const data: CreateClient = { createdAt: new Date(), leadId, statusId };
        return api.post("clients", data, { params: { locale } });
    },

    updateClient(id: number, payload: UpdateClient, locale: Locale): Promise<void> {
        return api.patch(`/clients/${id}`, payload, { params: { locale } });
    },

    bulkUpdate(data: UpdateClient, locale: Locale): Promise<void> {
        return api.patch(`/clients/bulk`, data, { params: { locale } });
    },

    addContact(id: number, email: string) {
        return api.post(`/clients/${id}/contacts`, { email });
    },

    async getClientAdditionalContactOptions(clientId: number) {
        const response = await api.get(`/clients/${clientId}/additional-contacts`);
        return response.data;
    },

    async clientFieldsList(locale: "en" | "ua") {
        const response = await api.get<{ essenceFields: string[]; customFields: CustomField[] }>("/clients/fields", { params: { locale } });
        return response;
    },

    async exportClients(
        params: { locale: "en" | "ua" } & { essenceFieldIds: string[]; customFieldIds: number[]; additionalContactsFieldIds: string[] } & Omit<
                ClientFilters,
                keyof Pagination
            > &
            ClientSorting
    ) {
        const { essenceFieldIds, customFieldIds, additionalContactsFieldIds } = params;
        return await api.get<{ fileUrl: string }>("/clients/export", {
            params: {
                ...params,
                essenceFieldIds: essenceFieldIds.join(","),
                customFieldIds: customFieldIds.join(","),
                additionalContactsFieldIds: additionalContactsFieldIds.join(","),
            },
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importClients(data: { locale: "en" | "ua" } & { file: File; uniqueFieldId: string }) {
        const form = new FormData();
        form.append("file", data.file);
        form.append("locale", data.locale);
        form.append("uniqueFieldId", data.uniqueFieldId);

        return await api.postForm<{ errors: ({ [key: string]: any } & { error: any })[]; successCount: number }>("/clients/import", form, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importClientsTemplate(fileType: DownloadFileTypeOptions) {
        return await api.get<{ fileUrl: string }>(`/clients/import/template?fileType=${fileType}`, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
};
