import api from "@/services/api";
import { DealStage } from "@/services/types";

export const dealStagesService = {
    async stagesList(params?: any) {
        return await api.get<DealStage[]>("/deal-stages", {
            params: { ...params },
        });
    },
    async removeDealStage(dealStageId: number) {
        const response = await api.delete(`/deal-stages/${dealStageId}`);
        return response.data;
    },
    async createDealStage(stage: Omit<DealStage, "id">) {
        return await api.post("/deal-stages", stage);
    },
    async updateDealStage(dealStageId: number, dealStage: Partial<Omit<DealStage, "id">>) {
        return await api.patch(`/deal-stages/${dealStageId}`, dealStage);
    },
};
