import { ActionContext } from "vuex";

import { dealsService } from "@/services";
import { CreateDeal, UpdateDeal, Deal, DealFilters, DealSorting, Locale, Pagination } from "@/services/types";
import { AxiosResponse } from "axios";
import { paginationState, paginationMutations, PaginationState, customFieldsState, customFieldsMutations, CustomFieldsState } from "@/store/mixins";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";
import { DownloadFileTypeOptions } from "@/utils/import-export";

type DealResponse = AxiosResponse<{ _: string }> | undefined;
type Context = ActionContext<unknown, unknown>;

export interface DealsState extends CustomFieldsState, PaginationState {
    createDeal: DealResponse;
    updateDeal: DealResponse;
    deals: Deal[];
    selectInputOptions: Partial<Deal>[];
    deal: Deal & { funnelId?: number };
}

const DealsModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
        ...paginationState,
        createDeal: undefined as DealResponse,
        updateDeal: undefined as DealResponse,
        deals: [] as Deal[],
        selectInputOptions: [] as Partial<Deal>[],
        deal: undefined,
    },
    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setCreateDeal: (state: DealsState, payload: DealResponse): void => {
            state.createDeal = payload;
        },
        setUpdateDeal: (state: DealsState, payload: DealResponse): void => {
            state.updateDeal = payload;
        },
        setDeals: (state: DealsState, payload: Deal[]): void => {
            state.deals = payload;
        },
        setDeal: (state: DealsState, payload: Deal): void => {
            state.deal = payload;
        },
        setDealsForSelectInput: (state: DealsState, payload: Partial<Deal>[]): void => {
            state.selectInputOptions = payload;
        },
    },
    actions: {
        dealList: async ({ commit }: Context, filters: DealFilters | DealSorting | Locale): Promise<void> => {
            try {
                const { deals, customFields, page, pagesCount } = await dealsService.dealList(filters);
                commit("setDeals", deals);
                commit("setCustomFields", customFields);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        getDeal: async ({ commit }: ActionContext<unknown, unknown>, { id, locale }: { id: string; locale: Locale }) => {
            const deal = await dealsService.getDeal(id, locale);
            commit("setDeal", deal);
            return deal;
        },
        getSelectInputOptions: async ({ commit }: ActionContext<unknown, unknown>, clientId?: number): Promise<void> => {
            try {
                const deals = await dealsService.someFields({ fields: ["id", "name"] }, clientId);
                commit("setDealsForSelectInput", deals);
            } catch (e) {
                console.error(e);
            }
        },
        createDeal: async ({ commit }: Context, payload: CreateDeal) => {
            commit("setCreateDeal", undefined);
            try {
                const res = await dealsService.createDeal(payload);
                commit("setCreateDeal", res);
                return res;
            } catch (e: any) {
                commit("setCreateDeal", e.response || { statusText: e.code });
                throw e;
            }
        },
        updateDeal: async ({ commit }: ActionContext<unknown, unknown>, { id, payload, locale }: { id: number; payload: UpdateDeal; locale: Locale }): Promise<void> => {
            commit("setUpdateDeal", undefined);
            try {
                const res = await dealsService.updateDeal(id, payload, locale);
                commit("setUpdateDeal", res);
            } catch (e: any) {
                commit("setUpdateDeal", e.response || { statusText: e.code });
                throw e;
            }
        },
        async bulkUpdate(_commit: unknown, { data, locale }: { data: UpdateDeal; locale: Locale }): Promise<void> {
            try {
                return dealsService.bulkUpdate(data, locale);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useDealFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["deals/fields"],
        queryFn: () => dealsService.dealFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useDealPriceListFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["deal-price-list/fields"],
        queryFn: () => dealsService.dealPriceListFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useExport = () =>
    useMutation({
        mutationFn: (
            filters: { locale: "ua" | "en" } & { essenceFieldIds: string[]; customFieldIds: number[]; priceListFieldIds: string[] } & Omit<
                    DealFilters,
                    keyof Pagination
                > &
                DealSorting
        ) => dealsService.exportDeals(filters).then((res) => res.data),
    });

export const useImport = () =>
    useMutation({
        mutationFn: (data: { locale: "ua" | "en" } & { file: File; uniqueFieldId: string }) => dealsService.importDeals(data).then((res) => res.data),
    });

export const useImportTemplate = () =>
    useMutation({
        mutationFn: (fileType: DownloadFileTypeOptions) => dealsService.importDealsTemplate(fileType).then((res) => res.data),
    });

export default DealsModule;
