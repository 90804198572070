const notifications = {
    title: "Notifications",
    emptyList: "No new notifications",
    showAll: "Show all",
    tabTitles: {
        messages: "Messages",
        showAll: "Show all",
    },
};

export default notifications;
