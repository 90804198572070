const multiActions = {
    edit: {
        header: "Масові дії",
        apply: "Застосувати",
        clear: "Скинути",
    },
    panel: {
        checked: "Обрано",
        elements: "елемент(-и)",
        selectAll: "Вибрати все",
    },
    modals: {
        success: {
            title: "Зміни застосовані",
        },
    },
};

export default multiActions;
