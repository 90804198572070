const importExport = {
    export: "Export",
    downloadAll: "Download all",
    downloadFiltered: "Download filtered",
    startExport: "Export",
    startImport: "Import",
    chooseImportFile: "Choose file to import",
    import: "Import",
    uniqueField: "Unique field",
    all: "All",
    noChecks: "No checks",
    chooseFile: "Choose file for import",
    file: "File",

    successfullyImported: "Successfully imported:",
    didntManageToImport: "Didn't manage to import:",
    seeWhichRowsBelow: "see which rows below",
    importProcessEnded: "Import process ended",
    downloadExported: "Download exported",
    downloadTemplate: "Download template",

    leads: {
        exportDescription: `Choose what data you want to export, including additional contacts. 
                            If there is a lot of data, it may take some time. Please note, that if 
                            you don't include required fields in the export, this file will not import again`,
        importDescription: `To import leads, you need to create an import file according to our template. 
                            Please note, that if you didn't include required fields in the file, it will not import`,
        additionalContacts: "Additional contacts",
    },
    deals: {
        exportDescription: `Choose what data you want to export, including services/products.
                            If there is a lot of data, it may take some time. Please note, that if 
                            you don't include required fields in the export, this file will not import again.
                            Images can only be exported as an XLSX file`,
        importDescription: `To import leads, you need to create an import file according to our template. 
                            Please note, that if you didn't include required fields in the file, it will not import.
                            Images can only be added to an XLSX file`,
        priceLists: "Services/products",
    },
    clients: {
        exportDescription: `Choose what data you want to export, including additional contacts. 
                            If there is a lot of data, it may take some time. Please note, that if 
                            you don't include required fields in the export, this file will not import again`,
        importDescription: `To import clients, you need to create an import file according to our template. 
                            Please note, that if you didn't include required fields in the file, it will not import`,
        additionalContacts: "Additional contacts",
    },
    tasks: {
        exportDescription: `Choose what data you want to export. 
                            If there is a lot of data, it may take some time. Please note, that if 
                            you don't include required fields in the export, this file will not import again`,
        importDescription: `To import tasks, you need to create an import file according to our template. 
                            Please note, that if you didn't include required fields in the file, it will not import`,
    },
    priceLists: {
        exportDescription: `Choose what data you want to export. 
                            If there is a lot of data, it may take some time. Please note, that if 
                            you don't include required fields in the export, this file will not import again.
                            Images can only be exported as an XLSX file`,
        importDescription: `To import services/products, you need to create an import file according to our template. 
                            Please note, that if you didn't include required fields in the file, it will not import.
                            Images can only be added to an XLSX file`,
        barcodes: "Barcodes",
    },
};
export default importExport;
