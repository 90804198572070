const inputs = {
    range: {
        from: "Від",
        to: "До",
    },
    validationMessage: {
        required: "Це поле обов'язкове",
        formFieldsInvalid: "Дані деяких полей введено невірно",
        incorrectLength: "Довжина поля повинна бути від {from} до {to} символів",
    },
    dateValidationMessage: {
        beforeMinDate: "Дата не може бути раніше",
    },
};

export default inputs;
