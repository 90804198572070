import api from "@/services/api";
import { Funnel } from "@/services/types";

export const funnelsService = {
    async funnelsList() {
        return (await api.get<Funnel[]>("/funnels")).data;
    },
    async removeFunnel(funnelId: number) {
        return (await api.delete(`/funnels/${funnelId}`)).data;
    },
    async createFunnel(funnel: Omit<Funnel, "id" | "stages">) {
        return (await api.post("/funnels", funnel)).data;
    },
    async updateFunnel(funnelId: number, funnel: Omit<Funnel, "id" | "stages">) {
        return (await api.patch(`/funnels/${funnelId}`, funnel)).data;
    },
    async reorderDealStages(funnelId: number, reordered: { dealStageId: number; newIndex: number }) {
        return (await api.patch(`/funnels/reorder/${funnelId}`, reordered)).data;
    },
};
