const sidebar = {
    open: "Показати сайдбар",
    hide: "Сховати",
    items: {
        main: "Головна",
        leads: "Ліди",
        clients: "Клієнти",
        deals: "Угоди",
        tasks: "Завдання",
        calls: "Дзвінки",
        priceList: "Прайсліст",
        statistic: "Статистика",
        delivery: "Доставка",
        mail: {
            title: "Пошта",
            items: {
                inbox: "Вхідні",
                sent: "Надіслані",
                drafts: "Черенетки",
                starred: "Із зірочкою",
                archive: "Архів",
                spam: "Спам",
                trash: "Кошик",
            },
        },
        settings: {
            title: "Налаштування",
            items: {
                user: "Користувачі",
                roles: "Ролі",
                leads: "Ліди",
                clients: "Клієнти",
                deals: "Угоди",
                task: "Завдання",
                notifications: "Сповіщення",
                personal: "Персональні",
                priceList: "Прайс-лист",
                integrations: "Інтреграції",
            },
        },
    },
};
export default sidebar;
