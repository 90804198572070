import { concatPermissionValue } from "../permissions";

export const AccessLevels = {
    VIEW: "view",
    CREATE: "create",
    UPDATE: "update",
    ARCHIVE_AND_DEARCHIVE_LEADS: "archive-and-dearchive",
    REMOVE: "delete",
    REQUEST_ARCHIVE: "request-archive",
    IMPORT: "import",
    EXPORT: "export",
};

export const Resources = {
    LEADS: "leads",
    CLIENTS: "clients",
    DEALS: "deals",
    TASKS: "tasks",
    PRICE_LIST: "price-list",
    CALLS: "calls",
    USERS: "users",
    DELIVERY: "deliveries",
    STATISTICS: "statistics",
    USERS_SETTINGS: "users-settings",
    ROLES_SETTINGS: "roles-settings",
    LEADS_SETTINGS: "leads-settings",
    CLIENTS_SETTINGS: "clients-settings",
    DEALS_SETTINGS: "deals-settings",
    TASKS_SETTINGS: "tasks-settings",
    PRICE_LIST_SETTINGS: "price-lists-settings",
};

export const Permissions = {
    // leads
    CAN_VIEW_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.VIEW),
    CAN_CREATE_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.CREATE),
    CAN_UPDATE_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.UPDATE),
    CAN_ARCHIVE_AND_DEARCHIVE_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.ARCHIVE_AND_DEARCHIVE_LEADS),
    CAN_IMPORT_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.IMPORT),
    CAN_EXPORT_LEADS: concatPermissionValue(Resources.LEADS, AccessLevels.EXPORT),

    // clients
    CAN_VIEW_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.VIEW),
    CAN_CREATE_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.CREATE),
    CAN_UPDATE_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.UPDATE),
    CAN_ARCHIVE_AND_DEARCHIVE_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.ARCHIVE_AND_DEARCHIVE_LEADS),
    CAN_IMPORT_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.IMPORT),
    CAN_EXPORT_CLIENTS: concatPermissionValue(Resources.CLIENTS, AccessLevels.EXPORT),

    // deals
    CAN_VIEW_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.VIEW),
    CAN_CREATE_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.CREATE),
    CAN_UPDATE_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.UPDATE),
    CAN_ARCHIVE_AND_DEARCHIVE_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.ARCHIVE_AND_DEARCHIVE_LEADS),
    CAN_IMPORT_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.IMPORT),
    CAN_EXPORT_DEALS: concatPermissionValue(Resources.DEALS, AccessLevels.EXPORT),

    // tasks
    CAN_VIEW_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.VIEW),
    CAN_CREATE_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.CREATE),
    CAN_UPDATE_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.UPDATE),
    CAN_ARCHIVE_AND_DEARCHIVE_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.ARCHIVE_AND_DEARCHIVE_LEADS),
    CAN_IMPORT_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.IMPORT),
    CAN_EXPORT_TASKS: concatPermissionValue(Resources.TASKS, AccessLevels.EXPORT),

    // price-list
    CAN_VIEW_PRICE_LIST: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.VIEW),
    CAN_CREATE_PRICE_LISTS: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.CREATE),
    CAN_UPDATE_PRICE_LISTS: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.UPDATE),
    CAN_ARCHIVE_AND_DEARCHIVE_PRICE_LISTS: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.ARCHIVE_AND_DEARCHIVE_LEADS),
    CAN_IMPORT_PRICE_LISTS: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.IMPORT),
    CAN_EXPORT_PRICE_LISTS: concatPermissionValue(Resources.PRICE_LIST, AccessLevels.EXPORT),

    // calls
    CAN_VIEW_CALLS: concatPermissionValue(Resources.CALLS, AccessLevels.VIEW),
    CAN_UPDATE_CALLS: concatPermissionValue(Resources.CALLS, AccessLevels.UPDATE),
    CAN_REQUEST_CALLS_ARCHIVE: concatPermissionValue(Resources.CALLS, AccessLevels.REQUEST_ARCHIVE),

    // delivery
    CAN_VIEW_DELIVERIES: concatPermissionValue(Resources.DELIVERY, AccessLevels.VIEW),
    CAN_CREATE_DELIVERIES: concatPermissionValue(Resources.DELIVERY, AccessLevels.CREATE),
    CAN_DELETE_DELIVERIES: concatPermissionValue(Resources.DELIVERY, AccessLevels.REMOVE),

    // statistics
    CAN_VIEW_STATISTICS: concatPermissionValue(Resources.STATISTICS, AccessLevels.VIEW),

    // users settings
    CAN_VIEW_USERS_SETTINGS: concatPermissionValue(Resources.USERS_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_USERS_SETTINGS: concatPermissionValue(Resources.USERS_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_USERS_SETTINGS: concatPermissionValue(Resources.USERS_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_USERS_SETTINGS: concatPermissionValue(Resources.USERS_SETTINGS, AccessLevels.REMOVE),

    // roles settings
    CAN_VIEW_ROLES_SETTINGS: concatPermissionValue(Resources.ROLES_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_ROLES_SETTINGS: concatPermissionValue(Resources.ROLES_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_ROLES_SETTINGS: concatPermissionValue(Resources.ROLES_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_ROLES_SETTINGS: concatPermissionValue(Resources.ROLES_SETTINGS, AccessLevels.REMOVE),

    // leads settings
    CAN_VIEW_LEADS_SETTINGS: concatPermissionValue(Resources.LEADS_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_LEADS_SETTINGS: concatPermissionValue(Resources.LEADS_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_LEADS_SETTINGS: concatPermissionValue(Resources.LEADS_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_LEADS_SETTINGS: concatPermissionValue(Resources.LEADS_SETTINGS, AccessLevels.REMOVE),

    // clients settings
    CAN_VIEW_CLIENTS_SETTINGS: concatPermissionValue(Resources.CLIENTS_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_CLIENTS_SETTINGS: concatPermissionValue(Resources.CLIENTS_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_CLIENTS_SETTINGS: concatPermissionValue(Resources.CLIENTS_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_CLIENTS_SETTINGS: concatPermissionValue(Resources.CLIENTS_SETTINGS, AccessLevels.REMOVE),

    // deals settings
    CAN_VIEW_DEALS_SETTINGS: concatPermissionValue(Resources.DEALS_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_DEALS_SETTINGS: concatPermissionValue(Resources.DEALS_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_DEALS_SETTINGS: concatPermissionValue(Resources.DEALS_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_DEALS_SETTINGS: concatPermissionValue(Resources.DEALS_SETTINGS, AccessLevels.REMOVE),

    // tasks settings
    CAN_VIEW_TASKS_SETTINGS: concatPermissionValue(Resources.TASKS_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_TASKS_SETTINGS: concatPermissionValue(Resources.TASKS_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_TASKS_SETTINGS: concatPermissionValue(Resources.TASKS_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_TASKS_SETTINGS: concatPermissionValue(Resources.TASKS_SETTINGS, AccessLevels.REMOVE),

    // price-list settings
    CAN_VIEW_PRICE_LISTS_SETTINGS: concatPermissionValue(Resources.PRICE_LIST_SETTINGS, AccessLevels.VIEW),
    CAN_CREATE_PRICE_LISTS_SETTINGS: concatPermissionValue(Resources.PRICE_LIST_SETTINGS, AccessLevels.CREATE),
    CAN_UPDATE_PRICE_LISTS_SETTINGS: concatPermissionValue(Resources.PRICE_LIST_SETTINGS, AccessLevels.UPDATE),
    CAN_DELETE_PRICE_LISTS_SETTINGS: concatPermissionValue(Resources.PRICE_LIST_SETTINGS, AccessLevels.REMOVE),
};
