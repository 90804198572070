const logs = {
    title: "Activity Log",
    noLogs: "No logs found",
    essences: {
        lead: "lead",
        client: "client",
        deal: "deal",
        task: "task",
        priceList: "price list",
    },
    methods: {
        created: "created",
        updated: "updated",
        archived: "archived",
        dearchived: "dearchived",
        ["added-nova-post-delivery-by-ttn"]: "added nova poshta delivery for",
        ["removed-delivery"]: "removed delivery for",
        ["created-additional-contact"]: "created additional contact for",
        ["updated-additional-contact"]: "updated additional for",
        ["deleted-additional-contact"]: "deleted additional for",
        ["created-note"]: "created note for",
        ["updated-note"]: "updated note for",
        ["deleted-note"]: "deleted note for",
        ["created-barcode"]: "created barcode for",
        ["updated-barcode"]: "updated barcode for",
        ["deleted-barcode"]: "deleted barcode for",
        ["task-assigned-to-customer"]: "assigned task for",
        ["task-reassigned-to-customer"]: "reassigned task for",
        ["task-assigned-to-deal"]: "assigned task for",
        ["task-reassigned-to-deal"]: "reassigned task for",
        ["deal-assigned-to-customer"]: "assigned deal for",
        ["deal-reassigned-to-customer"]: "reassigned deal for",
        ["deal-rewrite-to-price-lists"]: "changed price list for",
    },
    ttn: "TTN",
};

export default logs;
