import { computed, reactive, ref } from "vue";

export const useValidator = () => {
    const fields = ref<{
        [key: string]: {
            isValid: boolean;
        };
    }>({});

    const isValid = computed(
        () =>
            Object.values(fields.value).reduce((prev, cur) => ({ isValid: prev.isValid ? cur.isValid : false }), {
                isValid: true,
            }).isValid
    );

    const clearFields = () => (fields.value = reactive({}));
    const setFieldValid = (id: string, isValid: boolean) => {
        if (fields.value[id] && fields.value[id].isValid == isValid) return;

        fields.value = reactive({
            ...fields.value,
            [id]: { isValid },
        });
    };

    return {
        setFieldValid,
        clearFields,
        isValid,
        fieldValidator: (fieldId: string) => (isValid: boolean) => setFieldValid(fieldId, isValid),
    };
};

export type Validator = ReturnType<typeof useValidator>;
