const inputs = {
    range: {
        from: "From",
        to: "To",
    },
    validationMessage: {
        required: "This field is required",
        formFieldsInvalid: "Some of fields invalid",
        incorrectLength: "Field length should be from {from} to {to} symbols",
    },
    dateValidationMessage: {
        beforeMinDate: "Date cannot be before",
    },
};

export default inputs;
