const secretKey = process.env.VUE_APP_SECRET_KEY;

export const decrypt = async (encryptedText: string) => {
    const [ivHex, encryptedDataHex] = encryptedText.split(":");
    const iv = hexToArrayBuffer(ivHex);
    const encryptedData = hexToArrayBuffer(encryptedDataHex);
    return decryptUsingAES_CTR(iv, encryptedData, secretKey);
};

const hexToArrayBuffer = (hexString: string) => {
    const matched = hexString.match(/.{1,2}/g);
    if (matched === null) {
        throw new Error("Invalid hex string format");
    }
    return new Uint8Array(matched.map((byte) => parseInt(byte, 16))).buffer;
};

const decryptUsingAES_CTR = async (iv: ArrayBuffer, encryptedData: ArrayBuffer, secretKey: string) => {
    const secretKeyArrayBuffer = hexToArrayBuffer(secretKey);
    const key = await window.crypto.subtle.importKey("raw", secretKeyArrayBuffer, { name: "AES-CTR", length: 256 }, false, ["decrypt"]);

    try {
        const decryptedBuffer = await window.crypto.subtle.decrypt(
            {
                name: "AES-CTR",
                counter: iv,
                length: 128,
            },
            key,
            encryptedData
        );
        return new TextDecoder().decode(decryptedBuffer);
    } catch (error) {
        console.error("Decryption failed.", error);
        throw error;
    }
};
