import { createStore, Store } from "vuex";

import auth, { AuthState } from "@/store/modules/auth";
import leads, { LeadsState } from "@/store/modules/leads";
import clients, { ClientsState } from "@/store/modules/clients";
import deals, { DealsState } from "@/store/modules/deals";
import tasks, { TaskState } from "@/store/modules/tasks";
import statuses, { StatusState } from "@/store/modules/statuses";
import sources, { SourceState } from "@/store/modules/sources";
import customerTypes, { CustomerTypeState } from "@/store/modules/customer-types";
import categories, { CategoryState } from "@/store/modules/categories";
import calls, { CallState } from "@/store/modules/calls";
import priceList, { PriceListState } from "@/store/modules/price-list";
import users, { UserState } from "@/store/modules/users";
import roles, { RoleState } from "@/store/modules/roles";
import customFields from "@/store/modules/custom-fields";
import statistics, { StatisticState } from "@/store/modules/statistics";
import notes, { NoteState } from "@/store/modules/notes";
import modals, { ModalsState } from "@/store/modules/modals";
import dealStages, { DealStagesState } from "@/store/modules/deal-stages";
import deliveryStatusCodes, { deliveryStatusCodeState } from "@/store/modules/delivery-status-code";
import relatedStatuses, { relatedStatusesState } from "@/store/modules/related-statuses";
import logs, { LogsState } from "@/store/modules/logs";
import references, { ReferencesState } from "@/store/modules/references";
import { CustomFieldsState } from "@/store/mixins";
import emails, { EmailsState } from "@/store/modules/emails";
import notifications, { NotificationsState } from "./modules/notifications";
import queryParamFilters, { QueryParamFiltersState } from "./modules/query-param-filters";
import delivery, { DeliveryState } from "@/store/modules/delivery";
import additionalContacts, { AdditionalContactState } from "@/store/modules/additional-contact";

export interface RootState {
    auth: AuthState;
    leads: LeadsState;
    clients: ClientsState;
    deals: DealsState;
    tasks: TaskState;
    statuses: StatusState;
    sources: SourceState;
    customerTypes: CustomerTypeState;
    categories: CategoryState;
    calls: CallState;
    priceList: PriceListState;
    users: UserState;
    roles: RoleState;
    customFields: CustomFieldsState;
    statistics: StatisticState;
    notes: NoteState;
    modals: ModalsState;
    dealStages: DealStagesState;
    deliveryStatusCodes: deliveryStatusCodeState;
    relatedStatuses: relatedStatusesState;
    logs: LogsState;
    references: ReferencesState;
    emails: EmailsState;
    notifications: NotificationsState;
    queryParamFilters: QueryParamFiltersState;
    delivery: DeliveryState;
    additionalContacts: AdditionalContactState;
}

export default createStore<Store<RootState>>({
    getters: {
        userPermissions(store) {
            //@ts-ignore
            return store.auth?.profile?.role?.permissions;
        },
    },
    modules: {
        auth,
        leads,
        clients,
        deals,
        tasks,
        statuses,
        sources,
        customerTypes,
        categories,
        calls,
        priceList,
        users,
        roles,
        customFields,
        statistics,
        notes,
        modals,
        dealStages,
        logs,
        references,
        emails,
        notifications,
        delivery,
        additionalContacts,
        queryParamFilters,
        deliveryStatusCodes,
        relatedStatuses,
    },
});
