import Joi from "joi";
import { ROLE_NAME_LENGTH } from "../constants/roles";
import { enUsLocaleName, ukUaLocaleName } from "../constants";

const errMsgs = {
    [ukUaLocaleName]: {
        "string.max": `Назва ролі має бути менше чи дорівнює ${ROLE_NAME_LENGTH} символам.`,
        "string.empty": "Назва ролі не може бути порожньою.",
        "any.required": "Назва ролі є обов'язковим полем.",
    },
    [enUsLocaleName]: {
        "string.max": `Role name must be less than or equal to ${ROLE_NAME_LENGTH} characters.`,
        "string.empty": "Role name cannot be empty.",
        "any.required": "Role name is a required field.",
    },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const roleNameSchema = Joi.string().max(ROLE_NAME_LENGTH).required().messages(errMsgs);
