import api from "@/services/api";
import { addDelivery } from "@/services/types/delivery/add-delivery";
import { DeliveryFilters } from "@/services/types/delivery/delivery-filtets";
import { getSettlements } from "@/services/types/delivery/settlements";
import { getDepartment, getDepartments } from "@/services/types/delivery/departments";
import { getDepartmentsType } from "@/services/types/delivery/departments-type";
import { CreateCounterPartyRecipient } from "@/services/types/delivery/create-counterparty-recipient";
import { CreateNovaPoshtaDelivery } from "@/services/types/delivery/create-novaposta-delivery";
import { GetCounterpartyData } from "@/services/types/delivery/get-counterparty";
import { DeliveryProviders, UkrPostDelivery } from "@/services/enums";
import { GetContactPerson } from "@/services/types/delivery/get-contact-person";
import { PrintMarkingDelivery } from "@/services/types/delivery/print-marking-delivery";

export const deliveryService = {
    async addDelivery(deliveryData: addDelivery): Promise<void> {
        return await api.post("/delivery/add", deliveryData);
    },

    async createCounterpartyRecipient(counterpartyRecipientData: CreateCounterPartyRecipient) {
        const response = await api.post("delivery/nova-post/create-counterparty-recipient", counterpartyRecipientData);
        return response.data;
    },

    async printMarking(printMarkingDeliveryData: PrintMarkingDelivery) {
        return await api.post("delivery/nova-post/print-marking", printMarkingDeliveryData, {
            responseType: "arraybuffer",
        });
    },

    async createNovaPostDelivery(createNovaPoshtaDeliveryData: CreateNovaPoshtaDelivery) {
        const response = await api.post("/delivery/nova-post", createNovaPoshtaDeliveryData);
        return response.data;
    },

    async createUkrPostDelivery(payload: UkrPostDelivery) {
        const response = await api.post("/delivery/ukr-post", payload);
        return response.data;
    },

    async getCounterPartyData(params: GetCounterpartyData) {
        const response = await api.get("/delivery/nova-post/counterparty", {
            params,
        });
        return response.data;
    },

    async getContactPersonData(params: GetContactPerson) {
        const response = await api.get("/delivery/nova-post/contact-person", {
            params,
        });
        return response.data;
    },

    async deliveryList(params: DeliveryFilters): Promise<any> {
        const response = await api.get("/delivery", {
            params,
        });
        return response.data;
    },

    async deleteDelivery(deliveryId: number): Promise<any> {
        return api.delete(`/delivery/${deliveryId}`);
    },

    async deleteDeliveries(data: number[]): Promise<any> {
        return api.delete("delivery/bulk", {
            data: data,
        });
    },

    async getSettlements(params: getSettlements, provider: DeliveryProviders): Promise<any> {
        const response = await api.get(`/delivery/${provider}/settlements`, {
            params,
        });
        return response.data;
    },

    async getNovaPostDepartmentTypes(params: getDepartmentsType): Promise<any> {
        const response = await api.get("/delivery/nova-post/department-types", {
            params,
        });
        return response.data;
    },

    async getDepartments(params: getDepartments, provider: DeliveryProviders): Promise<any> {
        const response = await api.get(`/delivery/${provider}/departments`, {
            params,
        });
        return response.data;
    },

    async getDepartment(params: getDepartment, provider: DeliveryProviders): Promise<any> {
        const response = await api.get(`/delivery/${provider}/department`, {
            params,
        });
        return response.data;
    },
};
