import { ActionContext } from "vuex";

import { sourcesService } from "@/services";
import { Source } from "@/services/types";
import { SourceType } from "@/services/enums";
import { useMutation, useQuery } from "@tanstack/vue-query";

export interface SourceState {
    sources: Source[];
}

const SourcesModule = {
    namespaced: true,
    state: {
        sources: [] as Source[],
    },
    mutations: {
        setSources: (state: SourceState, payload: Source[]): void => {
            state.sources = payload;
        },
    },
    actions: {
        sourceList: async ({ commit }: ActionContext<unknown, unknown>, sourceType: SourceType): Promise<void> => {
            try {
                const sources: Source[] = (await sourcesService.sourceList(sourceType)).data;
                commit("setSources", sources);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useSources = (sourceType: SourceType) =>
    useQuery({
        queryKey: ["sourcesList"],
        queryFn: () => sourcesService.sourceList(sourceType).then((res) => res.data),
        initialData: [],
    });

export const useRemoveSource = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (sourceId: number) => sourcesService.removeSource(sourceId, sourceType),
    });

export const useCreateSource = () =>
    useMutation({
        mutationFn: (source: Omit<Source, "id" | "isPredefinedBySystem">) => sourcesService.createSource(source),
    });

export const useUpdateSource = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (data: { id: number; source: Partial<Omit<Source, "id">> }) => sourcesService.updateSource(data.id, data.source, sourceType),
    });

export default SourcesModule;
