import { ActionContext } from "vuex";
import { Ref } from "vue";
import { useQuery } from "@tanstack/vue-query";

import { emailsService } from "@/services/emails.service";
import { UploadProgressHandlers } from "@/services/types";

type Context = ActionContext<unknown, unknown>;

export interface EmailsState {
    accounts: any[];
}

const EmailsModule = {
    namespaced: true,
    state: {
        accounts: [],
    },
    mutations: {
        setEmailAccounts: (state: EmailsState, payload: any): void => {
            state.accounts = payload;
        },
    },
    actions: {
        accountList: async ({ commit }: Context): Promise<void> => {
            try {
                const accounts = await emailsService.accountList();
                commit("setEmailAccounts", accounts);
            } catch (e) {
                console.error(e);
            }
        },

        sendMessage: async (
            _commit: unknown,
            payload: {
                accountId: number;
                mail: FormData;
                uploadHandlers: UploadProgressHandlers;
            }
        ): Promise<boolean> => {
            const {
                accountId,
                uploadHandlers: { onUploadProgress, onSuccessUpload, onErrorUpload },
            } = payload;

            try {
                await emailsService.sendMessage(accountId, payload.mail, onUploadProgress);
                onSuccessUpload();
                return true;
            } catch (e) {
                onErrorUpload("");
                console.error(e);
                return false;
            }
        },
    },
};

export const useEmailList = (page: Ref<number>) =>
    useQuery({
        queryKey: ["emails-list", page],
        queryFn: () => emailsService.getAllUserEmails(page.value),
        initialData: { items: [], total: 0 },
    });

export const useUserEmailAccounts = () =>
    useQuery({
        queryKey: ["user-email-accounts"],
        queryFn: () => emailsService.accountList(),
        initialData: [],
    });

export const useProviderConfigs = () =>
    useQuery({
        queryKey: ["user-email-accounts"],
        queryFn: (...a) => {
            console.log(a);
            return [];
            // emailsService.getProviderConfigs(email);
        },
        initialData: [],
    });

export default EmailsModule;
