import api from "@/services/api";
import { AxiosProgressEvent } from "axios";

export const emailsService = {
    async accountList(): Promise<any> {
        const response = await api.get("/emails/accounts");
        return response.data;
    },

    async emailList(id: number, params: any): Promise<any> {
        const response = await api.get(`/emails/accounts/${id}`, { params });
        return response.data;
    },

    async emailMessage(accountId: number, messageId: number): Promise<any> {
        const response = await api.get(`/emails/accounts/${accountId}/messages/${messageId}`);
        return response.data;
    },

    async createMessage(accountId: number, payload: FormData): Promise<any> {
        const response = await api.post(`/emails/accounts/${accountId}/messages`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    },

    async sendMessage(accountId: number, payload: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<any> {
        const response = await api.post(`/emails/accounts/${accountId}/messages/send`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60 * 20,
            onUploadProgress,
        });
        return response.data;
    },

    async updateMessage(accountId: number, messageId: number, payload: any): Promise<any> {
        const response = await api.patch(`/emails/accounts/${accountId}/messages/${messageId}`, payload);
        return response.data;
    },

    async bulkUpdate(accountId: number, ids: number[], payload: any) {
        const response = await api.patch(`/emails/accounts/${accountId}/messages`, {
            ids,
            payload,
        });
        return response.data;
    },

    createAccount(accountData: any): Promise<any> {
        return api.post(`/emails/create-account`, accountData);
    },

    async updateAccount(accountId: number, payload: any): Promise<any> {
        const response = await api.patch(`/emails/accounts/${accountId}`, payload);
        return response.data;
    },

    async removeAccount(accountId: number): Promise<any> {
        const response = await api.delete(`/emails/accounts/${accountId}`);
        return response.data;
    },

    async getAllUserEmails(page: number) {
        const response = await api.get("/emails/user-emails", {
            params: { page },
        });
        return response.data;
    },

    async getProviderConfigs(email: string) {
        const response = await api.get("/emails/providers/configs", {
            params: { email },
        });

        return response.data;
    },
};
