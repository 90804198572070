import { LocationQuery, Router, useRoute, useRouter } from "vue-router";
import { ComputedRef, Ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { QueryParams } from "@/store/modules/query-param-filters";

export const useFilterArchived = (queryFilters: ComputedRef<LocationQuery>, prefix: string) => {
    const router: Router = useRouter();
    const prefixPage = `${prefix}_page`;
    const prefixArchived = `${prefix}_isArchived`;

    return async (): Promise<void> => {
        const { [prefixArchived]: isArchived, [prefixPage]: page, ...currentQuery } = queryFilters.value;
        if (!Object.keys(queryFilters.value).includes(prefixArchived)) {
            currentQuery[prefixArchived] = "true";
        }
        await router.push({ query: currentQuery });
    };
};

export const useQueryParams = (params: Ref<{ [key: string]: string | undefined }>, queryFilters: ComputedRef<LocationQuery>) => {
    const router = useRouter();

    const applyParams = async (ignoreEmptyStrings = true) => {
        const { ...currentQuery } = queryFilters.value;
        Object.entries(params.value).map(([key, value]) => {
            if (value == undefined || (ignoreEmptyStrings && value == "")) delete currentQuery[key];
            else currentQuery[key] = value;
        });

        await router.push({ query: currentQuery });
    };

    const clearParams = async () => {
        const { ...currentQuery } = queryFilters.value;
        Object.keys(params.value).map((key) => delete currentQuery[key]);

        await router.push({ query: currentQuery });

        params.value = Object.keys(params.value).reduce((prev, k) => ({ ...prev, [k]: undefined }), {});
    };

    return { applyParams, clearParams };
};

export const useQueryParamFilters = <T extends QueryParams = QueryParams>(prefix: string, options?: { pushToQueryParams?: boolean }) => {
    const store = useStore<RootState>();
    const route = useRoute();

    onMounted(() => {
        store.dispatch("queryParamFilters/setInitialQueryParams", route.query);
    });

    const queryParams = computed(() => store.state.queryParamFilters.queryParams);
    const onlyPrefixedQueryParams = computed(() => getFiltersByPrefix(queryParams.value, prefix) as T);

    const routerQueryParams = computed(() => route.query);
    const { applyParams } = useQueryParams(queryParams, routerQueryParams);
    const setFilters = (filters: Partial<T>) => {
        store.dispatch(
            "queryParamFilters/setQueryParams",
            Object.entries(filters)
                .map(([key, value]) => [`${prefix}_${key}`, value])
                .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})
        );
        if (options?.pushToQueryParams !== false) applyParams();
    };

    return { filters: onlyPrefixedQueryParams, setFilters } as const;
};

export const getFiltersByPrefix = (queryFilters: Record<string, any>, prefix: string): Record<string, any> => {
    const filters: Record<string, any> = {};

    for (const key in queryFilters) {
        if (key.startsWith(prefix)) {
            const newKey = key.split("_")[1];
            filters[newKey] = queryFilters[key];
        }
    }
    if (prefix === "priceList" && queryFilters.categoryId) {
        filters["categoryId"] = queryFilters.categoryId;
    }

    return filters;
};
