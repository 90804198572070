import { Sorting } from "@/services/enums";
import { ComputedRef, ref, Ref, computed, watchEffect, isRef } from "vue";
import { LocationQuery, Router, useRouter } from "vue-router";

export const useSort = (
    queryFilters: ComputedRef<LocationQuery>,
    prefix: string | Ref<string>
): {
    order: Ref<Sorting | "">;
    orderBy: Ref<string>;
    handleSortUpdate: (updatedOrderBy: string) => void;
} => {
    const RESET_VALUE = "";
    const router: Router = useRouter();

    const getPrefixValue = () => (isRef(prefix) ? prefix.value : prefix);

    const prefixOrderBy = computed(() => `${getPrefixValue()}_orderBy`);
    const prefixOrder = computed(() => `${getPrefixValue()}_order`);

    const order = ref<Sorting | "">(RESET_VALUE);
    const orderBy = ref<string>(RESET_VALUE);

    watchEffect(() => {
        const initOrderBy = queryFilters.value[prefixOrderBy.value] || RESET_VALUE;
        const initOrder = (queryFilters.value[prefixOrder.value] as Sorting | "") || RESET_VALUE;
        order.value = initOrder;
        orderBy.value = initOrderBy as string;
    });

    const handleSortUpdate = (updatedOrderBy: string): void => {
        const isChangedOrderByColumn = orderBy.value !== updatedOrderBy;
        orderBy.value = order.value === Sorting.ASC ? RESET_VALUE : updatedOrderBy;

        if (order.value === RESET_VALUE || isChangedOrderByColumn) {
            order.value = Sorting.DESC;
        } else if (order.value === Sorting.DESC) {
            order.value = Sorting.ASC;
        } else if (order.value === Sorting.ASC) {
            order.value = RESET_VALUE;
        }

        const { [prefixOrderBy.value]: _, [prefixOrder.value]: __, ...query } = queryFilters.value;
        const routerQueryTwo =
            updatedOrderBy.length > 0 && order.value.length > 0 ? { [prefixOrderBy.value]: updatedOrderBy, [prefixOrder.value]: order.value, ...query } : query;

        router.push({ query: routerQueryTwo });
    };

    return {
        order,
        orderBy,
        handleSortUpdate,
    };
};
