<template>
    <span class="overflow-hidden rounded-full h-[22px] w-[22px]">
        <svg class="scale-[130%]" width="22" height="22" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="20" rx="2" fill="white" />
            <mask id="mask0_1803_12354" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
                <rect width="28" height="20" rx="2" fill="white" />
            </mask>
            <g mask="url(#mask0_1803_12354)">
                <rect width="28" height="20" fill="#0A17A7" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-1.28226 -1.9165L10.6669 6.14329V-1.33339H17.3335V6.14329L29.2827
-1.9165L30.7739 0.294263L21.3265 6.66661H28.0002V13.3333H21.3265L30.7739 19.7056L29.2827 
21.9164L17.3335 13.8566V21.3333H10.6669V13.8566L-1.28226 21.9164L-2.77344 19.7056L6.67395 
13.3333H0.000212669V6.66661H6.67395L-2.77344 0.294263L-1.28226 -1.9165Z"
                    fill="white"
                />
                <path d="M18.668 6.33219L31.3333 -2" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                <path d="M20.0127 13.6975L31.3665 21.3503" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                <path d="M8.00513 6.31042L-3.83789 -1.67102" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                <path d="M9.28963 13.6049L-3.83789 22.3104" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z" fill="#E6273E" />
            </g>
        </svg>
    </span>
</template>
