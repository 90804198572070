const notes = {
    title: "Notes",
    addButton: "Add",
    placeholder: "Add text.....",
    saveChangesBtn: "Save changes",
    cancelBtn: "Cancel",
    uploadingFiles: "The files are uploading",
    takeSomeTime: "It can take some time",
    noNotes: "No notes found",
    historyItem: {
        headerText: "added the note to {sourceType}",
        edited: "edited",
        LEAD: "lead",
        CLIENT: "client",
        DEAL: "deal",
        TASK: "task",
        PRICE_LIST: "price list",
    },
};

export default notes;
