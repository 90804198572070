const sidebar = {
    open: "Open sidebar",
    hide: "Hide",
    items: {
        main: "Main",
        leads: "Leads",
        clients: "Clients",
        deals: "Deals",
        tasks: "Tasks",
        calls: "Calls",
        priceList: "Price list",
        statistic: "Statistic",
        delivery: "Delivery",
        mail: {
            title: "Mail",
            items: {
                inbox: "Inbox",
                sent: "Sent",
                drafts: "Drafts",
                starred: "Starred",
                archive: "Archive",
                spam: "Spam",
                trash: "Trash",
            },
        },
        settings: {
            title: "Settings",
            items: {
                user: "Users",
                roles: "Roles",
                leads: "Leads",
                clients: "Clients",
                deals: "Deals",
                task: "Task",
                notifications: "Notifications",
                priceList: "Price-list",
                personal: "Personal",
                integrations: "Integrations",
            },
        },
    },
};
export default sidebar;
