import { ActionContext } from "vuex";

import { customerTypesService } from "@/services";
import { CustomerType } from "@/services/types";
import { SourceType } from "@/services/enums";
import { useMutation, useQuery } from "@tanstack/vue-query";

export interface CustomerTypeState {
    customerTypes: CustomerType[];
}

const CustomerTypesModule = {
    namespaced: true,
    state: {
        customerTypes: [] as CustomerType[],
    },
    mutations: {
        setCustomerTypes: (state: CustomerTypeState, payload: CustomerType[]): void => {
            state.customerTypes = payload;
        },
    },
    actions: {
        customerTypeList: async ({ commit }: ActionContext<unknown, unknown>, sourceType: SourceType): Promise<void> => {
            try {
                const customerTypes: CustomerType[] = (await customerTypesService.customerTypeList(sourceType)).data;
                commit("setCustomerTypes", customerTypes);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useCustomerTypes = (sourceType: SourceType) =>
    useQuery({
        queryKey: ["customerTypes", sourceType],
        queryFn: () => customerTypesService.customerTypeList(sourceType).then((res) => res.data),
        initialData: [],
    });

export const useRemoveCustomerType = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (customerTypeId: number) => customerTypesService.removeCustomerType(customerTypeId, sourceType),
    });

export const useCreateCustomerType = () =>
    useMutation({
        mutationFn: (customerType: Omit<CustomerType, "id" | "isPredefinedBySystem">) => customerTypesService.createCustomerType(customerType),
    });

export const useUpdateCustomerType = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (data: { id: number; customerType: Partial<Omit<CustomerType, "id">> }) =>
            customerTypesService.updateCustomerType(data.id, data.customerType, sourceType),
    });

export default CustomerTypesModule;
