import { crmSettingsService } from "@/services";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { megabytesToBytes } from "../transform-file-sizes";
import { formatBytes } from "../formats";

interface ErrorMsg {
    title: string;
    description: string;
}

export const useValidateFiles = (): {
    error: Ref<ErrorMsg | undefined>;
    validateFiles: (newFiles: any[], savedFiles: any[]) => Promise<boolean>;
} => {
    const { t } = useI18n();

    const error = ref<ErrorMsg | undefined>();

    const validateFiles = async (newFiles: any[], savedFiles: any[]): Promise<boolean> => {
        const { fileAmountLimit, fileSizeLimit } = await crmSettingsService.getSettings();
        const newFilesArr = Array.from(newFiles);
        const allFilesAmount = newFiles.length + savedFiles.length;

        const maxFileSize = Math.max(...newFilesArr.map((file) => file.size));

        if (allFilesAmount > fileAmountLimit) {
            error.value = {
                title: t("common.fileErrors.maxFilesCountLimitTitle"),
                description: t("common.fileErrors.maxFilesCountLimitDescription", { fileAmountLimit }),
            };

            return false;
        }

        const limitInBytes = megabytesToBytes(fileSizeLimit);
        if (maxFileSize > limitInBytes) {
            error.value = {
                title: t("common.fileErrors.maxFileSizeLimitTitle"),
                description: t("common.fileErrors.maxFileSizeLimitDescription", { fileSizeLimit: formatBytes(limitInBytes) }),
            };
            return false;
        }

        const storedFilesNames = savedFiles.filter((el) => el.file).map((el) => el.file?.name);
        const duplicatedFile = newFilesArr.find((selectedFile) => storedFilesNames.includes(selectedFile.name));
        if (duplicatedFile) {
            error.value = {
                title: t("common.fileErrors.fileNameDuplicatedTitle"),
                description: duplicatedFile.name,
            };

            return false;
        }

        return true;
    };

    return {
        error,
        validateFiles,
    };
};
