import store from "@/store";
import { computed } from "vue";

export const useAccessControl = (requiredPermissions: string[]) => {
    const userPermissions = store.getters["auth/userPermissions"];

    const allowAccess = computed(() => {
        if (requiredPermissions.length === 0) return true;
        return requiredPermissions.every((permission) => userPermissions.includes(permission));
    });

    return { allowAccess };
};
