<script setup>
    import { computed } from "vue";

    const { fullName } = defineProps({
        fullName: {
            type: String,
            required: true,
        },
    });

    const initials = computed(() => {
        const names = fullName.split(" ");
        if (names.length === 1) {
            return names[0].charAt(0);
        }
        return `${names[0].charAt(0)}${names[1].charAt(0)}`;
    });
</script>

<template>
    <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-100 dark:!bg-gray-500">
        <span class="font-medium leading-none text-gray-400 dark:text-white">{{ initials }}</span>
    </span>
</template>
