export const textInputClassName = (props: { size: "smallest" | "compact" | "default" }) => ({
    "px-2 py-[0.2rem]": props.size == "smallest",
    "p-2": props.size == "compact",
    "p-4": props.size == "default",
    "border border-gray-400 rounded-lg w-full": true,
    "bg-transparent transition-colors": true,
});

export const requirementsClassName = () => ({
    "text-small text-gray-400 dark:text-gray-200 transition-colors": true,
});
