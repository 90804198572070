const statistics = {
    title: "Статистика",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    leadCount: {
        title: "Кількість лідів",
        subtitle: "Кількість лідів відповідно до клієнтів",
        newLeads: "Нові ліди",
        leadsConversion: "Конверсія",
        convertedLeads: "Конвертовані ліди",
        archivedLeads: "Архівовано",
    },
    dealCount: {
        title: "Угоди",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        newDeals: "Нові угоди",
        successfulDeals: "Успішні угоди",
        failedDeals: "Неуспішні угоди",
        dealsConversion: "Конверсія",
        totalDealsAmount: "Сумма за весь час",
        averageDealsAmount: "Середній чек",
    },
    table: {
        titles: {
            leads: "Ліди",
            deals: "Угоди",
        },
        tabs: {
            leads: "Робота з лідами",
            deals: "Робота з угодами",
        },
        leads: {
            fullName: "Користувач",
            totalLeads: "Всього лідів",
            converted: "Конвертованих",
            averageConversionTime: "Середній час конверсії",
            conversion: "Конверсія",
        },
        deals: {
            fullName: "Користувач",
            totalDeals: "Всього замовлень",
            successDeals: "Виграно",
            failedDeals: "Програно",
            conversion: "Конверсія",
            averageConversionTime: "Середній час конверсії",
            successDealsAmount: "Сума виграних",
            failedDealsAmount: "Сума програних",
            successDealsAverageAmount: "Середній чек по виграних",
            failedDealsAverageAmount: "Середній чек по програних",
        },
    },
};

export default statistics;
