import { LogMethod, SourceType } from "@/services/enums";

export const logEssenceRouteMap = {
    [SourceType.LEAD]: "leads",
    [SourceType.CLIENT]: "clients",
    [SourceType.DEAL]: "deals",
    [SourceType.TASK]: "tasks",
    [SourceType.PRICE_LIST]: "price-list",
};

export const logEssenceMap = {
    [SourceType.LEAD]: "lead",
    [SourceType.CLIENT]: "client",
    [SourceType.DEAL]: "deal",
    [SourceType.TASK]: "task",
    [SourceType.PRICE_LIST]: "priceList",
};
export const logActionMap = {
    [LogMethod.CREATE]: "created",
    [LogMethod.UPDATE]: "updated",
    [LogMethod.ARCHIVE]: "archived",
    [LogMethod.DE_ARCHIVE]: "dearchived",
    [LogMethod.ADD_NOVA_POST_DELIVERY_BY_TTN]: "added-nova-post-delivery-by-ttn",
    [LogMethod.CREATE_NOVA_POST_DELIVERY]: "created-nova-post-delivery",
    [LogMethod.REMOVE_DELIVERY]: "removed-delivery",
    [LogMethod.CREATE_ADDITIONAL_CONTACT]: "created-additional-contact",
    [LogMethod.UPDATE_ADDITIONAL_CONTACT]: "updated-additional-contact",
    [LogMethod.DELETE_ADDITIONAL_CONTACT]: "deleted-additional-contact",
    [LogMethod.CREATE_NOTE]: "created-note",
    [LogMethod.UPDATE_NOTE]: "updated-note",
    [LogMethod.DELETE_NOTE]: "deleted-note",
    [LogMethod.CREATE_BARCODE]: "created-barcode",
    [LogMethod.UPDATE_BARCODE]: "updated-barcode",
    [LogMethod.DELETE_BARCODE]: "deleted-barcode",
    [LogMethod.TASK_ASSIGNED_TO_CUSTOMER]: "task-assigned-to-customer",
    [LogMethod.TASK_REASSIGNED_TO_CUSTOMER]: "task-reassigned-to-customer",
    [LogMethod.TASK_ASSIGNED_TO_DEAL]: "task-assigned-to-deal",
    [LogMethod.TASK_REASSIGNED_TO_DEAL]: "task-reassigned-to-deal",
    [LogMethod.DEAL_ASSIGNED_TO_CUSTOMER]: "deal-assigned-to-customer",
    [LogMethod.DEAL_REASSIGNED_TO_CUSTOMER]: "deal-reassigned-to-customer",
    [LogMethod.DEAL_REWRITE_PRICE_LISTS]: "deal-rewrite-to-price-lists",
};
