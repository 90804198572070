export interface InputEmits {
    (e: "validated", isValid: boolean): void;
    /**
     * @deprecated use update:model-value instead of input
     */
    (e: "input", value: string, error?: string | undefined): void;
    (e: "update:modelValue", value: string, ref?: any): void;
    (e: "focusin"): void;
    (e: "focusout"): void;
}

export const inputEmitProps = (emitter: InputEmits) => ({
    input: (value: string, error?: string | undefined) => emitter("input", value, error),
    "update:modelValue": (value: string, ref?: any) => emitter("update:modelValue", value, ref),
    validated: (isValid: boolean) => emitter("validated", isValid),
    focusin: () => emitter("focusin"),
    focusout: () => emitter("focusout"),
});
