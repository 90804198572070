import i18n from "@/i18n";

export const errMsgRetriever = (e: any, withFallbackMessage = true) => {
    if (!e) return null;

    if (e.response?.data?.message) {
        return e.response?.data?.message;
    } else if (withFallbackMessage) {
        return i18n.global.t("errors.smthWentWrong");
    } else {
        return null;
    }
};
