import { RouteIds } from "@/router/consts";
import { SourceType } from "@/services/enums";

export const routeIdNameToSourceTypeMap = {
    [RouteIds.LEAD]: SourceType.LEAD,
    [RouteIds.CLIENT]: SourceType.CLIENT,
    [RouteIds.DEAL]: SourceType.DEAL,
    [RouteIds.TASK]: SourceType.TASK,
    [RouteIds.PRICE_LIST]: SourceType.PRICE_LIST,
};
