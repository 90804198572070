import { AxiosProgressEvent } from "axios";
import { Ref, ref } from "vue";
import { UploadingProgress, UploadProgressHandlers } from "@/services/types";

export const useUploadProgress = (): {
    handlers: UploadProgressHandlers;
    progress: Ref<UploadingProgress>;
} => {
    const defaultState: UploadingProgress = { progress: 0, isSuccess: false, isError: false, errorMsg: "" };
    const progress = ref<UploadingProgress>({ ...defaultState });
    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (!progressEvent.total) return;

        progress.value.progress = progressEvent.loaded / (progressEvent.total / 100);
    };

    const onSuccessUpload = () => {
        progress.value.progress = 0;
        progress.value.isSuccess = true;

        setTimeout(() => {
            progress.value = { ...defaultState };
        }, 3000);
    };

    const onErrorUpload = (errorMsg: string) => {
        progress.value.isError = true;
        progress.value.errorMsg = errorMsg;

        setTimeout(() => {
            progress.value = { ...defaultState };
        }, 3000);
    };

    return {
        progress,
        handlers: {
            onUploadProgress,
            onSuccessUpload,
            onErrorUpload,
        },
    };
};
