import api from "@/services/api";
import { DeliveryProviders } from "@/services/enums";

export const deliveryProvidersService = {
    async providerList(): Promise<any> {
        const response = await api.get("/delivery-providers");
        return response.data;
    },

    async addCredentials(deliveryProviderData: any): Promise<any> {
        return api.post("/delivery-providers", deliveryProviderData);
    },

    async updateCredentials(id: number, deliveryProviderData: any): Promise<any> {
        return api.patch(`/delivery-providers/${id}`, deliveryProviderData);
    },

    async deleteCredentials(id: number): Promise<any> {
        return api.delete(`/delivery-providers/${id}`);
    },

    async addGeneralSettings(deliveryProviderData: any): Promise<any> {
        return api.post("/delivery-providers/settings", deliveryProviderData);
    },

    async updateGeneralSettings(deliveryProviderData: any): Promise<any> {
        return api.patch("/delivery-providers/settings", deliveryProviderData);
    },

    async getGeneralSettings(provider: DeliveryProviders): Promise<any> {
        const response = await api.get("/delivery-providers/settings", {
            params: { provider },
        });
        return response.data;
    },

    async addCredentialSettings(credentialId: number): Promise<any> {
        return api.post(`/delivery-providers/settings/${credentialId}`);
    },

    async updateCredentialSettings(credentialId: number, data: any): Promise<any> {
        return api.patch(`/delivery-providers/settings/${credentialId}`, data);
    },

    async getCredentialSettings(credentialId: number, provider: DeliveryProviders): Promise<any> {
        const response = await api.get(`/delivery-providers/settings/${credentialId}`, {
            params: { provider },
        });
        return response.data;
    },
};
