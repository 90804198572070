import { UserCallStatus } from "@/services/enums/user-call-status.enum";

const auth = {
    navBar: {
        login: "Ввійти",
        signUp: "Зареєструватися",
    },
    modals: {
        navBar: {
            login: "Вхід",
            signUp: "Зареєструватись",
        },
        login: {
            header: "Вхід",
            signIn: "Ввійти",
            signInWithGoogle: "Sign in with Google",
            register: {
                noAccount: "Немає акаунта?",
                register: "Зареєструватись",
            },
            error: "Помилка входу. Перевірте пароль та логін",
            resetPassword: "Забули пароль?",
        },
        register: {
            header: "Реєстрація",
            signUp: "Зареєструватись",
            signUpWithGoogle: "Sign up with Google",
            login: {
                alreadyRegistered: "Вже зареєстровані?",
                login: "Ввійти",
            },
            error: "Помилка реєстрації... Скористайтеся підтримкою",
        },
        confirm: {
            header: "Верифікація",
            authenticatorHeader: "Google authenticator верифікація",
            subtitle: "Будь ласка введіть 4 значний код, надісланий на пошту {email}",
            didntReceiveCode: "Не прийшов код?",
            resendCode: "Надіслати повторно",
            changeEmail: "Змінити пошту",
            continue: "Продовжити",
            error: "Помилка верифікації, перевірте код",
        },
        privacyPolicy: {
            header: "Натискаючи “Зареєструватись” ви приймаєте правила",
            privacyPolicy: "Privacy Policy",
        },
        generate2FA: {
            header: "Закінчення реєестрації",
            subtitle:
                "Для закінчення авторизації потрібно підключити двохфакторну автентифікацію. " +
                "Зайдіть у застосунок, наприклад Google Authenticator, та проскануйте в ньому qr-код нижче:",
            error: "Помилка активації двохфакторної автентифікації. Зверніться до підтримки",
            continueText: "Після сканування коду, натисніть:",
            continue: "Продовжити",
        },
        authenticate2FA: {
            header: "Закінчення реєестрації",
            subtitle: "Введіть код в вашому застосунку для двохфакторної автентифікації, наприклад Google Authenticator",
            error: "Помилка активації двохфакторної автентифікації. Зверніться до підтримки",
            code: "Шостизначний код з застосунку",
            continue: "Продовжити",
            back: "Назад",
        },
        setPassword: {
            header: "Встановити пароль",
            text: "Схоже ваш акаунт не має паролю, бажаєте додати ?",
        },
        resetPassword: {
            header: "Відновлення паролю",
            subtitle: "Будь ласка введіть вашу пошту, на яку буде надісланий 4-х значний код",
            submit: "Продовжити",
            back: "Скасувати",
        },
        resetPasswordCode: {
            header: "Відновлення паролю",
        },
        resetPasswordPassword: {
            header: "Відновлення паролю",
            back: "Назад",
            submit: "Змінити",
            success: "Пароль змінено! Перекидую вас на сторінку логіну...",
        },
    },
    inputs: {
        name: "Ім’я та прізвище",
        nameError: "Введіть коректне ім’я та прізвище",
        email: "Ваша пошта",
        emailError: "Формат електронної пошти має бути {format}",
        fullName: "ПІБ не може бути порожнім або містити лише пробіли і мати менше 3 символів",
        password: "Ваш пароль",
        passwordError: "Мінімум 8 символів",
        phone: "Ваш номер телефону",
        phoneError: "Знак плюс з кодом країни, наприклад +380951111111",
        repeatPassword: "Повторіть пароль",
        repeatPasswordError: "Паролі не співпадають!",
        confirmationCodeError: "Код повинен містити 4 цифри",
        newPassword: "Придумайте пароль",
        newPasswordRepeat: "Повторіть пароль",
        repeatPasswordRequirement: "Паролі повинні співпадати",
        newPasswordRequirements: "Мінімум 8 символів, 1 велика літера, 1 маленька",
    },
    callStatus: {
        [UserCallStatus.ACTIVE]: "Активний",
        [UserCallStatus.DND]: "Не турбувати",
        [UserCallStatus.BUSY]: "Зайнятий",
        collapse: "Згорнути",
    },
    invitation: {
        support: "підтримки",
        invalidInvitation: "Щось пішло не так із запрошенням, будь ласка, зверніться до",
        finishSignUpByInvitation: "Завершіть реєстрацію",
    },
};

export default auth;
