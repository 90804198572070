export interface ModalProps {
    title?: string;
    opened: boolean;
    otherModalOpened?: boolean;
    closeOnOutside?: boolean;
    loading?: boolean;
    containerClassName?: string;
    bodyClassName?: string;
    footerClassName?: string;
    showCloseButton?: boolean;
    overflowY?: "visible" | "scroll";
}

export interface ModalEmits {
    (e: "close"): void;
}

export const modalEmitProps = (emitter: ModalEmits) => ({
    close: () => emitter("close"),
});
