import { computed } from "vue";
import { Store, useStore } from "vuex";

import { Modal, ModalsState } from "@/store/modules/modals";

export const useModal = <T extends Record<string, any> = Record<string, any>>(modal: Modal) => {
    const store: Store<{ modals: ModalsState }> = useStore();

    const modalOpened = computed(() => store.state.modals.currentModal == modal);
    const closeModal = async () => store.commit("modals/closeModal");
    async function openModal(modal: Modal, params?: T): Promise<void> {
        if (params) store.commit("modals/setParams", params);
        store.commit("modals/openModal", modal);
    }

    const currentModal = computed(() => store.state.modals.currentModal);
    const otherModalOpened = computed(() => ![modal, undefined].includes(store.state.modals.currentModal));
    const params = computed(() => store.state.modals.params as T | undefined);

    return { modalOpened, closeModal, openModal, currentModal, otherModalOpened, params };
};
