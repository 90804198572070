import api from "@/services/api";
import { DeliveryStatusCode } from "@/services/types";

export const deliveryStatusCodesService = {
    async deliveryStatusCodesList() {
        return await api.get<DeliveryStatusCode[]>("/delivery-status-codes", {
            params: {},
        });
    },
};
