import api from "@/services/api";
import { CreateAdditionalContact, Locale, TaskFilters, TaskList, TaskSorting } from "@/services/types";
import { SourceType } from "./enums";
import { additionalContactFilers } from "@/services/types/additionalContacts/additional-contact-filters";
import { AdditionalContactSorting } from "@/services/types/additionalContacts/additional-contact-sorting";

export const additionalContactsService = {
    async create(payload: CreateAdditionalContact, sourceType: SourceType) {
        const response = await api.post("/additional-contacts", payload, { params: { sourceType } });
        return response.data;
    },

    async update(id: number, payload: CreateAdditionalContact, sourceType: SourceType) {
        const response = await api.patch(`/additional-contacts/${id}`, payload, { params: { sourceType } });
        return response.data;
    },

    async delete(id: number, sourceType: SourceType) {
        const response = await api.delete(`/additional-contacts/${id}`, { params: { sourceType } });
        return response.data;
    },

    async contactList(params: additionalContactFilers | AdditionalContactSorting | Locale | SourceType) {
        const response = await api.get("/additional-contacts", {
            params: params,
        });
        return response.data;
    },
};
