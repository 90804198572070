import api from "@/services/api";
import { SourceType } from "@/services/enums";
import { CustomerType } from "@/services/types";

export const customerTypesService = {
    async customerTypeList(sourceType: SourceType) {
        return await api.get<CustomerType[]>("/customer-types", {
            params: { sourceType },
        });
    },
    async removeCustomerType(customerTypeId: number, sourceType: SourceType) {
        return await api.delete(`/customer-types/${customerTypeId}`, { params: { sourceType } });
    },
    async createCustomerType(payload: Omit<CustomerType, "id" | "isPredefinedBySystem">) {
        const { sourceType, ...customerType } = payload;

        return await api.post("/customer-types", customerType, { params: { sourceType } });
    },
    async updateCustomerType(customerTypeId: number, payload: Partial<Omit<CustomerType, "id">>, sourceType: SourceType) {
        return await api.patch(`/customer-types/${customerTypeId}`, payload, {
            params: { sourceType },
        });
    },
};
