import { CustomField } from "@/services/types";
import { CustomFieldEssenceMixin } from "@/services/types/common/essences";
import { TableColumn } from "@/uikit/components/Views/Table/types";

export const fieldColumnsWrapper = (defaultColumns: TableColumn[], customFields: CustomField[], actionsLabel?: string) => {
    return [
        ...defaultColumns,
        ...customFields.map((cf) => ({
            label: cf.name,
            key: String(cf.id),
            isOrderable: false,
        })),
        actionsLabel && {
            label: actionsLabel,
            key: "actions",
            customBody: true,
            isOrderable: false,
        },
    ].filter(Boolean) as TableColumn[];
};
export const fieldValuesWrapper = <T extends CustomFieldEssenceMixin>(defaultData: undefined | T[], customFields: CustomField[]) => {
    return (defaultData || []).map((essence) => {
        const values = customFields
            .map((cf) => (essence.customFieldValues || []).find((value) => value.sourceField == cf.id))
            .reduce((prev, cur) => ({ ...prev, [String(cur?.sourceField)]: cur?.value }), {});
        return { ...essence, ...values };
    });
};
