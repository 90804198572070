import { onMounted, ref, reactive } from "vue";
import { getStructure } from "./storage";

export const useLocalization = () => {
    const structure = ref(getStructure());

    // localizationKey: "components.select.placeholder"
    const getLocalizationKey = (localizationKey: string): string | undefined => {
        let value = structure.value;
        localizationKey.split(".").forEach((key) => {
            if (value === undefined) return undefined;
            // @ts-expect-error element implicitly has any type
            value = value[key];
        });
        return String(value || "");
    };

    onMounted(() => {
        window.addEventListener("localstorage-changed", () => {
            structure.value = reactive(getStructure());
        });
    });

    return { t: getLocalizationKey, structure };
};
