export const enUsLocaleName = "en-us";
export const ukUaLocaleName = "uk-ua";

export const backendEnUsLocaleName = "en";
export const backendUkUaLocaleName = "ua";

export const backendFrontendLocalsMap = {
    [enUsLocaleName]: backendEnUsLocaleName,
    [ukUaLocaleName]: backendUkUaLocaleName,
};

export const i18nLocalStorageKey = "locale";
export const fallbackLocale = ukUaLocaleName;
