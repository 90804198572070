const calls = {
    title: "Calls",
    subtitle: "Track the essentials on the Dashboard",
    addLead: "Lead",
    table: {
        title: "Information about the calls",
        panel: {
            searchPlaceholder: "Search",
            tabs: {
                all: "All calls",
                incoming: "Incoming",
                outgoing: "Outgoing",
                missed: "Missed",
            },
            filter: "Filter",
        },
        columns: {
            id: "ID",
            type: "Mark",
            sourceNumber: "Source number",
            targetNumber: "Target number",
            internalNumber: "Internal source/target number",
            duration: "Call duration",
            startedAt: "Call date-time",
            actions: "Actions",
            buttons: {
                deal: "Deal",
                add: "Add",
            },
        },
        callTypes: {
            incoming: "Incoming",
            outgoing: "Outgoing",
            missed: "Missed",
        },
    },
    filter: {
        header: "Filter",
        inputs: {
            sourceNumber: "Source number",
            targetNumber: "Target number",
            internalNumber: "Internal number",
            duration: "Duration (sec)",
            startedFrom: "Call date-time from",
            startedTo: "Call date-time to",
        },
        placeholders: {
            number: "Enter number",
        },
        apply: "Apply",
        clear: "Clear",
    },
    callWindow: {
        titleFrom: "Call from: ",
        titleTo: "Call to number: ",
        buttons: {
            hide: "Hide",
            createDeal: "Create deal",
            createTask: "Create task",
            answer: "Answer",
            skip: "Dismiss",
            hangup: "Hang up",
            cancel: "Cancel",
        },
        callTimeTitle: "Call time: ",
        callStatuses: {
            busy: "The subscriber is busy",
            connecting: "Connecting",
            notRegistered: "No registered sip number",
            awaiting: "The caller is waiting on the line",
            connected: "Connected",
        },
        tasksTitle: "Tasks:",
        dealsTitle: "Deals:",
        responsibleTitle: "Responsible:",
        unknownUser: "Unknown user",
    },
    transferToWindow: {
        header: "Transfer call to: ",
        title: "User",
        button: "Transfer",
    },
};

export default calls;
