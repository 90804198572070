import { AxiosResponse } from "axios";
import { paginationState } from "@/store/mixins";
import { ActionContext } from "vuex";
import { additionalContactsService } from "@/services";
import { additionalContactFilers } from "@/services/types/additionalContacts/additional-contact-filters";
import { AdditionalContactSorting } from "@/services/types/additionalContacts/additional-contact-sorting";
import { SourceType } from "@/services/enums";
import { CreateAdditionalContact } from "@/services/types";

type addAdditionalContactResponse = AxiosResponse<{ _: string }> | undefined;

export interface AdditionalContactState {
    additionalContacts: [];
    addAdditionalContact: addAdditionalContactResponse;
    additionalContact: "";
}

const AdditionalContactModule = {
    namespaced: true,
    state: {
        ...paginationState,
        additionalContacts: [],
        addAdditionalContact: undefined as addAdditionalContactResponse,
        additionalContact: undefined,
    },
    mutations: {
        setAddedAdditionalContact: (state: AdditionalContactState, payload: addAdditionalContactResponse): void => {
            state.addAdditionalContact = payload;
        },
        setAdditionalContacts: (state: AdditionalContactState, payload: any): void => {
            state.additionalContacts = payload;
        },
    },
    actions: {
        additionalContactList: async ({ commit }: ActionContext<unknown, unknown>, filters: additionalContactFilers | AdditionalContactSorting | SourceType) => {
            try {
                const { additionalContacts } = await additionalContactsService.contactList(filters);
                commit("setAdditionalContacts", additionalContacts);
            } catch (e) {
                console.error(e);
            }
        },
        deleteAdditionalContact: async ({ dispatch }: ActionContext<unknown, unknown>, payload: { id: number; sourceType: SourceType }) => {
            try {
                const { id, sourceType } = payload;
                await additionalContactsService.delete(id, sourceType);
            } catch (error) {
                console.error(error);
            }
        },
        createAdditionalContact: async (
            { commit }: ActionContext<unknown, unknown>,
            payload: { payloadContact: CreateAdditionalContact; sourceType: SourceType }
        ): Promise<void> => {
            commit("setAddedAdditionalContact", undefined);
            try {
                const { payloadContact, sourceType } = payload;
                const res = await additionalContactsService.create(payloadContact, sourceType);
                commit("setAddedAdditionalContact", res);
                return res;
            } catch (e: any) {
                commit("setAddedAdditionalContact", e.response || { statusText: e.code });
            }
        },
    },
};

export default AdditionalContactModule;
