import { createApp } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import "../teliqon-ui-kit/src/style.css";
import "./assets/css/tailwind.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-away.css";
import "vue-toastification/dist/index.css";

createApp(App).use(store).use(router).use(i18n).use(VueQueryPlugin).use(VueTippy).use(Toast).mount("#app");
