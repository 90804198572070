import api from "@/services/api";
import { Statistic, DashboardStatistic, StatisticFilters, DashboardStatisticFilters } from "@/services/types";

export const statisticsService = {
    async statistics(filters: StatisticFilters) {
        return await api.get<Statistic>("/statistics", {
            params: filters,
        });
    },
    async dashboardStatistics(filters: DashboardStatisticFilters) {
        return await api.get<DashboardStatistic>("/statistics/dashboard", {
            params: filters,
        });
    },
};
