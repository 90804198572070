import { ActionContext, useStore } from "vuex";

import { usersService } from "@/services";
import { User, CreateUser, UpdateUser, UserFilters } from "@/services/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { AuthState } from "./auth";

export interface UserState {
    users: User[];
    selectInputOptions: Partial<User>[];
}

const UsersModule = {
    namespaced: true,
    state: {
        users: [] as User[],
        selectInputOptions: [] as Partial<User>[],
    },
    mutations: {
        setUsers: (state: UserState, payload: User[]): void => {
            state.users = payload;
        },
        setUsersForSelectInput: (state: UserState, payload: Partial<User>[]): void => {
            state.selectInputOptions = payload;
        },
    },
    actions: {
        getSelectInputOptions: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const users = await usersService.someFields({ fields: ["id", "fullName", "login", "status"] });
                commit("setUsersForSelectInput", users);
            } catch (e) {
                console.error(e);
            }
        },
        createUser: async (_commit: unknown, payload: CreateUser): Promise<void> => {
            try {
                await usersService.createUser(payload);
            } catch (e) {
                console.error(e);
            }
        },
        updateUser: async (_commit: unknown, { id, payload }: { id: number; payload: UpdateUser }): Promise<void> => {
            try {
                await usersService.updateUser(id, payload);
            } catch (e) {
                console.error(e);
            }
        },
        removeUser: async (_commit: unknown, id: number): Promise<void> => {
            try {
                await usersService.removeUser(id);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const USERS_LIST_KEY = "users-list";

export const useUsersList = (params: UserFilters) =>
    useQuery({
        queryKey: [USERS_LIST_KEY],
        queryFn: () => usersService.userList(params),
        initialData: { users: [], totalCount: 0 },
    });

export const useDeleteUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (userId: number) => usersService.deleteUser(userId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USERS_LIST_KEY] });
        },
    });
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (user: CreateUser) => usersService.createUser(user),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [USERS_LIST_KEY] }),
    });
};

export const useUserInfo = (id: number) =>
    useQuery({
        queryKey: ["getUser", id],
        queryFn: () => usersService.getUserById(id),
    });

export const useUpdateUser = (userId: number) => {
    const queryClient = useQueryClient();
    const store = useStore<AuthState>();

    return useMutation({
        mutationFn: (payload: UpdateUser) => usersService.updateUser(userId, payload),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["getUser", userId] });
            await store.dispatch("auth/getProfile");
        },
    });
};

export default UsersModule;
