//
// from maz-ui use-libphonenumber.ts
//
// https://github.com/LouisMazel/maz-ui/blob/dfe9c0b2746f1daab8c51079d1d75a40296cfedc/packages/lib/
// components/MazPhoneNumberInput/use-libphonenumber.ts
//

import {
    parsePhoneNumberFromString,
    AsYouType,
    type CountryCode,
    getCountries,
    getCountryCallingCode,
    getExampleNumber,
    isSupportedCountry,
    type Examples,
    CountryCallingCode,
} from "libphonenumber-js";
import type { Country, IpWhoResponse, Result } from "./types";

let displayNamesInstance: Intl.DisplayNames | undefined = undefined;
let displayNamesLocale: string | undefined = undefined;

function getCountryName(
    locale: string,
    code: CountryCode | string,
    customCountriesNameListByIsoCode?: Record<CountryCode, string>
): string | undefined {
    // @ts-expect-error error
    if (customCountriesNameListByIsoCode?.[code]) {
        // @ts-expect-error error
        return customCountriesNameListByIsoCode[code];
    }

    if (displayNamesLocale !== locale || !displayNamesInstance) {
        displayNamesLocale = locale;
        displayNamesInstance = new Intl.DisplayNames([locale], { type: "region" });
    }

    return displayNamesInstance.of(code);
}

const PHONE_CHAR_REGEX = /^[\d ().-]+$/;
const NON_ALPHA_REGEX = /^[^a-z]+$/i;

let examples: Examples;

async function loadPhoneNumberExamplesFile() {
    const { default: data } = await import("libphonenumber-js/examples.mobile.json");
    examples = data;
    return examples;
}

function isSameCountryCallingCode(countryCode: CountryCode, countryCode2: CountryCode) {
    return getCountryCallingCode(countryCode) === getCountryCallingCode(countryCode2);
}

async function getPhoneNumberExample(countryCode?: CountryCode) {
    if (!examples) await loadPhoneNumberExamplesFile();
    try {
        return countryCode ? getExampleNumber(countryCode, examples)?.formatNational() : undefined;
    } catch (error) {
        console.error(`[maz-ui](MazPhoneNumberInput) ${error}`);
    }
}

function sanitizePhoneNumber(input?: string) {
    if (!input) {
        return "";
    }

    const hasNonAlpha = NON_ALPHA_REGEX.test(input);
    const hasPhoneChar = PHONE_CHAR_REGEX.test(input);

    if (!hasNonAlpha && !hasPhoneChar) {
        return input.replaceAll(/[^\d.]/g, "");
    }

    return input;
}

function getCountriesList(
    locale?: string,
    customCountriesNameListByIsoCode?: Record<CountryCode, string>
): Country[] | undefined {
    const countriesList: Country[] = [];
    const isoList = getCountries();

    locale = locale ?? browserLocale().browserLocale ?? "en-US";

    for (const iso2 of isoList) {
        const name = getCountryName(locale, iso2, customCountriesNameListByIsoCode);

        if (name) {
            try {
                const dialCode = getCountryCallingCode(iso2);
                countriesList.push({
                    iso2,
                    dialCode,
                    name,
                });
            } catch (error) {
                console.error(`[MazPhoneNumberInput](getCountryCallingCode) ${error}`);
            }
        }
    }

    return countriesList;
}

function browserLocale() {
    if (typeof window === "undefined") {
        return {};
    }

    const browserLocale = window.navigator.language;

    if (!browserLocale) {
        return {};
    }

    let locale = browserLocale.slice(3, 7).toUpperCase();

    if (locale === "") {
        locale = browserLocale.slice(0, 2).toUpperCase();
    }

    if (locale === "EN") {
        locale = "US";
    }
    if (locale === "JA") {
        locale = "JP";
    }

    return { locale, browserLocale };
}

export function isCountryAvailable(locale: string) {
    try {
        const response = isSupportedCountry(locale);

        if (!response) {
            console.error(`[maz-ui](MazPhoneNumberInput) The code country "${locale}" is not available`);

            return false;
        }

        return response;
    } catch (error) {
        console.error(`[maz-ui](MazPhoneNumberInput) ${error}`);
        return false;
    }
}

function countryCodeToUnicodeFlag(countryCode: string | undefined) {
    if (!countryCode) return "";
    return [...countryCode]
        .map((letter) => {
            const code = letter.codePointAt(0);
            return code ? (code % 32) + 0x1_f1_e5 : undefined;
        })
        .filter((v) => !!v)
        .map((n) => String.fromCodePoint(n || 0))
        .join("");
}

const getResultsFromPhoneNumber = ({
    phoneNumber,
    countryCode,
}: {
    phoneNumber?: string;
    countryCode?: CountryCode;
}): Result => {
    try {
        if (!phoneNumber) {
            return {
                isValid: false,
                countryCode,
            };
        }

        const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

        return {
            isValid: parsedNumber?.isValid() ?? false,
            isPossible: parsedNumber?.isPossible(),
            countryCode: parsedNumber?.country,
            countryCallingCode: parsedNumber?.countryCallingCode,
            nationalNumber: parsedNumber?.nationalNumber,
            type: parsedNumber?.getType(),
            formatInternational: parsedNumber?.formatInternational(),
            formatNational: parsedNumber?.formatNational(),
            uri: parsedNumber?.getURI(),
            e164: parsedNumber?.format("E.164"),
            rfc3966: parsedNumber?.format("RFC3966"),
        };
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](getResultsFromPhoneNumber) ${error}`);
    }
};

function getAsYouTypeFormat(countryCode?: CountryCode, phoneNumber?: string) {
    try {
        if (!phoneNumber) {
            return;
        }

        return new AsYouType(countryCode).input(phoneNumber);
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](getAsYouTypeFormat) ${error}`);
    }
}

async function fetchCountryCode() {
    try {
        const reponse = await fetch("https://ipwho.is");
        const { country_code } = (await reponse.json()) as IpWhoResponse;

        return country_code;
    } catch (error) {
        throw new Error(`[MazPhoneNumberInput](fetchCountryCode) ${error}`);
    }
}

function getCountryByCountryIsoCode(iso2: CountryCallingCode | undefined) {
    return getCountriesList()?.filter((country) => country.iso2 == iso2)[0];
}

export function useLibphonenumber() {
    return {
        fetchCountryCode,
        getAsYouTypeFormat,
        getResultsFromPhoneNumber,
        loadPhoneNumberExamplesFile,
        getPhoneNumberExample,
        sanitizePhoneNumber,
        getCountriesList,
        browserLocale,
        isSameCountryCallingCode,
        getCountryByCountryIsoCode,
        countryCodeToUnicodeFlag,
    };
}
