import api from "@/services/api";
import { Role, CreateRole, UpdateRole, Permission } from "@/services/types";

export const rolesService = {
    async roleList(): Promise<Role[]> {
        const response = await api.get("/roles");
        return response.data;
    },
    async permissionList(): Promise<Permission[]> {
        const response = await api.get("/roles/permissions");
        return response.data;
    },
    async createRole(payload: CreateRole): Promise<void> {
        const response = await api.post("/roles", payload);
        return response.data;
    },
    async updateRole(id: number, payload: UpdateRole): Promise<void> {
        const response = await api.patch(`/roles/${id}`, payload);
        return response.data;
    },
    async removeRole(id: number): Promise<void> {
        const response = await api.delete(`/roles/${id}`);
        return response.data;
    },
    async getUsersCountWithRole(roleId: number) {
        const response = await api.get(`/roles/users-count-with-role/${roleId}`);
        return response.data;
    },
};
