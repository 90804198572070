import { ActionContext } from "vuex";
import { AxiosResponse } from "axios";

import { tasksService } from "@/services";
import { CreateTask, UpdateTask, Task, TaskFilters, TaskSorting, Locale, Pagination } from "@/services/types";
import { paginationState, paginationMutations, PaginationState, customFieldsState, customFieldsMutations, CustomFieldsState } from "@/store/mixins";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";
import { DownloadFileTypeOptions } from "@/utils/import-export";

type TaskResponse = AxiosResponse<{ _: string }> | undefined;

export interface TaskState extends CustomFieldsState, PaginationState {
    tasks: Task[];
    createTask: TaskResponse;
    updateTask: TaskResponse;
    selectInputOptions: Partial<Task>[];
    task: Task;
}

const TasksModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
        ...paginationState,
        tasks: [] as Task[],
        createTask: undefined as TaskResponse,
        updateTask: undefined as TaskResponse,
        selectInputOptions: [] as Partial<Task>[],
        task: undefined,
    },
    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setCreateTask: (state: TaskState, payload: TaskResponse): void => {
            state.createTask = payload;
        },
        setUpdateTask: (state: TaskState, payload: TaskResponse): void => {
            state.updateTask = payload;
        },
        setTasks: (state: TaskState, payload: Task[]): void => {
            state.tasks = payload;
        },
        setTask: (state: TaskState, payload: Task): void => {
            state.task = payload;
        },
        setTasksForSelectInput: (state: TaskState, payload: Partial<Task>[]): void => {
            state.selectInputOptions = payload;
        },
    },
    actions: {
        taskList: async ({ commit }: ActionContext<unknown, unknown>, filters: TaskFilters | TaskSorting | Locale): Promise<void> => {
            try {
                const { tasks, customFields, page, pagesCount } = await tasksService.taskList(filters);
                commit("setTasks", tasks);
                commit("setCustomFields", customFields);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        getSelectInputOptions: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const tasks = await tasksService.someFields({ fields: ["id", "name"] });
                commit("setTasksForSelectInput", tasks);
            } catch (e) {
                console.error(e);
            }
        },
        getTask: async ({ commit }: ActionContext<unknown, unknown>, { id, locale }: { id: string; locale: Locale }) => {
            const task = await tasksService.getTask(id, locale);
            commit("setTask", task);
            return task;
        },

        createTask: async ({ commit }: ActionContext<unknown, unknown>, payload: CreateTask) => {
            commit("setCreateTask", undefined);
            try {
                const res = await tasksService.createTask(payload);
                commit("setCreateTask", res);
                return res;
            } catch (e: any) {
                commit("setCreateTask", e.response || { statusText: e.code });
                throw e;
            }
        },
        updateTask: async ({ commit }: ActionContext<unknown, unknown>, { id, payload, locale }: { id: number; payload: UpdateTask; locale: Locale }): Promise<void> => {
            commit("setUpdateTask", undefined);
            try {
                const res = await tasksService.updateTask(id, payload, locale);
                commit("setUpdateTask", res);
            } catch (e: any) {
                commit("setUpdateTask", e.response || { statusText: e.code });
                throw e;
            }
        },
        async bulkUpdate(_commit: unknown, { data, locale }: { data: UpdateTask; locale: Locale }): Promise<void> {
            try {
                return tasksService.bulkUpdate(data, locale);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useTaskFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) => {
    return useQuery({
        queryKey: ["tasks/fields"],
        queryFn: () => tasksService.taskFieldsList(locale).then((res) => res.data),
        enabled,
    });
};

export const useExport = () =>
    useMutation({
        mutationFn: (filters: { locale: "ua" | "en" } & { essenceFieldIds: string[]; customFieldIds: number[] } & Omit<TaskFilters, keyof Pagination> & TaskSorting) =>
            tasksService.exportTasks(filters).then((res) => res.data),
    });

export const useImport = () =>
    useMutation({
        mutationFn: (data: { locale: "ua" | "en" } & { file: File; uniqueFieldId: string }) => tasksService.importTasks(data).then((res) => res.data),
    });

export const useImportTemplate = () =>
    useMutation({
        mutationFn: (fileType: DownloadFileTypeOptions) => tasksService.importTasksTemplate(fileType).then((res) => res.data),
    });

export default TasksModule;
