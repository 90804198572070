import { ActionContext } from "vuex";
import { referencesService } from "@/services";
import { Currency } from "@/services/types";
import { useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";

export interface ReferencesState {
    currency: Currency[];
}

const ReferencesModule = {
    namespaced: true,
    state: {
        currency: [],
    } as ReferencesState,
    mutations: {
        setCurrency: (state: ReferencesState, payload: Currency[]): void => {
            state.currency = payload;
        },
    },
    actions: {
        getCurrency: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const data = await referencesService.getCurrencyList();
                commit("setCurrency", data);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

const FUNNELS_REFERENCES_LIST = "funnels-references-list";

export const useFunnelsRefs = ({ enabled }: { enabled: ComputedRef<boolean> } = { enabled: computed(() => true) }) => {
    return useQuery({
        queryKey: [FUNNELS_REFERENCES_LIST],
        queryFn: () => referencesService.getFunelsList(),
        enabled,
    });
};

export const usePriceListRefs = ({ enabled }: { enabled: ComputedRef<boolean> } = { enabled: computed(() => true) }) => {
    return useQuery({
        queryKey: [FUNNELS_REFERENCES_LIST],
        queryFn: () => referencesService.getPriceLists(),
        enabled,
    });
};

export default ReferencesModule;
