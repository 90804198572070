import { Permission } from "@/services/types";
export const DelimiterBetweenResourceAndAccessLevel = ":";

// Todo fully duplicated on the backend part
export const groupPermissionByResource = (permissions: Permission[]): Record<string, Permission[]> => {
    return permissions.reduce((grouped: Record<string, Permission[]>, permission: Permission) => {
        const [resource, accessLevel] = permission.name.split(DelimiterBetweenResourceAndAccessLevel);

        if (resource && accessLevel) {
            if (resource in grouped) {
                grouped[resource].push(permission);
            } else {
                grouped[resource] = [permission];
            }
        }

        return grouped;
    }, {} as Record<string, Permission[]>);
};

// Todo fully duplicated on the backend part
export const splitPermissionToParts = (permission: Permission): { id: number; resource: string; accessLevel: string } => {
    const parts = permission.name.split(DelimiterBetweenResourceAndAccessLevel);

    if (parts.length !== 2) {
        throw new Error("Incorrectly formed permission, must be resource:accessLevel");
    }

    return {
        id: permission.id,
        resource: parts[0],
        accessLevel: parts[1],
    };
};

export const concatPermissionValue = (resource: string, accessLevel: string) => `${resource}${DelimiterBetweenResourceAndAccessLevel}${accessLevel}`;
