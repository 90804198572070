import api from "@/services/api";
import { Position, DraftPosition, PositionsFilters } from "@/services/types";

export const positionsService = {
    async positionsList(params: PositionsFilters): Promise<{ positions: Position[]; totalCount: number }> {
        const response = await api.get("/positions", { params });
        return response.data;
    },
    async createPosition(position: DraftPosition): Promise<Position> {
        const response = await api.post("/positions", position);
        return response.data;
    },
    async updatePosition({ id, name }: Position): Promise<Position> {
        const response = await api.patch(`/positions/${id}`, { name });
        return response.data;
    },
    async deletePosition(id: number): Promise<void> {
        await api.delete(`/positions/${id}`);
    },

    async getUsersCountWithPosition(positionId: number) {
        const response = await api.get(`/positions/users-count-with-position/${positionId}`);
        return response.data;
    },
};
