import { ActionContext } from "vuex";

import { referencesService, rolesService } from "@/services";
import { Role, Permission, UpsertRoleWithPermissions, UpdateRole, CreateRole } from "@/services/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";

export interface RoleState {
    roles: Role[];
    permissions: Permission[];
}

const RolesModule = {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {},
};

const ROLES_LIST_KEY = "roles-list";
const PERMISSIONS_LIST_KEY = "permissions-list";
const COUNT_USERS_WITH_ROLE = "count-users-with-role";

// todo move to references
export const useRoles = ({ enabled }: { enabled: ComputedRef<boolean> } = { enabled: computed(() => true) }) =>
    useQuery({
        queryKey: [ROLES_LIST_KEY],
        queryFn: async () => {
            const res = await referencesService.getRolesList();
            return res;
        },
        initialData: [],
        enabled,
    });

export const useRoleList = () =>
    useQuery({
        queryKey: [ROLES_LIST_KEY],
        queryFn: () => rolesService.roleList(),
    });

export const usePermissionList = () =>
    useQuery({
        queryKey: [PERMISSIONS_LIST_KEY],
        queryFn: () => rolesService.permissionList(),
        initialData: [],
    });

export const useUpdateRole = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, data }: { id: number; data: UpdateRole }) => rolesService.updateRole(id, data),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [ROLES_LIST_KEY] }),
    });
};

export const useCreateRole = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateRole) => rolesService.createRole(data),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [ROLES_LIST_KEY] }),
    });
};

export const useRemoveRole = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) => rolesService.removeRole(id),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [ROLES_LIST_KEY] }),
    });
};

export const useCountUsersWithRole = (roleId: number) =>
    useQuery({
        queryKey: [COUNT_USERS_WITH_ROLE, roleId],
        queryFn: () => rolesService.getUsersCountWithRole(roleId),
        initialData: [],
    });

export default RolesModule;
