import api from "@/services/api";

export const logService = {
    async logList(essence: string, id: number) {
        const response = await api.get(`/logs/${essence}/${id}`);
        return response.data;
    },

    async notificationHistory(page: number) {
        const response = await api.get(`/logs/notifications-history`, {
            params: { page },
        });
        return response.data;
    },
};
