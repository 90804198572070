import Joi from "joi";
import { enUsLocaleName, POSTION_NAME_LENGTH, ukUaLocaleName } from "../constants";

const errMsgs = {
    [ukUaLocaleName]: {
        "string.max": `Назва позиції має бути менше чи дорівнює ${POSTION_NAME_LENGTH} символам.`,
        "string.empty": "Назва позиції не може бути порожньою.",
        "any.required": "Назва позиції є обов'язковим полем.",
    },
    [enUsLocaleName]: {
        "string.max": `Position name must be less than or equal to ${POSTION_NAME_LENGTH} characters.`,
        "string.empty": "Position name cannot be empty.",
        "any.required": "Position name is a required field.",
    },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const positionNameSchema = Joi.string().max(POSTION_NAME_LENGTH).required().messages(errMsgs);
