const mail = {
    noEmailAccount: "Поштовий акаунт ще не підключено",
    connectButton: "Підключити пошту",
    newMail: "Новий лист",
    newMessage: {
        title: "Нове повідомлення",
        from: "Від",
        to: "Кому",
        subject: "Тема",
        placeholder: "Додати текст.....",
        sendButton: "Надіслати",
        saveAsDraft: "Зберегти як чернетку",
    },
    filters: {
        title: "Фільтр",
        unseen: "Непрочитані",
        withAttachments: "Тільки з вкладеннями",
        notLead: "Без прив'язки до ліда",
        notClient: "Без прив'язки до клієнта",
        apply: "Застосувати",
        clear: "Скасувати",
    },
    createAccount: {
        title: "Підключіть обліковий запис електронної пошти",
        name: "Ім'я користувача",
        email: "Пошта",
        password: "Пароль",
        imapHost: "Сервер вхідної електронної пошти",
        port: "Порт",
        smtpHost: "Сервер вихідної електронної пошти",
        send: "Підтвердити",
        notUsingImap: "Не використовуєте IMAP?",
        instructionDescr: "Щоб продовжити, введіть свої облікові данні",
        recomend: "Замість IMAP, ми рекомендуємо",
        reason: "щоб бути впевненим в надійності синхронізації пошти",
        usingAltProvider: "використовувати альтернативного постачальника послуг електронної пошти",
        changeEmailProvider: "Змінити постачальника послуг електронної пошти",
        instruction: "Інструкція",
    },
    actions: {
        markAsTitle: "Помітити як",
        seen: "Прочитаними",
        unSeen: "Непрочитаними",
        starred: "З зірочкою",
        notStarred: "Без зірочки",
        archive: "Архівування",
    },
    back: "Назад",
    reply: "Відповісти",
    forward: "Переслати",
    markAsUnread: "Позначити як непрочитане",
    archive: "Архівувати",
    unarchive: "Деархівувати",
    peopleInThread: "Людей в даній переписці",
    addToExistingLead: "Додати до наявного ліда",
    addToExistingClient: "Додати до наявного клієнта",
    createNewLead: "Створити нового ліда",
    bindToClient: "Під'язати до клієнта або створити угоду",
    findExistingDeal: "Знайти наявну угоду, ліда. Або створити нову угоду, ліда.",
    addNewDeal: "Створити угоду",
    goToLead: "Переглянути ліда",
    goToClient: "Переглянути клієнта",
    confirm: "Підтвердити",
    addLeadEmailTitle: "Додати пошту ліду",
    addClientEmailTitle: "Додати пошту клієнту",
    selectLeadTitle: "Лід",
    selectClientTitle: "Клієнт",
    mailSent: "Повідомлення надіслано",
    viewMail: "Переглянути повідомлення",
    draftTitle: "Чернетка",
    whom: "Кому:",
};

export default mail;
