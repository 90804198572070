import { Email, Log } from "@/services/types";

export interface NotificationsState {
    pull: (Email | Log)[];
    isUnread: boolean;
}

const LogsModule = {
    namespaced: true,
    state: {
        pull: [],
        isUnread: false,
    },
    mutations: {
        add: (state: NotificationsState, payload: (Email | Log)[]): void => {
            state.pull = [...payload, ...state.pull].slice(0, 10).filter(Boolean);
            state.isUnread = true;
        },

        markAsViewed: (state: NotificationsState) => {
            state.isUnread = false;
        },
    },
};

export default LogsModule;
