export const removeNullOrUndefinedKeys = (obj: NonNullable<any>) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    }
    return obj;
};

export const removeDefaultKeys = (obj: NonNullable<any>) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === -1) {
            delete obj[key];
        }
    }
    return obj;
};

export const removeEmptyStringsKeys = (obj: NonNullable<any>) => {
    for (const key in obj) {
        if (obj[key] === "") {
            delete obj[key];
        }
    }
    return obj;
};

export const removeNotChangedObjectValuesKeys = (prev: NonNullable<any>, next: NonNullable<any>) => {
    const nullable = [null, undefined, ""];
    for (const key in next) {
        const isNullable = nullable.includes(next[key]) && nullable.includes(prev[key]);
        if (prev[key] === next[key] || isNullable) {
            delete next[key];
        }
    }
    return next;
};

export const deepObjectEqual = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepObjectEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};
