import { enUsLocaleName, ONE_MEGABYTE_IN_BYTES } from "@/utils/constants";
import { Locale } from "vue-i18n";

export const formatBytes = (bytes: number): string => {
    if (bytes < 1024) {
        return bytes + " B";
    } else if (bytes < ONE_MEGABYTE_IN_BYTES) {
        return (bytes / 1024).toFixed(2) + " KB";
    } else {
        return (bytes / ONE_MEGABYTE_IN_BYTES).toFixed(2) + " MB";
    }
};

export const boardItemsWordForm = (number: number, locale: Locale) => {
    if (locale === enUsLocaleName) {
        if (number === 1) {
            return "item";
        } else {
            return "items";
        }
    } else {
        if (number % 10 === 1 && number % 100 !== 11) {
            return "пункт";
        } else if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) {
            return "пункти";
        } else {
            return "пунктів";
        }
    }
};
