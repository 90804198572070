export enum SourceType {
    LEAD = "LEAD",
    CLIENT = "CLIENT",
    DELIVERY = "DELIVERY",
    DEAL = "DEAL",
    TASK = "TASK",
    NOTE = "NOTE",
    PRICE_LIST = "PRICE_LIST",
    USER = "USER",
    CALL = "CALL",
}
