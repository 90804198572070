const statistics = {
    title: "Statistics",
    subtitle: "Track the essentials on the Dashboard",
    leadCount: {
        title: "Lead count",
        subtitle: "Count of leads compared to deals and clients",
        newLeads: "New leads",
        leadsConversion: "Conversion",
        convertedLeads: "Converted leads",
        archivedLeads: "Archived",
    },
    dealCount: {
        title: "Deals",
        subtitle: "Track the essentials on the Dashboard",
        newDeals: "New deals",
        successfulDeals: "Successful deals",
        failedDeals: "Failed deals",
        dealsConversion: "Conversion",
        totalDealsAmount: "Total amount",
        averageDealsAmount: "Average deal amount",
    },
    table: {
        titles: {
            leads: "Leads",
            deals: "Deals",
        },
        tabs: {
            leads: "Work with leads",
            deals: "Work with deals",
        },
        leads: {
            fullName: "User",
            totalLeads: "Total leads",
            converted: "Converted",
            averageConversionTime: "Average conversion time",
            conversion: "Conversion",
        },
        deals: {
            fullName: "User",
            totalDeals: "Total deals",
            successDeals: "Won",
            failedDeals: "Lost",
            conversion: "Conversion",
            averageConversionTime: "Average conversion time",
            successDealsAmount: "Amount won",
            failedDealsAmount: "Amount lost",
            successDealsAverageAmount: "Average deal amount won",
            failedDealsAverageAmount: "Average deal amount lost",
        },
    },
};

export default statistics;
