<script setup lang="ts"></script>
<template>
    <div class="min-h-[4.5rem] flex flex-row justify-between items-center border-b">
        <div class="w-2/5 px-4">
            <slot name="row-name" />
        </div>
        <div class="w-2/5 pl-4">
            <slot name="row-value" />
        </div>
        <div class="flex items-center justify-end w-1/5 text-right px-4">
            <slot name="row-edit" />
        </div>
    </div>
</template>
