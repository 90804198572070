import api from "@/services/api";
import { Currency } from "@/services/types";
import { SourceType } from "./enums";

export const currenciesService = {
    async removeCurrency(currencyId: number, sourceType: SourceType) {
        return await api.delete(`/currencies/${currencyId}`, { params: { sourceType } });
    },
    async createCurrency(currency: Omit<Currency, "id" | "isPredefinedBySystem">, sourceType: SourceType) {
        return await api.post("/currencies", currency, { params: { sourceType } });
    },
    async updateCurrency(currencyId: number, currency: Partial<Omit<Currency, "id">>, sourceType: SourceType) {
        return await api.patch(`/currencies/${currencyId}`, currency, { params: { sourceType } });
    },
};
