import { Ref, ref } from "vue";

// eslint-disable-next-line
export const useDebounce = <T extends any[]>(delay = 300) => {
    const timeout: Ref<number | null> = ref(null);
    return (callback: (...args: T) => Promise<void>) => {
        return (...args: T): void => {
            if (timeout.value !== null) {
                clearTimeout(timeout.value);
            }
            timeout.value = setTimeout(() => callback(...args), delay) as unknown as number;
        };
    };
};
