import { SourceType } from "@/services/enums";

export type CreateCustomField = {
    name: string;
    sourceType: SourceType;
    type: CustomFieldType;
    value: string;
};

export enum CustomFieldType {
    INPUT = "INPUT",
    DROPDOWN = "DROPDOWN",
}
