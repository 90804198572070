import api from "@/services/api";
import { relatedStatus } from "@/services/types";

export const relatedStatusesService = {
    async relatedStatusesList(params: any) {
        return await api.get<relatedStatus[]>("/related-statuses", {
            params,
        });
    },
    async addRelatedStatuses(relatedStatuses: relatedStatus[]) {
        return api.post("/related-statuses", relatedStatuses);
    },
    async removeRelatedStatus(relatedStatusId: number) {
        return api.delete(`/related-statuses/${relatedStatusId}`);
    },
};
