import { ComputedRef, ref, Ref, watch } from "vue";
import { LocationQuery, Router, useRouter } from "vue-router";
import { useDebounce } from "@/utils/hooks/debouncer";

export const useSearch = (queryFilters: ComputedRef<LocationQuery>, prefix: string) => {
    const router: Router = useRouter();
    const prefixPage = `${prefix}_page`;
    const prefixSearch = `${prefix}_search`;
    const searchField: Ref<string> = ref((queryFilters.value[prefixSearch] as string) || "");
    const debouncedSearch = useDebounce(500);

    watch(
        () => queryFilters.value[prefixSearch],
        (newValue) => {
            searchField.value = (newValue as string) || "";
        }
    );

    const onInput = (value: string): void => {
        debouncedSearch(async (searchValue: string): Promise<void> => {
            searchField.value = searchValue;
            const { [prefixSearch]: _, [prefixPage]: __, ...query } = queryFilters.value;
            const routerQuery = searchValue.length > 0 ? { ...query, [prefixSearch]: searchValue } : query;
            await router.push({ query: routerQuery });
        })(value);
    };

    return {
        searchField,
        onInput,
    };
};
