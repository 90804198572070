import api from "@/services/api";
import { CrmSettings } from "@/services/types";

export const crmSettingsService = {
    async getSettings(): Promise<CrmSettings> {
        const response = await api.get("/crm-settings");
        return response.data;
    },

    async patch(payload: Partial<CrmSettings>): Promise<CrmSettings> {
        const response = await api.patch("/crm-settings", payload);
        return response.data;
    },
};
