import { LocalizationStructure } from "./types";

const localstorageKey = "teliqon-ui-kit:localization-structure";

export const setStructure = (structure: LocalizationStructure) => {
    window.localStorage.setItem(localstorageKey, JSON.stringify(structure));
    window.dispatchEvent(new CustomEvent("localstorage-changed"));
};

export const getStructure = () => {
    return JSON.parse(window.localStorage.getItem(localstorageKey) || "{}") as LocalizationStructure;
};
