import { ActionContext } from "vuex";

import { dealStagesService } from "@/services";
import { DealStage } from "@/services/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

const DEAL_STAGES_KEY = "dealStages";

export interface DealStagesState {
    stages: DealStage[];
}

const DealStagesModule = {
    namespaced: true,
    state: {
        stages: [] as DealStage[],
    },
    mutations: {
        setStages: (state: DealStagesState, payload: DealStage[]): void => {
            state.stages = payload;
        },
    },
    actions: {
        stagesList: async ({ commit }: ActionContext<unknown, unknown>, params?: any): Promise<void> => {
            try {
                const stages: DealStage[] = (await dealStagesService.stagesList(params)).data;
                commit("setStages", stages);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useDealStages = () =>
    useQuery({
        queryKey: [DEAL_STAGES_KEY],
        queryFn: () => dealStagesService.stagesList().then((res) => res.data),
    });

export const useRemoveDealStage = (invalidateKeys: string[] = []) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dealStageId: number) => dealStagesService.removeDealStage(dealStageId),
        onSuccess: () => {
            return Promise.all(
                [DEAL_STAGES_KEY, ...invalidateKeys].map((key) => {
                    return queryClient.invalidateQueries({ queryKey: [key], exact: false });
                })
            );
        },
    });
};

export const useCreateDealStage = (invalidateKeys: string[] = []) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dealStage: Omit<DealStage, "id">) => dealStagesService.createDealStage(dealStage),
        onSuccess: () => {
            return Promise.all(
                [DEAL_STAGES_KEY, ...invalidateKeys].map((key) => {
                    return queryClient.invalidateQueries({ queryKey: [key], exact: false });
                })
            );
        },
    });
};

export const useUpdateDealStage = (invalidateKeys: string[] = []) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (paylaod: { dealStageId: number; data: Partial<Omit<DealStage, "id" | "funnel">> }) =>
            dealStagesService.updateDealStage(paylaod.dealStageId, paylaod.data),
        onSuccess: () => {
            return Promise.all(
                [DEAL_STAGES_KEY, ...invalidateKeys].map((key) => {
                    return queryClient.invalidateQueries({ queryKey: [key], exact: false });
                })
            );
        },
    });
};

export default DealStagesModule;
