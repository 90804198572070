import { RoutesNames } from "@/router/consts";
import { Permissions } from "../constants";

export const routeNameToRequiredPermissionMap = {
    [RoutesNames.CLIENTS]: Permissions.CAN_VIEW_CLIENTS,
    [RoutesNames.CLIENT_INFO]: Permissions.CAN_VIEW_CLIENTS,

    [RoutesNames.LEADS]: Permissions.CAN_VIEW_LEADS,
    [RoutesNames.LEAD_INFO]: Permissions.CAN_VIEW_LEADS,

    [RoutesNames.DEALS]: Permissions.CAN_VIEW_DEALS,
    [RoutesNames.DEAL_INFO]: Permissions.CAN_VIEW_DEALS,

    [RoutesNames.TASKS]: Permissions.CAN_VIEW_TASKS,
    [RoutesNames.TASK_INFO]: Permissions.CAN_VIEW_TASKS,

    [RoutesNames.PRICE_LIST]: Permissions.CAN_VIEW_PRICE_LIST,
    [RoutesNames.PRICE_LIST_INFO]: Permissions.CAN_VIEW_PRICE_LIST,

    [RoutesNames.CALLS]: Permissions.CAN_VIEW_CALLS,

    [RoutesNames.STATISTIC]: Permissions.CAN_VIEW_STATISTICS,

    [RoutesNames.DELIVERY]: Permissions.CAN_VIEW_DELIVERIES,

    [RoutesNames.SETTINGS_USERS]: Permissions.CAN_VIEW_USERS_SETTINGS,

    [RoutesNames.SETTINGS_PRICE_LIST]: Permissions.CAN_VIEW_PRICE_LISTS_SETTINGS,

    [RoutesNames.SETTINGS_LEAD]: Permissions.CAN_VIEW_LEADS_SETTINGS,

    [RoutesNames.SETTINGS_CLIENT]: Permissions.CAN_VIEW_CLIENTS_SETTINGS,

    [RoutesNames.SETTINGS_DEAL]: Permissions.CAN_VIEW_DEALS_SETTINGS,

    [RoutesNames.SETTINGS_TASK]: Permissions.CAN_VIEW_TASKS_SETTINGS,

    [RoutesNames.SETTINGS_ROLES]: Permissions.CAN_VIEW_ROLES_SETTINGS,
};
