import { ActionContext } from "vuex";
import { deliveryStatusCodesService } from "@/services/delivery-status-codes.service";
import { relatedStatus } from "@/services/types/relatedStatuses";
import { relatedStatusesService } from "@/services/related-statuses.service";
import { deliveryService } from "@/services";

export interface relatedStatusesState {
    relatedStatuses: relatedStatus[];
}

const relatedStatuses = {
    namespaced: true,

    state: {
        relatedStatuses: [] as relatedStatus[],
    },
    mutations: {
        setRelatedStatuses(state: relatedStatusesState, payload: relatedStatus[]): void {
            state.relatedStatuses = payload;
        },
    },
    actions: {
        relatedStatusesList: async ({ commit }: ActionContext<unknown, unknown>, payload: { deliverySettingsId: number; funnelId: number }): Promise<void> => {
            try {
                const relatedStatuses: relatedStatus[] = (await relatedStatusesService.relatedStatusesList(payload)).data;
                commit("setRelatedStatuses", relatedStatuses);
            } catch (e) {
                console.error(e);
            }
        },

        removeRelatedStatus: async ({ dispatch }: ActionContext<unknown, unknown>, id: number) => {
            try {
                await relatedStatusesService.removeRelatedStatus(id);
            } catch (error) {
                console.error(error);
            }
        },
    },
};

export default relatedStatuses;
