import { onBeforeMount, ref } from "vue";
import { listViewMode } from "@/utils/constants";

const viewMode = ref(listViewMode);
const getViewMode = () => {
    const localStorageViewMode = localStorage && localStorage.getItem("viewMode");
    if (localStorageViewMode) viewMode.value = localStorageViewMode;
    return viewMode.value;
};
const setViewMode = (value: string) => {
    localStorage.setItem("viewMode", value);
    viewMode.value = value;
};

export const useViewMode = () => {
    onBeforeMount(() => getViewMode());

    return { viewMode, setViewMode, getViewMode };
};
