import { UserCallStatus } from "@/services/enums/user-call-status.enum";

const auth = {
    navBar: {
        login: "Login",
        signUp: "Sign up",
    },
    modals: {
        login: {
            header: "Login",
            signIn: "Sign in",
            signInWithGoogle: "Sign in with Google",
            register: {
                noAccount: "No account?",
                register: "Register",
            },
            error: "Login error. Check password and login",
            resetPassword: "Forgot password?",
        },
        register: {
            header: "Registration",
            signUp: "Sign up",
            signUpWithGoogle: "Sign up with Google",
            login: {
                alreadyRegistered: "Already registered?",
                login: "Log in",
            },
            error: "Registration error... Use support",
        },
        confirm: {
            header: "Verification",
            authenticatorHeader: "Google authenticator verification",
            subtitle: "Please enter 4 digit code sent to email {email}",
            didntReceiveCode: "Didn't receive code?",
            resendCode: "Send again",
            changeEmail: "Change email",
            continue: "Continue",
            error: "Verification error, check code",
        },
        privacyPolicy: {
            header: "By clicking “Register” you accept the rules",
            privacyPolicy: "Privacy Policy",
        },
        generate2FA: {
            header: "End of registration",
            subtitle:
                "To complete authorization, you need to connect two-factor authentication. " +
                "Enter the application, for example, Google Authenticator, and scan the qr-code below in it:",
            error: "Two-factor authentication activation error. Contact support",
            continueText: "After scanning the code, press:",
            continue: "Continue",
        },
        authenticate2FA: {
            header: "End of registration",
            subtitle: "Enter the code in your two-factor authentication application, for example, Google Authenticator",
            error: "Two-factor authentication activation error. Contact support",
            code: "Six-digit code from application",
            continue: "Continue",
            back: "Back",
        },
        setPassword: {
            header: "Set password",
            text: "It looks like your account doesn't have a password, would you like to add one?",
        },
        resetPassword: {
            header: "Password recovery",
            subtitle: "Please enter your email, a 4-digit code will be sent to it",
            submit: "Continue",
            back: "Cancel",
        },
        resetPasswordCode: {
            header: "Password recovery",
        },
        resetPasswordPassword: {
            header: "Password recovery",
            back: "Back",
            submit: "Change",
            success: "Password changed! Redirecting you to the login page...",
        },
    },
    inputs: {
        name: "Name and surname",
        nameError: "Enter correct name and surname",
        email: "Your email",
        emailError: "Email format must be {format}",
        fullName: "Full Name cannot be empty or contain only spaces and less than 3 symbols",
        password: "Your password",
        passwordError: "At least 8 symbols",
        phone: "Your phone number",
        phoneError: "Plus sign with country code, for example +380951111111",
        repeatPassword: "Repeat password",
        repeatPasswordError: "Passwords do not match!",
        confirmationCodeError: "Code should contain 4 digits",
        newPassword: "Think up password",
        newPasswordRepeat: "Repeat password",
        repeatPasswordRequirement: "Passwords must match",
        newPasswordRequirements: "Minimum 8 characters, 1 uppercase, 1 lowercase",
    },
    callStatus: {
        [UserCallStatus.ACTIVE]: "Active",
        [UserCallStatus.DND]: "Don't disturb",
        [UserCallStatus.BUSY]: "Busy",
        collapse: "Collapse",
    },
    invitation: {
        support: "support",
        invalidInvitation: "Something was wrong with the invitation, please contact",
        finishSignUpByInvitation: "Finish sign up",
    },
};

export default auth;
