import { ActionContext } from "vuex";

import { notesService } from "@/services";
import { CreateNote, Note, NoteFilters, UpdateNote, UploadProgressHandlers } from "@/services/types";
import { SourceType } from "@/services/enums";

export interface NoteState {
    notes: Note[];
}

const NotesModule = {
    namespaced: true,
    state: {
        notes: [] as Note[],
    } as NoteState,
    mutations: {
        setNotes: (state: NoteState, payload: Note[]): void => {
            state.notes = payload;
        },

        removeNote: (state: NoteState, id: number) => {
            state.notes = state.notes.filter((n) => n.id !== id);
        },
    },
    actions: {
        noteList: async ({ commit }: ActionContext<unknown, unknown>, filters: NoteFilters): Promise<void> => {
            try {
                const notes: Note[] = await notesService.noteList(filters);
                commit("setNotes", notes);
            } catch (e) {
                console.error(e);
            }
        },

        createNote: async (
            _commit: unknown,
            {
                sourceType,
                payload,
            }: {
                sourceType: SourceType;
                payload: {
                    note: CreateNote;
                    uploadHandlers: UploadProgressHandlers;
                };
            }
        ): Promise<void> => {
            const {
                note,
                uploadHandlers: { onUploadProgress, onSuccessUpload, onErrorUpload },
            } = payload;

            try {
                await notesService.createNote(sourceType, note, onUploadProgress);
                onSuccessUpload();
            } catch (e) {
                onErrorUpload("");
                console.error(e);
            }
        },

        updateNote: async (
            _commit: unknown,
            { id, sourceType, payload }: { id: number; sourceType: SourceType; payload: { note: UpdateNote; uploadHandlers: UploadProgressHandlers } }
        ): Promise<void> => {
            const {
                note,
                uploadHandlers: { onUploadProgress, onSuccessUpload, onErrorUpload },
            } = payload;

            try {
                await notesService.updateNote(id, sourceType, note, onUploadProgress);
                onSuccessUpload();
            } catch (e) {
                onErrorUpload("");
                console.error(e);
            }
        },
        removeNote: async ({ commit }: ActionContext<unknown, unknown>, payload: { id: number; sourceType: SourceType }): Promise<void> => {
            try {
                await notesService.removeNote(payload);
                commit("removeNote", payload.id);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export default NotesModule;
