export const autoDownloadMarking = (response: any) => {
    if (!response || !response.data) {
        console.error("Invalid response data");
        return;
    }

    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    const fileName = `marking_${Date.now()}.pdf`;
    link.href = url;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
