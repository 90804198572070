<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" class="mb-0.5" height="22" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_1640_3347" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <path
                :d="'M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 \
                     12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z'"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_1640_3347)">
            <path d="M0 12L12.0938 10.1531L24 12V24H0V12Z" fill="#FFDA44" />
            <path d="M0 0H24V12H0V0Z" fill="#338AF3" />
        </g>
    </svg>
</template>
