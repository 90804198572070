import api from "@/services/api";
import { CreateLead, CustomField, Lead, LeadFilters, LeadList, LeadSorting, Locale, Pagination, SomeFieldsRequest, UpdateLead } from "@/services/types";
import { DownloadFileTypeOptions } from "@/utils/import-export";

export const leadsService = {
    async leadList(params: LeadFilters | LeadSorting | Locale): Promise<LeadList> {
        const response = await api.get("/leads", {
            params,
        });
        return response.data;
    },

    async someFields(params: SomeFieldsRequest): Promise<Partial<Lead>[]> {
        const response = await api.get("/leads/some-fields", {
            params: { fields: params.fields?.join(",") },
        });
        return response.data;
    },

    async getLead(id: string, locale: Locale) {
        const response = await api.get(`/leads/${id}`, { params: { locale } });
        return response.data;
    },

    createLead(lead: CreateLead) {
        return api.post("leads", lead);
    },

    updateLead(id: string, lead: UpdateLead, locale: Locale) {
        return api.patch(`/leads/${id}`, lead, { params: { locale } });
    },

    bulkUpdate(data: UpdateLead, locale: Locale): Promise<void> {
        return api.patch(`/leads/bulk`, data, { params: { locale } });
    },

    addContact(id: number, email: string) {
        return api.post(`/leads/${id}/contacts`, { email });
    },

    async getLeadAdditionalContactOptions(leadId: number) {
        const response = await api.get(`/leads/${leadId}/additional-contacts`);
        return response.data;
    },

    async leadFieldsList(locale: "en" | "ua") {
        return await api.get<{
            essenceFields: string[];
            customFields: CustomField[];
        }>("/leads/fields", { params: { locale } });
    },

    async additionalContactFieldsList(locale: "en" | "ua") {
        return await api.get<{ essenceFields: string[] }>("/additional-contacts/fields", { params: { locale } });
    },

    async exportLeads(
        params: { locale: "en" | "ua" } & { essenceFieldIds: string[]; customFieldIds: number[]; additionalContactsFieldIds: string[] } & Omit<
                LeadFilters,
                keyof Pagination
            > &
            LeadSorting
    ) {
        const { essenceFieldIds, customFieldIds, additionalContactsFieldIds } = params;
        return await api.get<{ fileUrl: string }>("/leads/export", {
            params: {
                ...params,
                essenceFieldIds: essenceFieldIds.join(","),
                customFieldIds: customFieldIds.join(","),
                additionalContactsFieldIds: additionalContactsFieldIds.join(","),
            },
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importLeads(data: { locale: "en" | "ua" } & { file: File; uniqueFieldId: string }) {
        const form = new FormData();
        form.append("file", data.file);
        form.append("locale", data.locale);
        form.append("uniqueFieldId", data.uniqueFieldId);

        return await api.postForm<{ errors: ({ [key: string]: any } & { error: any })[]; successCount: number }>("/leads/import", form, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importLeadsTemplate(fileType: DownloadFileTypeOptions) {
        return await api.get<{ fileUrl: string }>(`/leads/import/template?fileType=${fileType}`, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
};
