export const RoutesNames = {
    MAIN: "Main",
    LEADS: "Leads",
    LEAD_INFO: "LeadInfo",
    CLIENTS: "Clients",
    CLIENT_INFO: "ClientInfo",
    DEALS: "Deals",
    DEAL_INFO: "DealInfo",
    TASKS: "Tasks",
    TASK_INFO: "TaskInfo",
    CALLS: "Calls",
    PRICE_LIST: "PriceList",
    PRICE_LIST_INFO: "PriceListInfo",
    STATISTIC: "Statistic",
    DELIVERY: "Delivery",
    NO_EMAIL_ACCOUNTS: "NoEmailAccounts",
    MAIL_BOX: "MailBox",
    MAIL_MESSAGE: "MailMessage",
    SETTINGS: "Settings",
    USER_INFO: "UserInfo",
    SETTINGS_USERS: "Settings > Users",
    SETTINGS_PRICE_LIST: "Settings > Price List",
    SETTINGS_LEAD: "Settings > Lead",
    SETTINGS_CLIENT: "Settings > Client",
    SETTINGS_DEAL: "Settings > Deal",
    SETTINGS_TASK: "Settings > Task",
    SETTINGS_ROLES: "Settings > Roles",
    SETTINGS_MAIL: "Settings > Mail",
    SETTINGS_INTEGRATIONS: "Settings > Integrations",
    SETTINGS_NOTIFICATIONS: "Settings > Notifications",
    SETTINGS_PERSONAL: "Settings > Personal",
    POST_INFO: "PostInfo",
    EDIT_PRIVATE_INDIVIDUAL: "EditPrivateIndividual",
    NOTIFICATIONS: "Notifications",
    PROFILE: "Profile",
    CREATE_NEW_DELIVERY: "CreateNewDelivery",
    SIGN_UP_BY_INVITATION: "SignUpByInvitation",
    AUTH: "Auth",
};

export const RouteIds = {
    LEAD: "leadId",
    CLIENT: "clientId",
    DEAL: "dealId",
    TASK: "taskId",
    PRICE_LIST: "priceListId",
};

export const PublicPages = ["/auth", "/invitation"];
