import { onBeforeMount, ref } from "vue";

const userSelectedTheme = () => localStorage && (localStorage.getItem("theme") as Theme | null);
const userPreferresDark = () => window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
type Theme = "dark" | "light";

export const useTheme = () => {
    const setTheme = (selectedTheme: Theme) => {
        const root = document.querySelector(":root");

        localStorage && localStorage.setItem("theme", selectedTheme);

        if (selectedTheme == "dark") root?.classList.add("dark");
        else root?.classList.remove("dark");
    };

    const theme = ref<Theme>(userSelectedTheme() || (userPreferresDark() ? "dark" : "light"));
    onBeforeMount(() => {
        setTheme(theme.value);

        const observer = new MutationObserver(
            () => (theme.value = document.querySelector(":root")?.classList.contains("dark") ? "dark" : "light")
        );
        // @ts-expect-error for some reason it asks for Node, but Element is alright
        observer.observe(document.querySelector(":root"), {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"],
        });

        return () => observer.disconnect();
    });

    return { theme, setTheme };
};
