import api from "@/services/api";
import { Status } from "@/services/types";
import { SourceType } from "@/services/enums";

export const statusesService = {
    async statusList(sourceType: SourceType) {
        return await api.get<Status[]>("/statuses", {
            params: { sourceType },
        });
    },
    async removeStatus(statusId: number, sourceType: SourceType) {
        return await api.delete(`/statuses/${statusId}`, { params: { sourceType } });
    },
    async createStatus(payload: Omit<Status, "id">, sourceType: SourceType) {
        return await api.post("/statuses", payload, { params: { sourceType } });
    },
    async updateStatus(statusId: number, status: Partial<Omit<Status, "id">>, sourceType: SourceType) {
        return await api.patch(`/statuses/${statusId}`, status, { params: { sourceType } });
    },
};
