import { ActionContext } from "vuex";
import { Ref } from "vue";

import { logService } from "@/services";
import { Log } from "@/services/types";
import { useQuery } from "@tanstack/vue-query";

type Context = ActionContext<unknown, unknown>;

export interface LogsState {
    logs: Log[];
    total: number;
}

const LogsModule = {
    namespaced: true,
    state: {
        logs: [] as Log[],
        total: 0,
    },
    mutations: {
        setLogs: (state: LogsState, payload: any): void => {
            state.logs = payload;
        },
        setTotal: (state: LogsState, payload: any): void => {
            state.total = payload;
        },
    },
    actions: {
        logList: async ({ commit }: Context, { sourceType, id }: { sourceType: string; id: number }): Promise<void> => {
            try {
                const { items, total } = await logService.logList(sourceType, id);
                commit("setLogs", items);
                commit("setTotal", total);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useLogsList = (page: Ref<number>) =>
    useQuery({
        queryKey: ["logs-list", page],
        queryFn: () => logService.notificationHistory(page.value),
        initialData: { items: [], total: 0 },
    });

export default LogsModule;
