import { ref } from "vue";

export const useTimer = () => {
    let callStartTime: number;
    let callTimer: number;

    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);
    const formattedTime = ref("");

    const startCallTimer = () => {
        callStartTime = Date.now();
        callTimer = setInterval(() => {
            const now = Date.now();
            const diff = now - callStartTime;

            const diffSeconds = Math.floor(diff / 1000);
            hours.value = Math.floor(diffSeconds / 3600);
            minutes.value = Math.floor((diffSeconds % 3600) / 60);
            seconds.value = diffSeconds % 60;

            formattedTime.value =
                hours.value > 0
                    ? `${hours.value}:${minutes.value < 10 ? "0" : ""}${minutes.value}:${seconds.value < 10 ? "0" : ""}${seconds.value}`
                    : `${minutes.value}:${seconds.value < 10 ? "0" : ""}${seconds.value}`;
        }, 1000);
    };

    const stopCallTimer = () => clearInterval(callTimer);

    return {
        formattedTime,
        startCallTimer,
        stopCallTimer,
    };
};
