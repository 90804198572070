const settings = {
    main: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        customFields: {
            title: "Налаштування полів сутностей",
            subtitle: "Ви можете додати додаткові поля для необхідних сутностей",
            priceList: "Прайсліст",
            lead: "Лід",
            client: "Клієнт",
            deal: "Угода",
            task: "Задача",
            users: "Користувачі",
            roles: "Ролі",
            notifications: "Сповіщення",
            integrations: "Інтеграції",
            mail: "Пошта",
            personal: "Персональні",
        },
    },
    users: {
        userInfo: {
            title: "Особиста картка користувача",
        },
        positions: {
            modal: {
                titleCreate: "Створити посаду",
                titleUpdate: "Редагувати посаду",
                saveBtn: "Зберегти",
                inputLabel: "Посада",
                inputPlaceholder: "Введіть назву посади",
                errors: {
                    required: `Поле "посада" є обов'язковим`,
                },
                deleteConfirmationTitle: `Після видалення для {usersCount} користувача -ів буде встановлено посаду "Manager"`,
                deleteConfirmationSubtitle: `Ви підтверджуєте видалення?`,
            },
        },
        positionsTable: {
            title: "Посади",
            panel: {
                createPosition: "Додати нову посаду",
            },
            columns: {
                name: "Назва",
                actions: "Дії",
            },
        },
        table: {
            title: "Користувачі",
            panel: {
                createUser: "Додати користувача",
            },
            columns: {
                fullName: "ПІБ",
                login: "Пошта",
                role: "Роль",
                position: "Посада",
                status: "Статус",
                actions: "Дії",
            },
        },
        newUserModal: {
            header: "Створити користувача",
            inputs: {
                fullName: "ПІБ *",
                login: "Пошта *",
                photo: "Фото",
                password: "Пароль *",
                passwordError: "Від 3 до 15 символів",
                confirmPassword: "Повторіть пароль *",
                role: "Роль *",
                position: "Посада *",
            },
            placeholders: {
                fullName: "Введіть ПІБ користувача",
                login: "Введіть пошту",
                photo: "Завантажити файл",
                password: "Придумайте пароль",
                confirmPassword: "Повторіть новий пароль",
            },
        },
        deleteUserModal: {
            title: "Ви підтверджуєте видалення користувача {userName}?",
            changeResponsibleSubtitle: "Ви можете змінити відповідального через масовані дії перейшовши у відповідні частини додатку",
            changeResponsible: "Змінити відповідального",
        },
        filter: {
            header: "Фільтр",
            inputs: {
                name: "ПІБ",
                email: "Пошта",
                role: "Роль",
                position: "Посада",
            },
            placeholders: {
                name: "Введіть ПІБ користувача",
                email: "Введіть пошту",
            },
        },
    },
    modals: {
        error: "Сталася помилка при обробці вашого запиту",
    },

    roles: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        add: "Створити роль",
        table: {
            title: "Ролі",
            columns: {
                name: "Роль",
                permissions: "Дозволи",
                actions: "",
            },
        },
        modal: {
            create: {
                header: "Створити роль",
                inputs: { name: "Назва" },
                submit: "Створити",
            },
            update: {
                header: "Редагувати роль",
                inputs: { name: "Назва ролі (незмінювана)" },
                submit: "Зберегти",
            },
            delete: {
                deleteDeliveryConfirmationSubtitle: "Ви підтверджуєте видалення доставки?",
                deletedDeliveryMessage: "Доставка була видалена",
                deleteConfirmationTitle: `Після видалення для {usersCount} користувача -ів буде встановлено роль "User"`,
                deleteConfirmationSubtitle: `Ви підтверджуєте видалення?`,
            },

            header: "",
        },
        permissions: {
            selectAll: "Вибрати всі",
            cancelAll: "Скасувати вибір",
            leads: "Ліди",
            clients: "Клієнти",
            deals: "Угоди",
            tasks: "Завдання",
            "price-list": "Прайсліст",
            calls: "Дзвінки",
            settings: "Налаштування",
            roles: "Ролі",
            users: "Користувачі",
            statistics: "Статистика",
            deliveries: "Доставка",
            "users-settings": "Налаштування користувачів",
            "roles-settings": "Налаштування ролей",
            "leads-settings": "Налаштування лідів",
            "clients-settings": "Налаштування клієнтів",
            "deals-settings": "Налаштування угод",
            "tasks-settings": "Налаштування завдань",
            "price-lists-settings": "Налаштування прайслиста",
            errors: {
                canViewMustBeSelected: 'Доступ "Може переглядати..." має бути обрано',
            },
        },
        levels: {
            view: "Може переглядати",
            create: "Може створювати",
            update: "Може редагувати",
            delete: "Може видаляти",
            "request-archive": "Може робити запит архіву",
            "archive-and-dearchive": "Може архівувати і деархівувати",
            import: "Може імпортувати",
            export: "Може експортувати",
        },
    },

    common: {
        multipleValues: {
            close: "Закрити",
            confirmValueRemoving: "Дійсно видалити?",
            valuePlaceholder: "Введіть текст",
            valueName: "Введіть варіанти",
            header: "Змінити поле",
        },
        add: "Додати",
        status: "Статус",
        customerType: "Тип",
        source: "Джерело",
        currency: "Валюта (прайслисти та угоди)",
        dealStage: "Етап",
    },

    customFields: {
        modal: {
            create: {
                header: "Створити нове поле",
                inputs: {
                    name: "Назва поля",
                    type: "Тип поля",
                    value: "Введіть варіанти",
                    valuePlaceholder: "Введіть нове значення та нажміть Enter",
                },
                submit: "Створити",
            },
            update: {
                header: "Змінити поле",
                inputs: {
                    name: "Назва поля",
                    type: "Тип поля",
                    value: "Введіть варіанти",
                    valuePlaceholder: "Введіть нове значення та нажміть Enter",
                },
                submit: "Зберегти",
            },
            view: {
                header: "Змінити поле",
                inputs: {
                    name: "Назва поля",
                    type: "Тип поля",
                    value: "Введіть варіанти",
                    valuePlaceholder: "Введіть нове значення та нажміть Enter",
                },
                submit: "Зберегти",
            },
        },
        type: {
            INPUT: "Інпут",
            DROPDOWN: "Дропдаун",
        },
        table: {
            columns: {
                name: "Назва",
                type: "Тип",
                actions: "",
            },
        },
    },
    funnels: {
        title: "Воронки",
        addStage: "Додати етап",
        addNew: "Додати нову",
        saveBeforeAddingNew: "Збережіть додану воронку перед тим як додати ще одну",
    },
    taskStatus: {
        title: "Задача",
        subtitle: "Статус",
        add: "Додати статус",
    },
    LEAD: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        add: "Нове поле",
        table: {
            title: "Лід",
        },
    },
    CLIENT: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        add: "Нове поле",
        table: {
            title: "Клієнт",
        },
    },
    DEAL: {
        title: "Налаштування",
        subtitle: "Угоди",
        add: "Нове поле",
        table: {
            title: "Угода",
        },
        inputs: {
            errors: {
                maxLengthError: "Максимальна довжина 50 символів",
                minLengthError: "Не може бути порожнім",
                onlySpaces: "Не може складатися тільки з пробілів",
            },
        },
    },
    TASK: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        add: "Нове поле",
        table: {
            title: "Задача",
        },
    },
    PRICE_LIST: {
        title: "Налаштування",
        subtitle: "Відслідковуй найголовніше на Дешборді",
        add: "Нове поле",
        table: {
            title: "Прайсліст",
        },
        barcodes: {
            instructionDescr:
                "Налаштування генерації PDF зі штрихкодами. Самі штрихкоди додаються на внутрішню сторінку товару у відповідному блоці. Також, їх можна імпортувати.",
            instruction: "Інструкція",
            commonSettings: "Загальні налаштування",
            addArticleToPdfFile: "Додати артикул в PDF до штрихкоду",
            addNameToPdfFile: "Додати назву товару в PDF до штрихкоду",
            copyArticleToBarcode: "Скопіювати артикул в шрихкод",
            barcodeFormat: "Символіка при додаванні шрихкоду",
        },
    },
    mail: {
        title: "Налаштування",
        subtitle: "Пошта",
        accountData: {
            title: "Дані облікового запису",
            senderNameTitle: "Ім'я відправника",
            senderNamePlaceholder: "Введіть ім'я налаштованого відправника",
            emailTitle: "Електронна пошта",
            connected: "підключено",
        },
        mailSync: {
            title: "Синхронізація даних електронних листів",
            syncFromTitle: "Синхронізувати починаючи з",
            syncButton: "Синхронізувати",
            month: "1 місяць",
            threeMonths: "3 місяці",
            sixMonths: "6 місяців",
        },
        accountManage: {
            title: "Упаравління обліковим записом",
            stopSyncButton: "Зупинити синхронізацію",
            removeAccountButton: "Видалити обліковий запис",
        },
    },
    integrations: {
        pageSubTitle: "Інтеграції",
        emails: {
            connected: "Приєднано",
            reconnect: "Підключити повторно",
            addEmailProvider: "Додати пошту",
            other: "Інше",
            selectEmailProvider: "Виберіть вашу пошту",
            description: `Інтеграція Email дозволяє працювати
                з електронними листами одразу в CRM. Всі електронні листи які ви надсилаєте або отримуєте
                автоматично прикріплюються до клієнтів, тому вся комунікація легко структурується та зберігається`,
            reconnectDescription: `Облікові дані вашого аккаунту застаріли. Будь ласка, натисніть 'Підключити повторно',
             щоб отримати нові облікові дані і продовжити синхронізацію електронної пошти.`,
        },
        post: {
            settings: {
                title: "Налаштування",
                subtitle: "Інтеграції",
                relatedStatuses: {
                    deliveryStatus: {
                        title: "Зовнішній статус",
                    },
                    dealsStage: {
                        title: "Етап",
                    },
                    warning: {
                        sameStatusId: "Будь ласка оберіть різні статуси для створення пов'язаних статусів",
                        selectStatusId: "Будь ласка, оберіть статуси, щоб створити пов'язані статуси",
                    },
                },

                infoTtnDescription:
                    "Формуйте TTH по угоді швидко і зручно. Автоматична підстановка даних отримувача, відправника, інформації про товар.\n" +
                    "Автоматичний перехід угод по етапам в залежності від статусу доставки, список для кур’єра, мультиакаунтність.",
                ttnInstructionTitle: "Першочергові налаштування",
                prioritySettingsTitle: "Першочергові налаштування",
                toggleOnIntegration: "Увімкнути інтеграцію",
                toggleOffIntegration: "Вимкнути інтеграцію",
                relatedStatusesCardTitle: "Пов'язані статуси",
                typeOfReceivingMoneyTitle: "Тип отримання грошей",
                privateIndividual: "Приватна особа",
                noReturnShipping: "Без зворотньої доставки",
                noAutoGenerationAdditionalInfo: "Без автогенерації додаткової інформації",
                placeProductNameToAdditionalInfo: "Розмістити назву товару в додаткову інформацію",
                popupPlaceProductNameToAdditionalInfo: 'Система автоматично додасть назви товарів в поле "Додаткова інформація" при генерації ТТН.',
                placeArticleAndQuantityToAdditionalInfo: "Розмістити артикул та кількість в додаткову інформацію",
                popupPlaceArticleAndQuantityToAdditionalInfo:
                    'Система автоматично додасть артикул товару, кількість та одиниці виміру поле "Додаткова інформація" при генерації ТТН.' + " Приклад: sku12-3шт.",
                inTheDepartment: "У відділенні",
                relatedStatusesAndPaymentCardTitle: "Пов’язані типи оплат та статуси",
                relatedStatusesCardSubTitle:
                    "Зв’яжіть статуси служби доставки і етапи угод KeepinCRM, щоб замовлення автоматично змінювали свій етап в залежності від статусу доставки",
                editPrivateIndividual: {
                    title: "Налаштування",
                    subtitle: "Інтеграції / Приватна особа",
                    active: "Увімкнено",
                    inActive: "Вимкнено",
                    deleteAccount: "Видалити акаунт",
                    name: "Назва",
                    apiKey: "Api ключ",
                    apiToken: "Bearer токен",
                    senderType: "Тип відправника",
                    senderFirstName: "Ім’я відправника",
                    senderMiddleName: "По-батькові відправника",
                    senderLastName: "Прізвище відправника",
                    companyName: "Назва компанії",
                    uniqueRegistrationNumber: "ЄДРПОУ",
                    phone: "Телефон",
                    typeOfCargo: "Тип вантажу",
                    deliveryMethod: "Метод доставки",
                    clientTypes: {
                        INDIVIDUAL: "Приватна особа",
                        CORPORATE: "Компанія",
                        THIRD_PERSON: "Третя особа",
                    },
                    cargoTypes: {
                        PARCEL: "Посилка",
                        DOCUMENTS: "Документи",
                    },
                    deliveryTypes: {
                        W2W: "Відділення-відділення",
                        W2D: "Відділення-двері",
                        D2W: "Двері-відділення",
                        D2D: "Двері-двері",
                    },
                    paymentTypes: {
                        CASH: "Готівка",
                        NON_CASH: "Безготівковий",
                    },
                    payerOfTheDeliveryCommission: {
                        title: "Платник комісії за доставку",
                        sender: "Відправник",
                        recipient: "Отримувач",
                    },
                    deliveryPayer: {
                        title: "Платник доставки",
                        sender: "Відправник",
                        recipient: "Отримувач",
                    },
                    city: "Місто",
                    cargoDescription: "Опис вантажу",
                    formOfPayment: "Форма оплати",
                    departmentType: "Тип відділення",
                    sendingDepartment: "Відділення відправника",
                    totalWeight: "Загальна вага(кг)",
                    totalShipmentVolume: "Загальний об’єм відправлення (м3)",
                    packageNumber: "Номер пакування",
                    additionalInfo: {
                        title: "Додаткова інформація",
                        empty: "Не заповнювати внутрішній номер відправлення",
                        fill: "Заповнити внутрішній номер відправлення",
                        popupFill:
                            "При створенні ТТН автоматично увімкнено заповнення кожного місця (Довжина х Ширина х Висота), вага (кг), " +
                            'та можливість вказати "Відправлення не в коробці". \n' +
                            "\n" +
                            'Дана функція також дозволяє автоматично підставляти ці дані з товарів (якщо вони заповнені на вкладці "К-сть місць"), ' +
                            "або з калькулятора об'ємної ваги в угоді. \n" +
                            "\n" +
                            "Якщо товар займає декілька місць, " +
                            "то це зручна функція для одноразового заповнення даних по кожному товару і автозаповнення при створенні ТТН.",
                        dealId: "Розмістити ID угоди на внурішній номер відправлення",
                        dealName: "Розмістити назву угоди на внутрішній номер відправлення",
                        priceList: "Розмістити інформацію з поля товару на внутрішній номер відправлення",
                    },
                    accompanyingDocuments: {
                        title: "Супроводжуючі документи",
                        fromDealPriceListAmount: "Кількість місць на основі кількості товарів в угоді",
                        fixed: "Фіксована кількість місць",
                        fromPriceListAmount: "Кількість місць з користувацького поля в товарі",
                        popupFromPriceListAmount:
                            "Це налаштування потрібне якщо товар великогабаритний та займає більше 1 місця при відправці." +
                            " Щоб пришвидшити створення ТТН, вкажіть поле звідки система буде брати інформацію про кількість місць." +
                            " Якщо в угоді 2 товари: в 1 товарі поле заповнено (вказано 2 місця), " +
                            "в іншому не заповнено, то система автоматично заповнить кількість місць = 3.",
                    },
                    deliverySize: {
                        title: "Розмір відправлення",
                        subtitle:
                            "Якщо при створенні ТТН заповнюється тільки загальні параметри вага та кількість місць то виберіть “Загальні параметри”," +
                            " якщо потрібно заповнювати параметри (Дожина х Ширина х Висота) кожного місця виберіть “Заповнити кожне місце”" +
                            " при цьому у вікні створення ТТН (це можна змінювати). Ця функція дозволяє автоматично заповнювати найбільш викорстований метод.",
                        general: "Загальні параметри",
                        popupGeneral: "При створенні ТТН автоматично увімкнено заповнення загальної ваги (кг), загального об'єму (м3) та кількості місць.",
                        fillEach: "Заповнити кожне місце",
                        enableSelect: "Увімкнути параметри відправлення для вибору",
                        fillPaymentCheck: "Заповнити контроль оплати, якщо без зворотньої доставки",
                        popupFillPaymentCheck:
                            "Послуга «Контроль оплати» забезпечує переказ на розрахунковий рахунок продавця коштів," +
                            " якими отримувач товару розраховується за відправлення з післяплатою.",
                    },
                },
            },
            ["nova-post"]: {
                connected: "Приєднано",
                addPoshtaProvider: "Додати аккаунт Нової Пошти",
                addProviderTitle: "Нова Пошта",
                inputApiKeyLabel: "Введіть API ключ",
                inputApiKeyPlaceholder: "Api ключ",
                add: "Додати",
                remove: "Скасувати",
                other: "Інше",
                descriptor: `Дорогі наші клієнти! 
                З нагоди нашого першого року разом, ми раді поділитися з вами чудовою новиною - у нашому магазині з'явилися найсвіжіші тренди сезону!
                Нові поступлення відомих брендів гарантовано додадуть вашому гардеробу неповторний шарм та стиль.`,
                invalidApiKeyErr: "API ключ невірний",
            },
            ["ukr-post"]: {
                connected: "Приєднано",
                addPoshtaProvider: "Додати аккаунт УкрПошти",
                addProviderTitle: "УкрПошта",
                add: "Додати",
                other: "Інше",
                inputApiKeyLabel: "Введіть API ключ",
                inputApiKeyPlaceholder: "Api ключ",
                inputTokenLabel: "Введіть Bearer токен",
                inputTokenPlaceholder: "Bearer токен",
                remove: "Скасувати",
                descriptor: `Дорогі наші клієнти! 
                З нагоди нашого першого року разом, ми раді поділитися з вами чудовою новиною - у нашому магазині з'явилися найсвіжіші тренди сезону!
                Нові поступлення відомих брендів гарантовано додадуть вашому гардеробу неповторний шарм та стиль.`,
            },
        },
    },
    personal: {
        title: "Двофакторна аутентифікація",
        subTitle: "Один з методів двофакторної аутентифікації є обовʼязковим",
        pageSubtitle: "Персональні",
        addButton: "Додати",
        modal: {
            header: "Під’єднати застосунок Authenticator",
            description: "Під’єднайте застосунок Authenticator, який створює коди перевірки. Ви можете використати ці коди для підтвердження особистих данних.",
            step1: "1. Завантажте і встановіть застосунок на мобільний пристрій.",
            step2: "2. Використовуйте застосунок для QR-коду.",
            step3: "3. Введіть код, створений застосунком.",
            inputs: {
                code: "Код перевірки",
                appName: "Ім’я верифікатора",
            },
            placeholders: {
                code: "Введіть код перевірки",
                appName: "Введіть ім’я верифікатора",
            },
            cancel: "Скасувати",
            submit: "Підключення",
        },
        errors: {
            lastIssuer: "Ви не можете видалити останній верифікатор",
            enableEmailVerification: "Будь ласка, активуйте спочатку підтвердження електронної пошти",
            enableAuthenticator: "Будь ласка, активуйте спочатку Google Authenticator",
        },
    },
    notifications: {
        pageSubTitle: "Нотифікації",
        channels: {
            title: "Загальні",
            notifyByEmail: "Отримувати сповіщення на Email",
            notifyBySocket: "Дозволити Push-сповіщення",
            notifyWithSound: "Звукові сповіщення",
        },
        leads: {
            title: "Ліди",
            description: "Отримувати нотифікації про ліда",
            create: "Створення ліда",
            update: "Редагування ліда",
            convertInClient: "Конвертація в клієнта",
            addTask: "Додавання завдання",
            archive: "Архівування ліда",
            deArchive: "Розархівування ліда",
        },
        clients: {
            title: "Клієнти",
            description: "Отримувати нотифікації про клієнта",
            create: "Створення клієнта",
            update: "Редагування ",
            addTask: "Додавання завдання",
            addDeal: "Додавання угоди",
            archive: "Архівування клієнта",
            deArchive: "Розархівування клієнта",
        },
        deals: {
            title: "Угоди",
            description: "Отримувати нотифікації про угоди",
            create: "Створення угоди",
            update: "Редагування угоди",
            addPriceList: "Додавання / редагування товарів / послуг",
            createDelivery: "Створення доставки",
            addTask: "Додавання завдання",
            complete: "Завершення угоди",
            archive: "Архівування угоди",
            deArchive: "Розархівування угоди",
        },
        tasks: {
            title: "Завдання",
            description: "Отримувати нотифікації про завдання",
            create: "Створення завдання",
            update: "Редагування завдання",
            addTask: "Додавання завдання",
            complete: "Завершення завдання",
            archive: "Архівування завдання",
            deArchive: "Розархівування завдання",
        },
        priceLists: {
            title: "Прайс лист",
            description: "Отримувати нотифікації про прайсліст",
            create: "Створення товару послуги",
            update: "Редагування товару послуги",
            archive: "Архівування товару послуги",
            deArchive: "Розархівування товару послуги",
        },
        emails: {
            title: "Пошта",
            description: "Отримувати нотифікації з пошти",
        },
    },
};

export default settings;
