const notifications = {
    title: "Сповіщення",
    emptyList: "Немає нових сповіщень",
    showAll: "Показати все",
    tabTitles: {
        messages: "Повідомлення",
        showAll: "Показати все",
    },
};

export default notifications;
