<template>
    <span class="overflow-hidden rounded-full h-[22px] w-[22px]">
        <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1844_12985)">
                <path
                    class="fill-current"
                    d="M22.8938 11C22.6298 11 22.3766 11.1053 22.19 11.2929C22.0033 11.4804 21.8984 11.7348 21.8984
                     12V19C21.8984 19.7956 21.5838 20.5587 21.0238 21.1213C20.4638 21.6839 19.7042 22 18.9123
                     22H4.97691C4.18494 22 3.4254 21.6839 2.86539 21.1213C2.30538 20.5587 1.99077 19.7956 1.99077
                     19V9.07099L8.42591 15.536C9.36068 16.4656 10.6223 16.9878 11.9376 16.9897C13.2529 16.9916 14.5159
                     16.4729 15.4533 15.546L17.3266 13.717C17.4219 13.6258 17.4982 13.5166 17.5512 13.3956C17.6042
                     13.2746 17.6327 13.1443 17.6353 13.0121C17.6378 12.88 17.6142 12.7486 17.5659 12.6257C17.5176
                     12.5028 17.4455 12.3907 17.3538 12.2959C17.2621 12.2011 17.1526 12.1256 17.0316 12.0736C16.9107
                     12.0216 16.7807 11.9942 16.6491 11.993C16.5175 11.9918 16.3871 12.0168 16.2652 12.0666C16.1433
                     12.1163 16.0325 12.1899 15.9391 12.283L14.0558 14.122C13.4868 14.6669 12.7308 14.9709 11.9446
                     14.9709C11.1584 14.9709 10.4024 14.6669 9.83339 14.122L2.3501 6.59999C2.60173 6.11853 2.97929
                     5.71513 3.44215 5.4332C3.90501 5.15128 4.43563 5.0015 4.97691 5H11.9446C12.2086 5 12.4618 4.89464
                     12.6484 4.7071C12.8351 4.51957 12.94 4.26521 12.94 4C12.94 3.73478 12.8351 3.48043 12.6484
                     3.29289C12.4618 3.10536 12.2086 3 11.9446 3H4.97691C3.65744 3.00159 2.39246 3.52888 1.45945
                     4.46622C0.52644 5.40355 0.00158053 6.6744 0 7.99999L0 19C0.00158053 20.3256 0.52644 21.5964
                     1.45945 22.5337C2.39246 23.4711 3.65744 23.9984 4.97691 24H18.9123C20.2317 23.9984 21.4967
                     23.4711 22.4297 22.5337C23.3627 21.5964 23.8876 20.3256 23.8892 19V12C23.8892 11.7348 23.7843
                     11.4804 23.5976 11.2929C23.411 11.1053 23.1578 11 22.8938 11Z"
                />
                <path
                    class="fill-current"
                    d="M23.7179 3.30111C23.8087 3.39501 23.8803 3.50609 23.9287 3.62802C23.9771 3.74994 24.0013 3.88032
                     23.9999 4.01172C23.9986 4.14311 23.9716 4.27295 23.9207 4.39381C23.8698 4.51467 23.7958 4.62419
                     23.7031 4.71611C23.6103 4.80804 23.5006 4.88057 23.3801 4.92956C23.2597 4.97856 23.1309 5.00306
                     23.0011 5.00167C22.8713 5.00027 22.7431 4.97301 22.6237 4.92144C22.5043 4.86988 22.3961 4.79501
                     22.3053 4.70111L20.4878 2.82011L20.4878 10.0011C20.4878 10.2663 20.3837 10.5207 20.1985
                     10.7082C20.0132 10.8957 19.762 11.0011 19.5 11.0011C19.238 11.0011 18.9868 10.8957 18.8015
                     10.7082C18.6163 10.5207 18.5122 10.2663 18.5122 10.0011L18.5122 2.82011L16.6947 4.70111C16.5113
                     4.89074 16.261 4.99885 15.9989 5.00167C15.7368 5.00448 15.4843 4.90176 15.2969 4.71611C15.1096
                     4.53046 15.0028 4.27708 15.0001 4.01172C14.9973 3.74635 15.0987 3.49074 15.2821 3.30111L17.7664
                     0.731118C18.2226 0.267109 18.8415 0.00467255 19.4881 0.00112017C19.4943 -0.000405707 19.5007
                     -0.000405707 19.5069 0.00112017C19.8262 0.00102865 20.1424 0.0647589 20.4374 0.188656C20.7323
                     0.312554 21.0002 0.49418 21.2257 0.723119L23.7179 3.30111Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1844_12985">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </span>
</template>
