import { AxiosResponse } from "axios";
import { Delivery } from "@/services/types/delivery/delivery";
import { customFieldsMutations, paginationMutations, paginationState } from "@/store/mixins";
import { ActionContext } from "vuex";
import { deliveryService } from "@/services";
import { addDelivery } from "@/services/types/delivery/add-delivery";
import { DeliveryFilters } from "@/services/types/delivery/delivery-filtets";

type addDeliveryResponse = AxiosResponse<{ _: string }> | undefined;

export interface DeliveryState {
    deliveries: Delivery[];
    addDelivery: addDeliveryResponse;
    deliver: Delivery;
}

const DeliveryModule = {
    namespaced: true,
    state: {
        ...paginationState,
        deliveries: [] as Delivery[],
        addDelivery: undefined as addDeliveryResponse,
        delivery: undefined,
    },
    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setAddedDelivery: (state: DeliveryState, payload: addDeliveryResponse): void => {
            state.addDelivery = payload;
        },
        setDeliveries: (state: DeliveryState, payload: Delivery[]): void => {
            state.deliveries = payload;
        },
    },
    actions: {
        deliveryList: async ({ commit }: ActionContext<unknown, unknown>, filters: DeliveryFilters) => {
            try {
                const { delivery, page, pagesCount } = await deliveryService.deliveryList(filters);
                commit("setDeliveries", delivery);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        deleteDelivery: async ({ dispatch }: ActionContext<unknown, unknown>, id: number) => {
            try {
                await deliveryService.deleteDelivery(id);
            } catch (error) {
                console.error(error);
            }
        },
        deleteDeliveries: async ({ dispatch }: ActionContext<unknown, unknown>, ids: number[]) => {
            try {
                await deliveryService.deleteDeliveries(ids);
            } catch (error) {
                console.error(error);
            }
        },
        addDelivery: async ({ commit }: ActionContext<unknown, unknown>, payload: addDelivery): Promise<void> => {
            commit("setAddedDelivery", undefined);
            try {
                const res = await deliveryService.addDelivery(payload);
                commit("setAddedDelivery", res);
            } catch (e: any) {
                commit("setAddedDelivery", e.response || { statusText: e.code });
            }
        },
    },
};

export default DeliveryModule;
