import api from "@/services/api";
import { SourceType } from "@/services/enums";
import { Source } from "@/services/types";

export const sourcesService = {
    async sourceList(sourceType: SourceType) {
        return await api.get<Source[]>("/sources", {
            params: { sourceType },
        });
    },
    async removeSource(sourceId: number, sourceType: SourceType) {
        return await api.delete(`/sources/${sourceId}`, { params: { sourceType } });
    },
    async createSource(payload: Omit<Source, "id" | "isPredefinedBySystem">) {
        const { sourceType, ...source } = payload;
        return await api.post("/sources", source, { params: { sourceType } });
    },
    async updateSource(sourceId: number, source: Partial<Omit<Source, "id">>, sourceType: SourceType) {
        return await api.patch(`/sources/${sourceId}`, source, { params: { sourceType } });
    },
};
