<script setup lang="ts"></script>
<template>
    <div class="px-6 py-6 rounded-2xl dark:bg-gray-700 bg-white w-full">
        <div class="flex justify-between items-center mb-4">
            <slot name="header" />
        </div>
        <slot name="rows" />
        <slot name="actions" />
        <slot></slot>
    </div>
</template>
