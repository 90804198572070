import { ref, Ref } from "vue";

export function useInfoHeight(infoContainer: Ref<HTMLElement | null>) {
    const infoHeight = ref<string>("auto");

    const calculateInfoHeight = () => {
        if (infoContainer.value) {
            let height = infoContainer.value.offsetHeight;
            if (height) height += 10;
            infoHeight.value = `${height}px`;
        }
    };

    return { infoHeight, calculateInfoHeight };
}
