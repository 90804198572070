export const truncateString = (text: string) => {
    if (text?.includes(" ")) {
        const words = text.split(" ");
        const hasTooLongWord = words.some((word) => word.length > 35);

        if (hasTooLongWord) {
            return text.substring(0, 35) + "...";
        }
        if (text.length >= 120) {
            return text.substring(0, 120) + "...";
        }
        return text;
    }

    return text && text.length >= 20 ? text.substring(0, 20) + "..." : text;
};
