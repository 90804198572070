import { computed, ref, Ref } from "vue";

// warning: it is shallow, meaning if you have key: Object, it may not behave
// only supports key as a primitive type (e.g. string, number, null, boolean, undefined)
export const useRefWithChangeTracking = <T extends object>(defaultFieldsValue: T) => {
    const fields = ref(defaultFieldsValue) as Ref<T>;
    const defaultFields = ref({ ...defaultFieldsValue }) as Ref<T>;

    const setDefaultToCurrent = () => (defaultFields.value = { ...fields.value });

    const changedFields = computed<Partial<T>>(() =>
        Object.entries(fields.value)
            // @ts-expect-error ddd
            .filter(([key, value]) => defaultFields.value[key] !== value)
            .reduce((obj, [key, value]) => {
                // @ts-expect-error ddd
                obj[key] = value;
                return obj;
            }, {})
    );

    return [fields, changedFields, setDefaultToCurrent, defaultFields] as const;
};
