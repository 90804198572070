import { ActionContext } from "vuex";

import { leadsService } from "@/services";
import { AxiosResponse } from "axios";
import { CreateLead, UpdateLead, Lead, LeadFilters, LeadSorting, Locale, ClientList, Pagination } from "@/services/types";
import { paginationState, paginationMutations, PaginationState, customFieldsState, customFieldsMutations, CustomFieldsState } from "@/store/mixins";
import { ClientsState } from "@/store/modules/clients";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";
import { DownloadFileTypeOptions } from "@/utils/import-export";

type LeadResponse = AxiosResponse<{ _: string }> | undefined;

export interface LeadsState extends CustomFieldsState, PaginationState {
    leads: Lead[];
    selectInputOptions: Partial<Lead>[];
    createLead: LeadResponse;
    updateLead: LeadResponse;
    lead: Lead;
    additionalContactOptions: any[];
}

const LeadsModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
        ...paginationState,
        leads: [] as Lead[],
        selectInputOptions: [] as Partial<Lead>[],
        createLead: undefined as LeadResponse,
        updateLead: undefined as LeadResponse,
        lead: undefined,
        additionalContactOptions: [],
    },

    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setCreateLead: (state: LeadsState, payload: LeadResponse): void => {
            state.createLead = payload;
        },
        setUpdateLead: (state: LeadsState, payload: LeadResponse): void => {
            state.updateLead = payload;
        },
        setLeads: (state: LeadsState, payload: Lead[]): void => {
            state.leads = payload;
        },
        setLead: (state: LeadsState, payload: Lead): void => {
            state.lead = payload;
        },
        setLeadsForSelectInput: (state: LeadsState, payload: Partial<Lead>[]): void => {
            state.selectInputOptions = payload;
        },
        setAdditionalContacts: (state: ClientsState, payload: any): void => {
            state.additionalContactOptions = payload;
        },
    },
    actions: {
        leadList: async ({ commit }: ActionContext<unknown, unknown>, filters: LeadFilters | LeadSorting | Locale): Promise<void> => {
            try {
                const { leads, customFields, page, pagesCount } = await leadsService.leadList(filters);
                commit("setLeads", leads);
                commit("setCustomFields", customFields);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },

        getSelectInputOptions: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const leads = await leadsService.someFields({ fields: ["id", "name"] });
                commit("setLeadsForSelectInput", leads);
            } catch (e) {
                console.error(e);
            }
        },

        getLead: async ({ commit }: ActionContext<unknown, unknown>, { id, locale }: { id: string; locale: Locale }) => {
            try {
                const lead = await leadsService.getLead(id, locale);
                commit("setLead", lead);
                return lead;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        createLead: async ({ commit }: ActionContext<unknown, unknown>, payload: CreateLead) => {
            commit("setCreateLead", undefined);
            try {
                const res = await leadsService.createLead(payload);
                commit("setCreateLead", res);
                return res;
            } catch (e: any) {
                commit("setCreateLead", e.response || { statusText: e.code });
                throw e;
            }
        },
        updateLead: async ({ commit }: ActionContext<unknown, unknown>, { id, payload, locale }: { id: string; payload: UpdateLead; locale: Locale }): Promise<void> => {
            commit("setUpdateLead", undefined);
            try {
                const res = await leadsService.updateLead(id, payload, locale);
                commit("setUpdateLead", res);
            } catch (e: any) {
                commit("setUpdateLead", e.response || { statusText: e.code });
                throw e;
            }
        },
        async bulkUpdate(_commit: unknown, { data, locale }: { data: UpdateLead; locale: Locale }): Promise<void> {
            try {
                return leadsService.bulkUpdate(data, locale);
            } catch (e) {
                console.error(e);
            }
        },
        async addContact(_commit: unknown, { id, email }: { id: number; email: string }): Promise<void> {
            try {
                await leadsService.addContact(id, email);
            } catch (e) {
                console.error(e);
            }
        },
        getLeadAdditionalContactOptions: async ({ commit }: ActionContext<unknown, unknown>, leadId: number): Promise<void> => {
            try {
                const additionalContacts: ClientList = await leadsService.getLeadAdditionalContactOptions(leadId);
                commit("setAdditionalContacts", additionalContacts);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

const LEDS_KEY = "leads";

export const useLead = ({ id, locale, enabled }: { id: ComputedRef<number>; locale: "en" | "ua"; enabled: ComputedRef<boolean> }) =>
    useQuery<Lead>({
        queryKey: [LEDS_KEY, id],
        queryFn: () => leadsService.getLead(String(id.value), locale as any),
        enabled,
    });

export const useLeadFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["leads/fields"],
        queryFn: () => leadsService.leadFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useAdditionalContactFields = (
    { locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }
) =>
    useQuery({
        queryKey: ["additional-contacts/fields"],
        queryFn: () => leadsService.additionalContactFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useExport = () =>
    useMutation({
        mutationFn: (
            filters: { locale: "ua" | "en" } & {
                essenceFieldIds: string[];
                customFieldIds: number[];
                additionalContactsFieldIds: string[];
                fileType: DownloadFileTypeOptions;
            } & Omit<LeadFilters, keyof Pagination> &
                LeadSorting
        ) => leadsService.exportLeads(filters).then((res) => res.data),
    });

export const useImport = () =>
    useMutation({
        mutationFn: (data: { locale: "ua" | "en" } & { file: File; uniqueFieldId: string }) => leadsService.importLeads(data).then((res) => res.data),
    });

export const useImportTemplate = () =>
    useMutation({
        mutationFn: (fileType: DownloadFileTypeOptions) => leadsService.importLeadsTemplate(fileType).then((res) => res.data),
    });

export default LeadsModule;
