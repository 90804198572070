import { SourceType } from "@/services/enums";
import { Permissions } from "../constants";

export const sourceTypeToUpdateSettingsPermissionsMap = {
    [SourceType.LEAD]: [Permissions.CAN_UPDATE_LEADS_SETTINGS],
    [SourceType.CLIENT]: [Permissions.CAN_UPDATE_CLIENTS_SETTINGS],
    [SourceType.PRICE_LIST]: [Permissions.CAN_UPDATE_PRICE_LISTS_SETTINGS],
    [SourceType.DEAL]: [Permissions.CAN_UPDATE_DEALS_SETTINGS],
    [SourceType.TASK]: [Permissions.CAN_UPDATE_TASKS_SETTINGS],
    [SourceType.DELIVERY]: [],
};

export const sourceTypeToDeleteSettingsPermissionsMap = {
    [SourceType.LEAD]: [Permissions.CAN_DELETE_LEADS_SETTINGS],
    [SourceType.CLIENT]: [Permissions.CAN_DELETE_CLIENTS_SETTINGS],
    [SourceType.PRICE_LIST]: [Permissions.CAN_DELETE_PRICE_LISTS_SETTINGS],
    [SourceType.DEAL]: [Permissions.CAN_DELETE_DEALS_SETTINGS],
    [SourceType.TASK]: [Permissions.CAN_DELETE_TASKS_SETTINGS],
    [SourceType.DELIVERY]: [],
};

export const sourceTypeToCreateSettingsPermissionMap = {
    [SourceType.LEAD]: [Permissions.CAN_CREATE_LEADS_SETTINGS],
    [SourceType.CLIENT]: [Permissions.CAN_CREATE_CLIENTS_SETTINGS],
    [SourceType.PRICE_LIST]: [Permissions.CAN_CREATE_PRICE_LISTS_SETTINGS],
    [SourceType.DEAL]: [Permissions.CAN_CREATE_DEALS_SETTINGS],
    [SourceType.TASK]: [Permissions.CAN_CREATE_TASKS_SETTINGS],
    [SourceType.DELIVERY]: [],
};
