import api from "@/services/api";
import { Currency, Role, Position, Funnel, PriceList } from "./types/";

export const referencesService = {
    async getCurrencyList(): Promise<Currency[]> {
        const response = await api.get("/references/currency-list");
        return response.data;
    },

    async getRolesList(): Promise<Role[]> {
        const response = await api.get("/references/roles-list");
        return response.data;
    },

    async getPositionsList(): Promise<Position[]> {
        const response = await api.get("/references/position-list");
        return response.data;
    },

    async getFunelsList() {
        return (await api.get<Funnel[]>("/references/funnels")).data;
    },

    async getPriceLists() {
        return (await api.get<PriceList[]>("/references/price-lists")).data;
    },

    async getTasksAndDealsByPhoneNumber(phoneNumber: string) {
        return (await api.get(`/references/tasks-and-deals?phoneNumber=${encodeURIComponent(phoneNumber)}`)).data;
    },

    async getContactNameByPhoneNumber(phoneNumber: string) {
        return (await api.get(`/references/contact-name?phoneNumber=${encodeURIComponent(phoneNumber)}`)).data;
    },
};
