import { ref } from "vue";
import { Ref } from "vue/dist/vue";

import { DeliveryProviders } from "@/services/enums";
import { deliveryService } from "@/services";
import { Option } from "@/uikit/components/Inputs/OptionList";

const novaPostCities = ref<Option[]>([]);
const ukrPostCities = ref<Option[]>([]);
const novaPostDepartmentTypes = ref<Option[]>([]);
const novaPostDepartments = ref<Option[]>([]);
const ukrPostDepartments = ref<Option[]>([]);

export const useDeliveryAddress = (form: Ref) => {
    const fetchCities = async (search: string, provider: DeliveryProviders) => {
        if (search) {
            const cities = await deliveryService.getSettlements({ search }, provider);
            if (provider === DeliveryProviders.NOVA_POST) {
                novaPostCities.value = cities;
            } else {
                ukrPostCities.value = cities;
            }
        }
    };
    const fetchNovaPostDepartmentTypes = async (city: string) => {
        novaPostDepartmentTypes.value = await deliveryService.getNovaPostDepartmentTypes({ settlement: city });
    };
    const fetchDepartments = async (city: string, departmentType: string, provider: DeliveryProviders) => {
        const departments = await deliveryService.getDepartments({ settlement: city, departmentType }, provider);
        if (provider === DeliveryProviders.NOVA_POST) {
            novaPostDepartments.value = departments;
        } else {
            ukrPostDepartments.value = departments;
        }
    };
    const selectNovaPostCity = async (option: Option) => {
        if (option) {
            const { name, value } = option;
            form.value.novaPostDeliveryAddress = {
                provider: DeliveryProviders.NOVA_POST,
                city: null,
                postDepartment: null,
                postDepartmentType: null,
            };
            form.value.novaPostDeliveryAddress.city = { name, value };
            await fetchNovaPostDepartmentTypes(name);
            form.value.novaPostDeliveryAddress.postDepartmentType = null;
            form.value.novaPostDeliveryAddress.postDepartment = null;
        }
    };
    const selectNovaPostDepartmentType = async (option: Option) => {
        if (option) {
            form.value.novaPostDeliveryAddress.postDepartmentType = option.value;
            await fetchNovaPostDepartments(option);
            form.value.novaPostDeliveryAddress.postDepartment = null;
        }
    };
    const selectNovaPostDepartment = (option: Option) => {
        if (option && form.value?.novaPostDeliveryAddress) {
            const { name, value } = option;
            form.value.novaPostDeliveryAddress.postDepartment = { name, value };
        }
    };
    const selectUkrPostCity = async (option: Option) => {
        if (option) {
            const { name, value } = option;
            form.value.ukrPostDeliveryAddress = {
                provider: DeliveryProviders.UKR_POST,
                city: null,
                postDepartment: null,
            };
            form.value.ukrPostDeliveryAddress.city = { name, value };
            await fetchDepartments(form.value.ukrPostDeliveryAddress?.city?.name, option.name, DeliveryProviders.UKR_POST);
        }
    };

    const selectUkrPostDepartment = (option: Option) => {
        if (option && form.value?.ukrPostDeliveryAddress) {
            const { name, value } = option;
            form.value.ukrPostDeliveryAddress.postDepartment = { name, value };
        }
    };
    const fetchNovaPostDepartments = async (option: Option) => {
        if (option) {
            await fetchDepartments(form.value.novaPostDeliveryAddress?.city?.name, option.name, DeliveryProviders.NOVA_POST);
        }
    };
    const setOptions = async (data: Ref) => {
        if (data.value.novaPostDeliveryAddress?.city) {
            novaPostCities.value = [data.value.novaPostDeliveryAddress.city];
            await fetchNovaPostDepartmentTypes(data.value.novaPostDeliveryAddress.city.name);
            if (data.value.novaPostDeliveryAddress?.postDepartmentType) {
                await fetchDepartments(data.value.novaPostDeliveryAddress.city.name, data.value.novaPostDeliveryAddress.postDepartmentType, DeliveryProviders.NOVA_POST);
            }
        }
        if (data.value.ukrPostDeliveryAddress?.city) {
            ukrPostCities.value = [data.value.ukrPostDeliveryAddress.city];
            await fetchDepartments(data.value.ukrPostDeliveryAddress.city.name, "", DeliveryProviders.UKR_POST);
        }
    };

    return {
        fetchCities,
        fetchNovaPostDepartmentTypes,
        fetchDepartments,
        selectNovaPostCity,
        selectUkrPostCity,
        selectNovaPostDepartment,
        selectNovaPostDepartmentType,
        selectUkrPostDepartment,
        fetchNovaPostDepartments,
        setOptions,
        novaPostCities,
        ukrPostCities,
        novaPostDepartmentTypes,
        novaPostDepartments,
        ukrPostDepartments,
    };
};
