import api from "@/services/api";
import { CreateTask, TaskFilters, TaskList, TaskSorting, UpdateTask, SomeFieldsRequest, Task, Locale, CustomField, Pagination } from "@/services/types";
import { DownloadFileTypeOptions } from "@/utils/import-export";

export const tasksService = {
    async taskList(params: TaskFilters | TaskSorting | Locale): Promise<TaskList> {
        const response = await api.get("/tasks", {
            params,
        });
        return response.data;
    },

    async someFields(params: SomeFieldsRequest): Promise<Partial<Task>[]> {
        const response = await api.get("/tasks/some-fields", {
            params: { fields: params.fields?.join(",") },
        });
        return response.data;
    },

    async getTask(id: string, locale: Locale) {
        const response = await api.get(`/tasks/${id}`, { params: { locale } });
        return response.data;
    },

    async createTask(task: CreateTask): Promise<void> {
        return api.post("tasks", task);
    },

    updateTask(id: number, task: UpdateTask, locale: Locale): Promise<void> {
        return api.patch(`/tasks/${id}`, task, { params: { locale } });
    },
    bulkUpdate(data: UpdateTask, locale: Locale): Promise<void> {
        return api.patch(`/tasks/bulk`, data, { params: { locale } });
    },
    taskFieldsList(locale: "en" | "ua") {
        return api.get<{ essenceFields: string[]; customFields: CustomField[] }>("/tasks/fields", { params: { locale } });
    },
    exportTasks(params: { locale: "en" | "ua" } & { essenceFieldIds: string[]; customFieldIds: number[] } & Omit<TaskFilters, keyof Pagination> & TaskSorting) {
        const { essenceFieldIds, customFieldIds } = params;
        return api.get<{ fileUrl: string }>("/tasks/export", {
            params: {
                ...params,
                essenceFieldIds: essenceFieldIds.join(","),
                customFieldIds: customFieldIds.join(","),
            },
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
    async importTasks(data: { locale: "en" | "ua" } & { file: File; uniqueFieldId: string }) {
        const form = new FormData();
        form.append("file", data.file);
        form.append("locale", data.locale);
        form.append("uniqueFieldId", data.uniqueFieldId);

        return await api.postForm<{ errors: ({ [key: string]: any } & { error: any })[]; successCount: number }>("/tasks/import", form, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importTasksTemplate(fileType: DownloadFileTypeOptions) {
        return await api.get<{ fileUrl: string }>(`/tasks/import/template?fileType=${fileType}`, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
};
