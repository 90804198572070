import { customFieldsService } from "@/services";
import { CreateCustomField, UpdateOrCreateCustomFieldValues, CustomField } from "@/services/types";
import { SourceType } from "@/services/enums";
import { customFieldsState, customFieldsMutations } from "@/store/mixins";
import { useMutation, useQuery } from "@tanstack/vue-query";

const CustomFieldsModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
    },
    mutations: {
        ...customFieldsMutations,
    },
};

export const useUpdateCustomField = () =>
    useMutation({
        mutationFn: ({ id, data }: { id: number; data: CreateCustomField }) => customFieldsService.updateCustomField(id, data),
    });

export const useCreateCustomField = () =>
    useMutation({
        mutationFn: (payload: CreateCustomField) => customFieldsService.createCustomField(payload),
    });

export const useRemoveCustomField = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (fieldId: number) => customFieldsService.removeCustomField(fieldId, sourceType),
    });

export const useCustomFields = (sourceType: SourceType) =>
    useQuery({
        queryKey: ["getCustomFields", sourceType],
        queryFn: () => customFieldsService.getCustomFields(sourceType),
    });

export const useUpdateOrCreateCustomFieldValues = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (payload: UpdateOrCreateCustomFieldValues) => customFieldsService.updateOrCreateCustomFieldValues(payload, sourceType).then((res) => res.data),
    });

export default CustomFieldsModule;
