import { ActionContext } from "vuex";

import { callsService } from "@/services";
import { Call, CallFilters, CallSorting } from "@/services/types";
import { CallList } from "@/services/types/calls/call-list";
import { paginationState, paginationMutations, PaginationState } from "@/store/mixins/pagination.mixin";
import { UpdateCall } from "@/services/types/calls/update-call";

export interface CallState extends PaginationState {
    calls: Call[];
    sipRegistered: boolean;
    hasActiveCallSession: boolean;
    isDtmfMode: boolean;
    dtmfTone: string | null;
    isTransferToMode: boolean;
    transferToNumber: string | null;
    remotePhoneNumber: string | null;
    isCallMuted: boolean;
    isOnHold: boolean;
    isDialing: boolean;
}

const CallsModule = {
    namespaced: true,
    state: {
        ...paginationState,
        calls: [] as Call[],
        sipRegistered: false,
        hasActiveCallSession: false,
        isDialing: false,
        isDtmfMode: false,
        dtmfTone: null,
        transferToNumber: null,
        remotePhoneNumber: null,
        isCallMuted: false,
        isOnHold: false,
    },
    mutations: {
        ...paginationMutations,
        setCalls: (state: CallState, payload: Call[]): void => {
            state.calls = payload;
        },
        setSipRegistered: (state: CallState, sipRegistered: boolean): void => {
            state.sipRegistered = sipRegistered;
        },
        setActiveCallSession: (state: CallState, hasActiveCallSession: boolean): void => {
            state.hasActiveCallSession = hasActiveCallSession;
        },
        setIsDialing: (state: CallState, isDialing: boolean): void => {
            state.isDialing = isDialing;
        },
        setIsDtmfMode: (state: CallState, isDtmfMode: boolean): void => {
            state.isDtmfMode = isDtmfMode;
        },
        setDtmfTone: (state: CallState, dtmfTone: string): void => {
            state.dtmfTone = dtmfTone;
        },
        setTransferToNumber: (state: CallState, transferToNumber: string): void => {
            state.transferToNumber = transferToNumber;
        },
        setRemotePhoneNumber: (state: CallState, remotePhoneNumber: string): void => {
            state.remotePhoneNumber = remotePhoneNumber;
        },
        setMuted: (state: CallState, isMuted: boolean): void => {
            state.isCallMuted = isMuted;
        },
        setHold: (state: CallState, isOnHold: boolean): void => {
            state.isOnHold = isOnHold;
        },
    },
    actions: {
        callList: async ({ commit }: ActionContext<unknown, unknown>, filters: CallFilters | CallSorting): Promise<void> => {
            try {
                const { pagesCount, page, calls }: CallList = await callsService.callList(filters);
                commit("setCalls", calls);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        async updateCall({ commit }: ActionContext<CallState, any>, { id, payload }: { id: string; payload: UpdateCall }): Promise<void> {
            try {
                await callsService.updateCall(id, payload);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export default CallsModule;
