import { ActionContext } from "vuex";

export type QueryParams = { [key: string]: string };
export interface QueryParamFiltersState {
    queryParams: QueryParams;
}

const CustomerTypesModule = {
    namespaced: true,
    state: {
        queryParams: undefined as unknown as QueryParams,
    } as QueryParamFiltersState,
    mutations: {
        setQueryParams: (state: QueryParamFiltersState, payload: QueryParams): void => {
            state.queryParams = payload;
        },
    },
    actions: {
        setQueryParams: async ({ commit }: ActionContext<unknown, unknown>, payload: QueryParams): Promise<void> => {
            commit("setQueryParams", { ...payload });
        },
        setInitialQueryParams: async ({ commit, state }: ActionContext<QueryParamFiltersState, unknown>, payload: QueryParams): Promise<void> => {
            if (state.queryParams === undefined) commit("setQueryParams", payload);
        },
    },
};

export default CustomerTypesModule;
