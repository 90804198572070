import { computed } from "vue";

import { Modal } from "@/store/modules/modals";

export const useMultiActions = (
    items: any,
    changeState: (items: any) => void,
    openModal: (modal: Modal) => Promise<void>,
    closeModal: (modal?: Modal) => Promise<void>
) => {
    const checkedItems = computed(() => items.value.filter((item: any) => item.id && item.checked));
    const allChecked = computed(() => items.value.length > 0 && checkedItems.value.length === items.value.length);

    const check = (index: number, checked: boolean, all = false) => {
        const updatedItems = [...items.value];
        if (all) {
            updatedItems.forEach((item) => (item.checked = checked));
        } else {
            updatedItems[index]["checked"] = checked;
        }
        changeState(updatedItems);
    };

    const updateSelected = async (updateFunction: (args: any) => Promise<any>, fetchItems: () => any) => {
        const response = await updateFunction(checkedItems.value.map((item: any) => item.id));
        await fetchItems();
        if (response?.status === 200) {
            await openModal("successChange");
            setTimeout(async () => {
                await closeModal();
            }, 1500);
        }
    };

    return { checkedItems, allChecked, check, updateSelected };
};
