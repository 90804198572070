const tasks = {
    title: "Завдання",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    table: {
        title: "Інформація про завдання",
        panel: {
            searchPlaceholder: "Пошук",
            archive: "Архів",
            create: "Створити завдання",
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            name: "Назва",
            responsible: "Головний відповідальний",
            status: "Статус",
            createdBy: "Створив",
            createdAt: "Створено",
            comment: "Коментар",
            customer: "Лід / Клієнт",
            endDate: "Дата завершення (свій дедлайн)",
            deal: "Угода",
            finishedAt: "Завершено",
            actions: "Дії",
            lead: "Лід",
            client: "Клієнт",
            responsibleContact: "Відповідальний контакт",
            priority: "Пріорітет",
        },
    },
    modal: {
        header: "Створити завдання",
        inputs: {
            name: "Назва завдання *",
            nameError: "Назва не коректна",
            responsibleUser: "Головний відповідальний *",
            comment: "Коментар",
            status: "Статус",
            createdBy: "Створив",
            customerType: "Ліди / Клієнти",
            leads: "Лід",
            clients: "Клієнт",
            endDate: "Дата завершення (свій дедлайн)",
            deal: "Угода",
            responsibleContact: "Відповідальний контакт",
            noResponsibleContacts: "У обраної сутності немає дод. контактів",
            selectEssence: {
                leads: "Оберіть спочатку лід",
                clients: "Оберіть спочатку клієнта",
            },
        },
        create: "Створити",
        createError: "Помилка створення завдання",
    },
    filter: {
        header: "Фільтр завдань",
        inputs: {
            name: "Назва завдання",
            responsibleUser: "Головний відповідальний",
            creator: "Створив",
            createdAt: "Створено",
            createdFrom: "Створено від",
            createdTo: "Створено до",
            customerType: "Лід/Клієнт",
            endDate: "Дата завершення",
            endDateFrom: "Дата завершення від",
            endDateTo: "Дата завершення до",
            deal: "Угода",
            leads: "Лід",
            clients: "Клієнт",
            status: "Статус",
        },
        placeholders: {
            name: "Введіть назву завдання",
            responsibleUser: "Введіть ім'я",
            creator: "Введіть ім'я",
            deal: "Ведіть назву угоди",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    card: {
        title: "Особиста картка завдання",
        info: {
            title: "Інформація про завдання",
            saveButton: "Зберегти",
        },
        modals: {
            confirmDeleteDeal: {
                title: "Тільки завдання, призначені клієнту, можуть мати прикріплену угоду.",
                subtitle: "Ви впевнені, що хочете призначити це завдання ліду?",
                subtitle2: "Угода буде видалена із завдання.",
            },
        },
    },
    selectCustomerType: {
        leads: "Ліди",
        clients: "Клієнти",
    },
    boardView: {
        noStatus: "Без статусу",
        copyLink: "Копіювати посилання",
        archive: "Архівувати",
        unArchive: "Розархівувати",
        card: {
            lead: "Лід",
            client: "Лід",
            deal: "Угода",
        },
    },
    logs: {
        lead: "Лід",
        client: "Клієнт",
    },
};

export default tasks;
