import api from "@/services/api";
import { CreateCategory, UpdateCategory } from "@/services/types";

export const categoriesService = {
    async categoryList(params: any) {
        return await api.get("/categories", { params });
    },
    async createCategory(payload: CreateCategory) {
        const response = await api.post("/categories", payload);
        return response.data;
    },

    async updateCategory(id: number, payload: UpdateCategory): Promise<void> {
        const response = await api.patch(`/categories/${id}`, payload);
        return response.data;
    },
};
