const notes = {
    title: "Нотатки",
    addButton: "Додати",
    placeholder: "Додати текст.....",
    saveChangesBtn: "Зберегти зміни",
    cancelBtn: "Скасувати",
    uploadingFiles: "Файли завантажуються",
    takeSomeTime: "Це може зайнятий якийсь час",
    noNotes: "Нотаток не знайдено",
    historyItem: {
        headerText: "додав(-ла) нотатку до {sourceType}",
        edited: "відредаговано",
        LEAD: "ліда",
        CLIENT: "клієнта",
        DEAL: "угоди",
        TASK: "завдання",
        PRICE_LIST: "прайс листу",
    },
};

export default notes;
