export const downloadFile = async (url: string, filename?: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    link.setAttribute("download", filename || url.split("/").pop() || "");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
